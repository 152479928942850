
import { Box } from '@mui/material';
import { Fragment } from 'react';
import profileImage from '../../Assets/user_placeholder.svg';
import { Font, HStack, Layout } from '../../Component/Components';

//==========// Campaign Profile //=======//

function CampaignProfile(props) {
  return (
    <Fragment>
      {/* Body */ }
      <Layout size="20px">
        <HStack gap='10px' justifycontent='' alignitems='center' wrap='wrap'>
          <HStack>
            <Box
              component={ 'img' }
              sx={ { borderRadius: 5 } }
              fluid
              src={ props?.cover_photo?.file_url ? props?.cover_photo?.file_url : profileImage }
              width={ '120px' }
              height={ '120px' } />
            <HStack>
              <Font
                size={ '18px' }
                weight={ 400 }>
                { props?.description }
              </Font>
            </HStack>
          </HStack>
        </HStack>
      </Layout>
    </Fragment >
  );
}
export default CampaignProfile;