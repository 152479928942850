import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Font, HStack } from "./Component/Components";
import { PrivateRoute, ProtectRoute } from "./Util/requireAuth";
import Login from './Views/Authentication/Login';
import Signup from './Views/Authentication/Signup';
import SignupThree from "./Views/Authentication/SignupThree";
import SignupTwo from "./Views/Authentication/SignupTwo";
import { CampaignCover, CampaignDescription, CampaignView , CreateCampaign} from "./Views/Campaign";
import EditCampaign from "./Views/Campaign/EditCampaign";
import LandingPage from "./Views/LandingPage/LandingPage";
import PaymentView from './Views/Payment';
import ProfileView from "./Views/Profile";
import { SearchView } from "./Views/Search";
import Success from './Views/Success/Success';
import SuccessPayment from "./Views/Success/SuccessPayment";
import PasswordReset from "./Views/PasswordRest";
import {
  Home,
  Contribution,
  ProfileSetting
} from "./Views/Dashboard"

import {
  AdminHome,
  Campaigns,
  Users,
  UserSetting,
  UserInstitute,
  Comments,
} from "./Views/Admin"
import InstitutionUpdate from './Views/Dashboard/ProfileSetting/InstitutionUpdate'
function ViewRoutes(props) {
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <LandingPage /> } />
        <Route path='/login' element={ <Login /> } />
        <Route path='/signup' element={ <Signup /> } />
        <Route path='/step-two' element={ <SignupTwo /> } />
        <Route path='/step-three' element={ <SignupThree /> } />
        <Route path='/password-reset' element={ <PasswordReset /> } />
        <Route path='/success' element={ <Success /> } />
        <Route path='/successfull-payment' element={ <SuccessPayment /> } />
        <Route path='/search' element={ <SearchView /> } />
        <Route path='/campaign/payment/:id' element={ <PaymentView /> } />
        <Route path='/campaign' element={ <CampaignView /> } />
        <Route path='/campaign/create' element={ <CreateCampaign /> } />
        <Route path='/compaign/cover' element={ <CampaignCover /> } />
        <Route path='/compaign/description' element={ < CampaignDescription /> } />
        <Route path='/dashboard/home' element={ <PrivateRoute element={ Home } /> } />
        <Route path='/dashboard/contribution' element={ <PrivateRoute element={ Contribution } /> } />
        <Route path='/dashboard/setting' element={ <PrivateRoute element={ ProfileSetting } /> } />
        <Route path='/dashboard/institution' element={ <PrivateRoute element={ InstitutionUpdate } /> } />
        <Route path="/profile/:id" element={ <ProfileView /> } />
        <Route path="/profile/edit/:id" element={ <PrivateRoute element={ EditCampaign } /> } />
        <Route path='/admin/home' element = {<ProtectRoute element = {AdminHome} /> }/>
        <Route path='/admin/campaigns' element = {<ProtectRoute element = {Campaigns} />}/>
        <Route path='/admin/users' element={ <ProtectRoute element = {Users} /> }/>
        <Route path='/admin/comments' element={ <ProtectRoute element = {Comments} /> }/>
        <Route path='/admin/institution/:id' element={ <ProtectRoute element = { UserInstitute } /> }/>
        <Route path='/admin/setting/:id' element={ <ProtectRoute element = { UserSetting } /> }/>
        {/* <Route path='/admin/setting/:userId' element={ <PrivateRoute element = {EditUser} /> } /> */}
        <Route path="*" element={
          <main style={ { padding: "1rem" } }>
            <HStack alignitems='center' justifycontent='center'>
              <Font size='30px' weight='500'>404</Font>
            </HStack>
          </main> } />
      </Routes>
    </Router>)
}
export default ViewRoutes;

