import { clearCurrentProfile, hideLoading, setError } from "../State/setPayload";
import { isEmpty } from "./validation";

/**
 * Handle all errors here
 * 
 * @param {*} dispatch 
 * @param {*} error 
 */
export function handleError(dispatch, error) {
  dispatch(hideLoading());
  if (!isEmpty(error)) {
    if (!error.response.data.success) {
      // dispatch(clearCurrentProfile());
    }
    dispatch(setError(error.response.data));
  }
}