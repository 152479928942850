import {useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSessionStats, getUsersStats, getTotalContribution, getUserDemographics, getMonthlyContribution, getMonthlyGoals, getGraduatingUsers } from '../../State/actions/admin';
import { getAllCampaigns } from '../../State/actions/campaign';
import {
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import Page from '../Page';
// project import
import OrdersTable from '../../Component/OrdersTable';
import IncomeAreaChart from '../../Component/IncomeAreaChart';
import MonthlyBarChart from '../../Component/MonthlyBarChart';
import ReportAreaChart from '../../Component/ReportAreaChart';
import SalesColumnChart from '../../Component/SalesColumnChart';
import MainCard from '../../Component/MainCard';
import AnalyticEcommerce from '../../Component/AnalyticEcommerce';

// assets
import { GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import avatar1 from '../../Assets/users/avatar-1.png';
import avatar2 from '../../Assets/users/avatar-2.png';
import avatar3 from '../../Assets/users/avatar-3.png';
import avatar4 from '../../Assets/users/avatar-4.png';

// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};

// sales report status
const status = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'month',
    label: 'This Month'
  },
  {
    value: 'year',
    label: 'This Year'
  }
];

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AdminHome = (props) => {
  const [value, setValue] = useState('today');
  const [slot, setSlot] = useState('week');
  const {auth, adminData, campData} = props;
  const [statType, setStatType] = useState('ethnicity');

  useEffect(() => {
    // Fetch session stats when component mounts
    props.getSessionStats();
    props.getUsersStats();
    props.getTotalContribution();
    props.getUserDemographics();
    props.getAllCampaigns();
    props.getMonthlyContribution();
    props.getMonthlyGoals();
    props.getGraduatingUsers();
  }, [auth]); // Run only on initial component mount
  return (
    <Page>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce title="Total Visits" count={adminData.totalVisits.toString()} percentage={59.3} extra={adminData.thisYearVisits.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce title="Total Users" count={adminData.totalUserCounts.toString()} percentage={70.5} extra={adminData.thisYearUsers.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce title="Total Contributions" count={"$" + adminData.totalContributions.toString()} percentage={27.4} isLoss color="warning" extra={"$" + adminData.thisYearContributions.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce title="Completed Campaigns" count={campData.allcampaign?.toString() ?? 'N/A'} percentage={27.4} isLoss color="warning" extra={campData.thisYearCampaigns?.toString() ?? 'N/A'} />
        </Grid>
        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

        {/* row 2 */}
        <Grid item xs={12} md={7} lg={18}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Graduating Students</Typography>
            </Grid>
          </Grid>
          <MainCard content={false} sx={{ mt: 1.5 }}>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: 'primary.main', '& th': { color: 'white' } }}>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Institution</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Expected Graduation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminData?.graduatingUsers?.data?.map((student) => (
                    <TableRow key={student._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row" >{student.first_name}</TableCell>
                      <TableCell>{student.last_name}</TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>{student.institution.prospective_institution}</TableCell>
                      <TableCell>{student.institution.education_program_track}</TableCell>
                      <TableCell>{student.institution.expected_graduation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Grid>
        {/* row 3 */}
        <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Finance Report</Typography>
            </Grid>
            
          </Grid>
          <MainCard sx={{ mt: 1.75 }}>
            <Stack spacing={1.5} sx={{ mb: -12 }}>
              <Typography variant="h6" color="secondary">
                Net Profit
              </Typography>
            </Stack>
            <SalesColumnChart 
              monthlyGoals={adminData.monthlyGoals} 
              monthlyContributions={adminData.monthlyContributions} 
            />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">User Statistics</Typography>
          </Grid>
          <Grid item>
            <Select
              value={statType}
              onChange={(e) => setStatType(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="ethnicity">Ethnicity</MenuItem>
              <MenuItem value="college">College</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.75 }} content={false}>
          <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>
            {statType === 'ethnicity' && adminData.userDemo?.ethnicityCounts?.map((item) => (
              <ListItemButton key={item._id} divider>
                <ListItemText primary={item._id} />
                <Typography variant="h5">{item.count}</Typography>
              </ListItemButton>
            ))}
            {statType === 'college' && adminData.userDemo?.collegeCounts?.map((item) => (
              <ListItemButton key={item._id} divider>
                <ListItemText primary={item._id} />
                <Typography variant="h5">{item.count}</Typography>
              </ListItemButton>
            ))}
          </List>
        </MainCard>
      </Grid>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  dataTotalCnt: Number(state.campaign.allcampaign),
  adminData:state.admin,
  campData: state.campaign,
});

const mapDispatchToProps =  {
  getSessionStats,
  getUsersStats,
  getTotalContribution,
  getAllCampaigns,
  getUserDemographics,
  getMonthlyContribution,
  getMonthlyGoals,
  getGraduatingUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
