import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, ContentView, Font, HStack, Size, Spacer, VStack } from "../../Component/Components";
import FileUploader from '../../Component/FileUploader';
import { signUp } from "../../State/actions/auth";
import '../../StyleModule/main_style.css';

//===============// Fundraiser CoverImage //================//

function CampaignCover(props) {
  const [ uploadImageCheck, setUploadImageCheck ] = useState(false);
  const [ coverFile, setCoverFile ] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.auth.isAuthenticated) return navigate('/login');
  }, [ navigate, props.auth.isAuthenticated ]);

  const handleImageUpload = (file) => {
    if (file) {
      setUploadImageCheck(false);
      setCoverFile(file);
    }
  };

  return (
    <HStack wrap='wrap'>
      <VStack className='login-container'>
        <ContentView
          className="invite"
          width='800px'
          alignitems
          justifycontent='center' >

          <Spacer size={ '60px' } />

          <Container
            alignitems
            display='flex'
            gap='10px'
            width='100%'
            justifycontent='center' >
            <HStack alignitems>
              <Font size='32px' weight={ 500 }>
                Create GapFundr Profile
              </Font>
            </HStack>
            <Spacer size={ '10px' } />

            {/* Form area */ }
            <FileUploader handleFile={ (file) => handleImageUpload(file) } />
            { uploadImageCheck &&
              <Font
                justifycontent="center"
                size="16px"
                color="red"
                weight={ 500 }>
                Please upload cover image
              </Font>
            }
            <Spacer size={ Size.s10 } />

            <HStack alignitems='center' justifycontent='center'>
              <Button
                className="__btn__"
                type={ "button" }
                onClick={ () => navigate(-1) }>
                <ArrowBackIos sx={ { height: 18 } } /> { ' ' }  Back
              </Button>

              <Button
                className="__btn__"
                type={ "button" }
                onClick={ () => {
                  if (coverFile) {
                    // Pass the file to next page. This action can be undone when the user refreshes the screen or decide to go back
                    navigate('/compaign/description', { state: coverFile });
                  }
                  else {
                    setUploadImageCheck(true);
                  }
                } }>Continue { ' ' } <ArrowForwardIos sx={ { height: 18 } } />
              </Button>
            </HStack>
          </Container>

          <Spacer size={ Size.s20 } />
        </ContentView>
      </VStack>
    </HStack>
  );
};
CampaignCover.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth
});
export default connect(mapStateToProps, { signUp })(CampaignCover);