import { ActionType } from '../actionTypes/index';

const initialState = {
  error: {}
};

function errorReducer(state = initialState,action) {
  switch(action.type) {
    case ActionType.SET_ERROR:
      return {
        error: action.payload,
      };
    default:
      return state;
  };
}

export default errorReducer;
