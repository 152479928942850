import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Font, HStack, Layout, Spacer } from '../../Component/Components';
import { TextField } from '../../Component/InputFields'
import { ContainerCard } from '../../Component/ContainerCard';
import { showLoading } from '../../State/setPayload';
import { getContributions, requestFunds } from '../Campaign/CampaignAPI';
import Page from '../Page';
import DonationList from '../Donation/DonationList';

function Contribution(props) {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [ open, setOpen ] = useState(false);
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ amount, setAmount ] = useState(0)
  const [ error, setError ] = useState({ amount: false });
  const [ serverError, setServerError ] = useState("");
  const [ successRequest, setSuccessRequest ] = useState(false);

  const retrieveContributions = () => {
    props.getContributions({ id: auth?.user._id })
  };

  useEffect(() => {
    setSuccessRequest(props.fundsRequest?.success);
  })


  useEffect(() => {
    retrieveContributions();
  }, [])


  useEffect(() => {
    if (successRequest) {
      setOpen(true)
      setOpenDialog(state => !state)
    }
  }, [ successRequest ])

  const newRequest = () => {
    if (!error.amount) setError({ amount: true });

    if (amount) {
      const payload = {
        first_name: auth.user.first_name,
        last_name: auth.user.last_name,
        dob: auth.user.dob,
        email: auth.user.email,
        amount: amount,
        student_id: auth.user.institution?.student_institution_id
      }

      dispatch(showLoading())
      setError({ amount: false });
      setTimeout(() => {
        props.requestFunds(auth.user?._id, payload);
      }, 3000)
    }
  }

  const handleClose = () => {
    setOpen(state => !state)
  }

  const handleCloseDialog = () => {
    setOpenDialog(state => !state)
    dispatch(setError({}))
  }

  return (
    <Page>
    <Fragment>
      <Spacer size='80px' />

      <HStack alignitems='center'>
        <Font>Contributions</Font>
        <Button type='button' onClick={ () => setOpenDialog(state => !state) }
          sx={ { padding: 0.4, pr: 1, pl: 1, borderRadius: '20px', background: '#F1F1F1', fontSize: '16px' } }>
          Request Funds
        </Button>
      </HStack>

      <Dialog open={ openDialog } onClose={ handleCloseDialog }>

        <DialogContent>
          <DialogContentText fontSize={ '20px' }>
            How much would you like to request?
          </DialogContentText>
          <Spacer />
          <TextField
            label="Amount"
            type="number"
            placeholder='0'
            onChange={ (e) => {
              setAmount(e.target.value)
              setError({ amount: false });
            }
            }
            errors={ error?.amount }
          />
          {
            props?.error &&
            <DialogContentText fontSize={ '14px' } variant='danger' color={ 'red' }>{ props?.error }</DialogContentText>
          }
        </DialogContent>

        <Spacer />

        <DialogActions sx={ { justifyContent: 'center' } }>

          <Button onClick={ handleCloseDialog } variant='danger' sx={ { borderRadius: 2, border: '2px solid red' } } className='__btn__outlined__'>Cancel
          </Button>

          <Button disabled={ props?.contributions?.length === 0 } onClick={ newRequest } className='__btn__'>
            { props?.loading ? <CircularProgress color={ 'inherit' } /> :
              <span>
                { props?.contributions?.length === 0 ? 'No funds' : 'Send Request' }
              </span>
            }
          </Button>

        </DialogActions>
        <Spacer />
      </Dialog>

      <Spacer />
      {
        props?.contributions?.length > 0 ?
          <DonationList { ...props } /> :


          <HStack alignitems='center' justifycontent='center'>
            <ContainerCard className="">
              <Layout size="20px">
                <HStack justifycontent='center' alignitems='center' >
                  <Layout>
                    <Font size="14px" weight='500'>You don't have contributions</Font>
                  </Layout>
                </HStack>

              </Layout>
            </ContainerCard>
          </HStack >
      }

      <Snackbar open={ open } autoHideDuration={ 6000 } onClose={ handleClose } anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }>

        <Alert onClose={ handleClose } severity="success" sx={ { width: '100%', alignContent: 'center' } }>
          { props?.fundsRequest?.data }
        </Alert>

      </Snackbar>
    </Fragment >
    </Page>
  );
};

Contribution.propTypes = {
  contributions: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  requestFunds: PropTypes.func,
  loading: PropTypes.bool,
  fundsRequest: PropTypes.object,
  getContributions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  contributions: state.campaign.contributions,
  loading: state.loading.loading,
  fundsRequest: state.campaign.fundsRequest,
  error: state.error.error.error,
});

export default connect(mapStateToProps, { getContributions, requestFunds })(Contribution);