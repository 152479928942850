export const sideMenuItems = {
    Home: 'home',
    Setting: 'setting',
    EditUser: 'edituser',
    Notification: 'notification',
    Donation: 'campaign',
    Contribution: 'contribution',
    Institution: 'institution',
    CreateCampaign: 'create',
    Campaigns: 'campaigns',
    Users: 'users',
    Comments: 'comments',
};