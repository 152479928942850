import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import { HStack, Layout,VStack, Spacer } from '../../Component/Components';
import AdminSideMenu from "../Admin/AdminSideMenu";
import UserSideMenu from "../Dashboard/UserSideMenu";
import MenuBar from "../Navbar/MenuBar";
import styled from "styled-components";

const PageWrapper = styled.div`
  position relative;
`;

const PageNav = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
`;

const NavBarSpacer = styled.nav`
  height: 4rem;
`;

const PageContent = styled.div`
     padding: 1rem;
     @media (max-width: 768px) {
       padding: 0.5rem;
     }
   `;

function Page(props) {
  return (
    <PageWrapper key="pageWrapper">
        <MenuBar { ...props } />
      <PageContent>
        <HStack justifycontent='space-between'>
        {props.auth.isAuthenticated ? (
          props.auth.user.role === 0 ? <AdminSideMenu {...props.auth.user} /> : <UserSideMenu {...props.auth.user} />
          ) 
        : null}
          <HStack className='fade-in-content'>
            <VStack>
              <Layout>
              <Spacer size='50px' />
                { props.children }
              </Layout>
            </VStack>
          </HStack>
        </HStack>
      </PageContent>
    </PageWrapper>
  );
}
Page.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error
});

export default connect(mapStateToProps)(Page);