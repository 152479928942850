import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from "prop-types";
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import sideImage from '../../Assets/side-image-login.png';
import { Container, ContentView, Font, Form, HStack, Spacer, VStack } from "../../Component/Components";
import { ErrorComponent } from '../../Component/ErrorComponent';
import { FormField } from "../../Component/InputFields";
import { login } from "../../State/actions/auth";
import { showLoading } from '../../State/setPayload';
import { loginValidation } from '../../Util/validation';
import Page from '../Page';
import './style.css';



function Login(props) {
  // const [ loginErrors, setLoginErrors ] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginValidation)
  });

  useEffect(() => {
    if (auth?.isAuthenticated) {
      if(auth.user.role === 0){
        navigate('/admin/home')
      }
      else
        navigate('/dashboard/home');
    }
  }, [ navigate, auth.isAuthenticated, auth.user, auth.user?.compaign_create_status, props.error.error, auth ]);

  const handleLogin = (data) => {
    dispatch(showLoading());
    props.login(data, navigate);
  };

  const { loading } = props.loading;
  const { error } = props.error;
  let showError = false;
  if (error) {
    showError = true
  }

  return (
    <Page>
      <Container
        className='fade-in-content'
        display={ 'flex' }
        gap={ '0px' }
        padding='0px'
        justifycontent='center'
        alignitems='center'
        direction={ 'row' }
        width={ 'auto' }
      >
        <Container width={ '100%' } >

          <ContentView width={ '600px' }>
            <HStack>
              <Font size={ '32px' }>Account login</Font>
            </HStack>
            <Form onSubmit={ handleSubmit(handleLogin) }>
              <VStack>
                <Spacer size={ '10px' } />
                <FormField
                  name={ "email" }
                  label={ "School Email" }
                  type={ "email" }
                  register={ register }
                  placeholder={ "Enter email" }
                  errors={ errors.email }
                />
                <FormField
                  name={ "password" }
                  label={ "Password" }
                  type={ "password" }
                  register={ register }
                  placeholder={ "Enter password" }
                  errors={ errors.password }
                />
                { showError && <ErrorComponent error={ error } /> }
                <Button className='__btn__' type={ 'submit' }>
                  { loading ? <CircularProgress color='inherit' size={ 30 } /> : 'Login' }
                </Button>
                <HStack alignitems='center' justifycontent='center'>Don’t have an account? <Link to='/signup'> Sign up</Link></HStack>
                <HStack alignitems='center' justifycontent='center'>Forgot password? <Link to='/password-reset'>Help</Link></HStack>
              </VStack>
            </Form>
          </ContentView>
        </Container>
        <Container className='__hide__side__image' width={ '100%' }
          padding='0px'>
          <img className='sideImg' src={ sideImage } alt='somethng' />
        </Container>
      </Container>
    </Page>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  loading: state.loading,
  auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);


