import React from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function EditCommentDialog({ open, onClose, commentInfo, onSave }) {
  const [editData, setEditData] = React.useState({
    title: '',
    content: '',
    createdAt: '',
  });
  const formattedDate = new Date(commentInfo.comments.created_at).toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  });
  useEffect(() => {
    if (commentInfo) {
      // const formattedDate = new Date(commentInfo.comments.created_at).toLocaleDateString('en-US', {
      //   year: 'numeric', month: 'long', day: 'numeric'
      // });

      setEditData({
        title: commentInfo.comments.posted_by,
        content: commentInfo.comments.content,
        createdAt: formattedDate,
        id: commentInfo.comments._id,
      });
    }
  }, [commentInfo]);

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    onSave(editData);
    onClose();
  };

  const handleClose = () => {
    setEditData({
      title: commentInfo.comments.posted_by,
      content: commentInfo.comments.content,
      createdAt: formattedDate,
      id: commentInfo.comments._id,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Comment</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Posted by"
          type="text"
          fullWidth
          variant="outlined"
          name="title"
          value={editData.title}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Content"
          type="text"
          fullWidth
          variant="outlined"
          name="content"
          value={editData.content}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Created At"
          type="text"
          fullWidth
          variant="outlined"
          name="createdAt"
          value={editData.createdAt}
          onChange={handleChange}
          disabled={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCommentDialog;