import styled from "styled-components";
import chefStyle from '../StyleModule/style.module.css';

export const Padding = {
  p2: '2px',
  p4: '4px',
  p6: '6px',
  p8: '8px',
  p10: '10px',
  p12: '2px',
  p14: '14px',
  p16: '16px',
  p18: '18px',
  p20: '20px',
  p22: '22px',
  p24: '24px',
  p26: '26px',
  p28: '28px',
  p30: '30px',
  p32: '32px',
};
export const Size = {
  s2: '2px',
  s4: '4px',
  s6: '6px',
  s8: '8px',
  s10: '10px',
  s12: '2px',
  s14: '14px',
  s16: '16px',
  s18: '18px',
  s20: '20px',
  s22: '22px',
  s24: '24px',
  s26: '26px',
  s28: '28px',
  s30: '30px',
  s32: '32px',
  s36: '36px',
  s38: '38px',
  s40: '40px',
  s42: '42px',
  s44: '44px',
  s46: '46px',
  s48: '48px',
  s50: '50px',
};
export const Margin = {
  m2: '2px',
  m4: '4px',
  m6: '6px',
  m8: '8px',
  m10: '10px',
  m12: '2px',
  m14: '14px',
  m16: '16px',
  m18: '18px',
  m20: '20px',
  m22: '22px',
  m24: '24px',
  m26: '26px',
  m28: '28px',
  m30: '30px',
  m32: '32px',
};

export const Fragment = styled.div`
  left: 0px;
  top: 0px;
  border-radius: 10px;
  display: flex;
  position: relative;
  text-align: center;
  color: white;
`;

/**
 * @param size?: number
 * @param weight?: number
 * @param color?: string // Pass FFF or FFFFFF for white etc
 * @param algin?: string
 * @param cursor?: string
 * @param style?: string
 */
export const Font = styled.span`
  cursor: ${props => props.cursor && props.cursor};
  font-style:${props => props.style ? props.style : 'normal'};
  font-weight:${(p) => p.weight ? p.weight : 500};
  font-size:${(p) => p.size ? p.size : `20px`};
  text-align: ${p => p.align ? p.align : 'start'};
  color:${props => props.color ? props.color : '#000'};
`;
/**
 * @param margin?: string
 */
export const Spacer = styled.div`
  margin-top:${props => props.size ? props.size : '10px'} !important;
  margin-bottom:${props => props.size ? props.size : '10px'} !important;
`;


/**
 * @param margin?: string
 */
export const Space = styled.div`
  margin:${props => props.size ? props.size : '10px'};
`;

export const GridWrapper = styled.div`
  display: block !important;
  display: grid !important;
  border-radius: 10px;
  row-gap: 10px;
  column-gap: 10px;
  @media  (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr) !important;
  }
  @media  (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr) !important;
  }
  @media  (min-width: 960px) {
      grid-template-columns: repeat(3, 1fr) !important;
  }
  @media  (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr) !important;
  }
`;
/**
 * @param margin?: string
 */
export const Layout = styled.div`
  margin:${props => props.size ? props.size : '10px'};
  @media(min-width: 768px) {
      flex-grow: 1 !important;
    }
`;
/**
 * @param paddingRight?: number // padding right
 * @param paddingLeft?: number // padding left
 * @param paddingTop?:number
 * @param paddingBottom?: number
 * @param backgroundcolor?: string
 * @param alignitems?: boolean
 * @param marginLeft?: number
 * @param marginRight?: number
 * @param color?: string;
 * @param gap?: string;
 */
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: :${props => props.flexBox && props.flexBox};
  gap:${props => props.gap ? props.gap : `20px`};
  /* padding */
  padding: ${props => props.padding && props.padding} !important;
  padding-left:${props => props.paddingLeft ? props.paddingLeft : `0px`};
  padding-right:${props => props.paddingRight ? props.paddingRight : `0px`};
  padding-top:${props => props.paddingTop ? props.paddingTop : `0px`};
  padding-bottom:${props => props.paddingBottom ? props.paddingBottom : `0px`};
  align-items:${props => props.alignitems ? props.alignitems : `auto`};
  justify-content:${props => props.justifycontent ? props.justifycontent : `start`};
  background:${props => props.backgroundcolor ? props.backgroundcolor : 'transparent'} !important;
  @media(min-width: 786px) {
    width: 100% !important;
    flex-grow: 1 !important;
    padding: 0px !important;
  }
  @media(max-width: 786px) {
    width: 100% !important;
    flex-grow: 1 !important;
    padding: 0px !important;
  }
`;
export const Line = styled.div`
  border: .2px solid #CCDBF0;
  display: flex;
`;

/**
 * @param alignitems?: boolean
 */
export const Form = styled.form`
  /* Check the flex 1 back later if things are messed up */
  /* flex: 1; */ 
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items:${props => props.alignitems = 'center' ? 'center' : `auto`};
    @media(max-width: 786px) {
      min-width: 100% !important;
      flex-grow: 1 !important;
      flex: 100% !important;
    }
`;

/**
 * @param backgroundcolor?: string
 * @param alignitems?: boolean
 * @param radius?: string;
 * @param gap?: string;
 */
export const HStack = styled.div`
display: flex;
flex-direction: row;
width: 100% !important;
gap: ${props => props.gap ? props.gap : '20px'} !important;
border-radius:${props => props.radius ? props.radius : '10px'} !important;
background-color:${props => props.backgroundcolor ? props.backgroundcolor : 'transparent'} !important;
justify-content:${props => props.justifycontent ? props.justifycontent : `start`};
align-items:${props => props.alignitems ? props.alignitems : `auto`} !important;
@media(max-width: 786px) {
    width: 100% !important;
    flex-grow: 1 !important;
    flex-wrap: ${props => !props.wrap ? 'nowrap' : props.wrap}  !important;
}
`;

export const Article = styled.article`
  display: flex;
  flex-direction: row;
  width: 100% !important;
  gap: ${props => props.gap ? props.gap : '20px'} !important;
  border-radius:${props => props.radius ? props.radius : '10px'} !important;
  background-color:${props => props.backgroundcolor ? props.backgroundcolor : 'transparent'} !important;
  justify-content:${props => props.justifycontent ? props.justifycontent : `start`};
  align-items:${props => props.alignitems ? props.alignitems : `auto`} !important;
  @media(max-width: 786px) {
      width: 100% !important;
      flex-grow: 1 !important;
      flex-wrap: ${props => !props.wrap ? 'nowrap' : props.wrap}  !important;
  }
`

/**
 * @param backgroundcolor?: string
 * @param alignitems?: boolean
 * @param radius?: string;
 * @param gap?: string;
 */
export const StackedButton = styled.div`
display: flex;
flex-direction: row;
width: 100% !important;
gap: ${props => props.gap ? props.gap : '20px'} !important;
border-radius:${props => props.radius ? props.radius : '10px'} !important;
background-color:${props => props.backgroundcolor ? props.backgroundcolor : 'transparent'} !important;
justify-content:${props => props.justifycontent ? props.justifycontent : `start`};
align-items:${props => props.alignitems = 'center' ? 'center' : `auto`};
@media(max-width: 786px) {
    width: 100% !important;
    flex-grow: 1 !important;
    // flex-wrap: wrap !important;
}
`;
/**
 * @param width?: string
 * @param size?: number
 * @param weight?: number
 * @param paddingRight?: number // padding right
 * @param paddingLeft?: number // padding left
 * @param padding?: number // padding top and bottom
 * @param left?: boolean
 * @param direction?: string 'row' or 'column' : default: column
 * @param display?: string
 * @param borderColor?: string // Pass FFF or FFFFFF for white etc
 * @param backgroundcolor?: string
 * @param hovercolor?: string
 * @param alignitems?: boolean
 * @param size?: number
 * @param show?: boolean
 * @param radius?: number
 * @param justifycontent?: string
 * @param cursor?: string
 * @param color?: string;
 */
export const Container = styled.div`
  padding-left: ${props => props.pleft && props.pleft} !important;
  padding-right: ${props => props.pright && props.pright} !important;
  padding-top: ${props => props.paddingTop ? props.paddingTop : '6px'} !important;
  padding-bottom: ${props => props.paddingBottom && props.paddingBottom} !important;
  display:${props => props.display ? props.display : `block`};
  flex-direction:${props => props.direction === 'row' ? `row` : `column`};
  position:${props => props.position && props.position} !important;
  color:${props => props.color && props.color};
  justify-content:${props => props.justifycontent ? props.justifycontent : 'center'};
  background:${props => props.backgroundcolor ? props.backgroundcolor : `transparent`};
  border:${props => props.borderColor ? `2px solid ${props.borderColor}` : `none`};
  border-radius:${props => props.radius ? props.radius : '20px'} !important;
  box-sizing: border-box;
  gap: ${props => props.gap && props.gap};
  scroll-behavior: auto;
  right:${props => props.right && props.right} !important;
  left:${props => props.left && props.left} !important;
  top:${props => props.top && props.top} !important;
  bottom:${props => props.bottom && props.bottom} !important;
  align-items: ${props => props.alignitems ? props.alignitems : 'none'};
  flex-grow:${props => props.flexGrow ? props.flexGrow : `0`};
  width:${props => props.width ? props.width : 'fit-content'};
  height:${props => props.height ? props.height : 'auto'} !important;
  font-weight:${props => props.weight ? props.weight : 500};
  padding:${props => props.padding ? props.padding : '6px'} !important;
  font-size:${props => props.size ? props.size : `18px`};
  line-height: 28px;
  cursor:${props => props.cursor && props.cursor};
  &:hover {
      background: ${props => props.hovercolor && props.hovercolor} !important;
      color: ${props => !props.color ? props.color : '#000'} !important;
  }
`;

export const ContentView = styled.div`
display:flex;
flex-direction: ${props => props.direction ? props.direction : 'column'};
gap: ${props => props.gap && props.gap};
left: 0 !important;
right:0 !important;
margin:0px auto;
padding: ${props => props.padding ? props.padding : '10px'} !important;
max-width:${props => props.width ? props.width : `auto`};
justify-content: ${props => props.justifycontent && props.justifycontent} !important;
@media(min-width: 786px) {
    width: 100% !important;
}
`;
/**
 * @param width?: string
 * @param left?: boolean
 * @param direction?: string
 * @param display?: string
 * @param borderColor?: string // Pass FFF or FFFFFF for white etc
 * @param backgroundcolor?: string
 * @param hovercolor?: string
 * @param alignitems?: boolean
 * @param size?: number
 * @param show?: boolean
 * @param radius?: number
 * @param justifycontent?: string
 * @param cursor?: string
 * @param color?: string;
 */
export const IconImage = styled.img`
  cursor: pointer;
  width:${props => props.width ? props.width : `auto`};
  height:${props => props.height ? props.height : `24px`};
  position:${props => props.position && props.position};
  right:${props => props.right && props.right};
  left:${props => props.left && props.left};
  top:${props => props.top && props.top};
  bottom:${props => props.bottom && props.bottom};
`;
/**
 * @param with?: string
 * @param height?: string
 */
export const ProfileImage = styled.img`
  border-radius: ${props => props.radius ? props.radius : '100%'} !important;
  border: 0.2px solid #C4C4C4 !important;
  width:${props => props.width ? props.width : `80px`} !important;
  height:${props => props.height ? props.height : `80px`} !important;
`;
/**
 * @param radius?: number
 */
export const Image = styled.img`
  width: ${props => props.width ? props.width : 'auto'}  !important;
  ${'' /* z-index: 3 !important; */}
  display:flex;
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  left: 0px;
  object-fit: contain;
  max-width: 100%;
  height: ${props => props.height ? props.height : 'auto'}  !important;
  border-radius:${props => props.radius ? props.radius : '10px'} !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const Spinner = ({ ...rest }) => {
  return <span className={ chefStyle.__spinner__ } { ...rest }></span>;
};

export const Center = styled.div`
  display: flex;
  align-items: center!important;
  justify-content: center!important;
`