import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, ContentView, Font, Form, HStack, Size, Spacer, VStack } from "../../Component/Components";
import { FormTextarea } from "../../Component/InputFields";
import { createCampaign } from "../../State/actions/campaign";
import { getProfile } from '../../State/actions/profile';
import { showLoading } from '../../State/setPayload';
import '../../StyleModule/main_style.css';
import { clearStorage, getLocalItem } from '../../Util/localStorage';
import { campaignDescriptionValidation } from '../../Util/validation';

//===============/ Description /=====================/

function CampaignDescription(props) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(campaignDescriptionValidation)
  });
  const [ campaign, setCampaign ] = useState(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [ characterLimit, setCharacterLimit ] = useState(0);
  const auth = useSelector(state => state.auth);


  useEffect(() => {
    const checkCampaignStatus = () => {
      if (auth.user?.campaign_create_status) {
        navigate(`/profile/${auth.user._id}`);
      }
    }
    checkCampaignStatus()
    // reset(description);
  }, [ auth.user._id, auth.user?.campaign_create_status, navigate ]);

  useEffect(() => {
    // if (!auth.isAuthenticated) return navigate('/login');
    const campaignItem = getLocalItem({ key: 'campaign' });
    // const description = getLocalItem({ key: 'description' });
    // if (description) setDescription(description);
    if (campaignItem) setCampaign(campaignItem);
  }, [ navigate, auth.isAuthenticated ]);

  const completeCampaignProfile = (data) => {
    const formData = new FormData();

    formData.append('file', state);
    formData.append('funds_use', campaign.funds_use);
    formData.append('goal', campaign.goal);
    formData.append('description', data.description);

    dispatch(showLoading());
    /// Submit change before navigating to the next page

    props.createCampaign(formData,auth.user._id, navigate );
    /// Cleanup 
    clearStorage({ key: 'campaign' });
    clearStorage({ key: 'description' });
    setTimeout(() => {
      window.location.href = '/dashboard/home';
    }, 1000);
  };

  let spinner = null;

  const { loading } = props.loading;

  if (loading) {
    spinner = <HStack justifycontent='center' alignitems='center'>
      <Font color='white' algin='center'>Please wait! { ' ' }</Font>
      <CircularProgress sx={ { color: 'inherit', width: '10px' } } />
    </HStack>
  }

  return (
    <HStack wrap='wrap'>
      <VStack className='login-container'>
        <ContentView
          className="invite"
          width='800px'
          alignitems
          justifycontent='center' >
          <Spacer size={ '60px' } />

          {/* Form area */ }
          <Form onSubmit={ handleSubmit(completeCampaignProfile) }>
            <Container
              alignitems
              display='flex'
              gap='10px'
              width='100%'
              justifycontent='center'>

              <HStack alignitems>
                <Font
                  size='32px'
                  weight={ 400 }>
                  Create a GapFundr Profie
                </Font>
              </HStack>

              <Spacer size={ '10px' } />

              {/* Form area */ }
              <FormTextarea
                type={ 'text' }
                onChange={ (e) => {
                  setCharacterLimit(e.target.value.length);
                } }
                name={ 'description' }
                placeholder={ 'What’s your story? Write a little about yourself to share with donors and supporters! ' }
                register={ register }
                errors={ errors.description }
              />

              <HStack alignitems>
                <Font
                  size='16px'
                  weight={ 400 }>
                  { 500 - parseInt(characterLimit) } { ' ' }character limit
                </Font>
              </HStack>

              <Spacer size={ Size.s10 } />

              <HStack alignitems='center' justifycontent='center'>
                <Button
                  className="__btn__outlined__"
                  type={ "button" }
                  onClick={ () => {
                    navigate(-1);
                  } }>
                  <ArrowBackIos sx={ { height: 18 } } /> { ' ' } Back
                </Button>

                <Button
                  className="__btn__"
                  type={ "submit" } >
                  { spinner ? spinner : <>
                    Complete Page
                  </> }
                </Button>
              </HStack>
              <Spacer size={ Size.s10 } />
            </Container>

          </Form>
          <Spacer size={ Size.s20 } />
        </ContentView>
      </VStack>
    </HStack>
  );
};

CampaignDescription.propTypes = {
  createCampaign: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  loading: state.loading,
  auth: state.auth
});

export default connect(mapStateToProps, { createCampaign, getProfile })(CampaignDescription);