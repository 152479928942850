import React from 'react';
import CampaignCard from './CampaignCard';

const CampaignList = ({ campaigns }) => {
  return (
    <div className="campaign-container">
      {campaigns?.map(campaign => (
        <CampaignCard key={campaign?.campaign._id} campaign={campaign?.campaign} user={campaign?.user}/>
      ))}
    </div>
  );
};

export default CampaignList;