import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import edit from '../../Assets/edit.png';
import {
  Font,
  HStack,
  IconImage,
  Layout,
  Line,
  Spacer,
  VStack
} from '../../Component/Components';
import { ContainerCard } from '../../Component/ContainerCard';
import {
  getProfile,
  updateProfile
} from '../../State/actions/profile';

function ProfileSettingDisplay(props) {
  const navigate = useNavigate();

  const onClick = (showEdit) => {
    props.setShowEdit(!showEdit);
  };

  return (
    <Fragment>
      { props && <>
        <ContainerCard>
          <HStack
            style={ { padding: '20px' } }
            justifycontent='space-between'>
            <Font size='18px' weight='500'>Profile Info</Font>
            <IconImage src={ edit } onClick={ onClick }></IconImage>
          </HStack>

          <Line />

          <Layout size="40px">

            <VStack>
              <HStack wrap='wrap'>
                <HStack>
                  <Font size='16px'
                    weight='400'>{ props.profile?.first_name }</Font>
                </HStack>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profil?.middle_name ? props.profile?.middle_name : null }</Font>
                </HStack>
              </HStack>
              <HStack wrap='wrap'>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profile?.last_name }</Font>
                </HStack>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profile?.ethnicity }</Font>
                </HStack>
              </HStack>
              <HStack wrap='wrap'>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>
                    { props.profile?.dob && props.profile?.dob }
                  </Font>
                </HStack>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profile?.gender }</Font>
                </HStack>
              </HStack>
              <HStack wrap='wrap'>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profile?.dream_job }
                  </Font>
                </HStack>
                <HStack>
                  <Font
                    size='16px'
                    weight='400'>{ props.profile?.hobbies }</Font>
                </HStack>

              </HStack>
            </VStack>
          </Layout>
          <Spacer />
        </ContainerCard>

        <Spacer size='20px' />

        {/* Institution information */ }

        <ContainerCard>
          <HStack wrap='wrap'
            style={ { padding: '20px' } }
            justifycontent='space-between'>
            <Font size='18px' weight='500'>Institution</Font>
            <IconImage src={ edit } onClick={ () => navigate('/dashboard/institution') }></IconImage>
          </HStack>
          <Line />

          <Layout size="40px">
            <HStack wrap='wrap'>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.degree && props.profile?.institution?.degree }
                </Font>
              </HStack>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.education_program_track && props.profile?.institution?.education_program_track }
                </Font>
              </HStack>
            </HStack>

            <HStack wrap='wrap'>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.expected_graduation && props.profile?.institution?.expected_graduation }
                </Font>
              </HStack>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.transfer_enrollment_date && props.profile?.institution?.transfer_enrollment_date }
                </Font>
              </HStack>
            </HStack>

            <HStack wrap='wrap'>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.prospective_institution && props.profile?.institution?.prospective_institution }
                </Font>
              </HStack>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.student_institution_id && props.profile?.institution?.student_institution_id }
                </Font>
              </HStack>
            </HStack>

            <HStack wrap='wrap'>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.city && props.profile?.institution?.city }
                </Font>
              </HStack>
              <HStack>
                <Font
                  size='16px'
                  weight='400'>
                  { props.profile?.institution?.state && props.profile?.institution?.state }
                </Font>
              </HStack>

            </HStack>
          </Layout>
        </ContainerCard>

        <Spacer size='20px' />

        {/* Private information */ }
        <ContainerCard>
          <HStack
            style={ { padding: '20px' } }
            justifycontent='space-between'>
            <Font
              size='18px'
              weight='500'>Contact Info</Font>
            <IconImage src={ edit } onClick={ onClick }></IconImage>
          </HStack>
          <Line />

          <Layout size="40px">

            <VStack>
              <HStack wrap='wrap'>
                <HStack>
                  <VStack>
                    <Font
                      size='16px'
                      weight='400'>{ props.profile?.email }</Font>
                  </VStack>
                  <VStack>
                    <Font
                      size='16px'
                      weight='400'>{ props.profile?.phone_number }</Font>
                  </VStack>
                </HStack>

                <HStack wrap='wrap'>
                  <VStack>
                    <VStack>
                      <Font size='20px' weight='400'>Notification</Font>
                    </VStack>
                    <HStack>
                      <input
                        disabled={ !props.showEdit }
                        className='__checkbox__'
                        type="checkbox"
                        value={ true }
                        checked={ props.profile?.email_notification }
                      />
                      <Font size='20px' weight='400'>Email</Font>
                    </HStack>
                  </VStack>
                </HStack>
              </HStack>

            </VStack>
          </Layout>


        </ContainerCard>
        <Spacer size='20px' />
        <ContainerCard>
          <HStack
            style={ { padding: '10px' } }
            justifycontent='space-between'>
            <Font
              size='18px'
              weight='500'>Deactivate Campaign Account</Font>
            <IconImage src={ edit } onClick={ onClick }></IconImage>
          </HStack>
          <Line />
          <Layout>
            <HStack wrap='wrap'>
              <HStack>
                <input
                  disabled={ !props.showEdit }
                  className='__checkbox__'
                  type="checkbox"
                  value={ true }
                  checked={ props.profile?.deactivate_account }
                />
                <Font size='20px' weight='400'>{ props.profile?.deactivate_account ? 'Campaign is not visiable' : 'Campaign is visible' }</Font>
              </HStack>
            </HStack>
          </Layout>
        </ContainerCard>
        <Spacer />
      </>
      }
      {
        !props &&
        <VStack>
          <span className="spinner-border spinner-border-lg align-center"></span>
        </VStack>
      }
      <Spacer size={ '70px' } />
    </Fragment >
  );
};

ProfileSettingDisplay.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  profile: state.profile.profile
});

export default connect(mapStateToProps, { getProfile, updateProfile })(ProfileSettingDisplay);