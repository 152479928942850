import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';
import usericon from '../../Assets/icons8-users-32.png';
import commentsicon from '../../Assets/icons8-comments-32.png';
import signouticon from '../../Assets/icons8-sign-out-32.png';
import campaignicon from '../../Assets/icons8-campaign-32.png';
import home from '../../Assets/House.png';
import { Container, Font, HStack, IconImage, Layout, ProfileImage, Spacer, VStack } from '../../Component/Components';
import { sideMenuItems } from '../../sideMenuRoutes';
import { logout } from '../../State/actions/auth';
import userPlaceholder from '../../Assets/user_placeholder.svg';
import { useEffect, useRef, useState } from 'react';
import removeCircle from '../../Assets/remove-circle.svg';
import uploadIcon from '../../Assets/upload.svg';
import { removeProfilePhoto, uploadProfilePhoto } from '../../State/actions/profile';

function AdminSideMenu(props) {
  const navigate = useNavigate();
  // Shows the current path/next path a user selects from the side menu
  function selectContent(path) {
    navigate(`/admin/${path}`);
  }

  const { dashboardPath } = useParams();
  const [ selectedImage, setSelectedImage ] = useState();
  const mountedRef = useRef(true);
  const imageUploader = useRef(null);
  const auth = useSelector(state => state.auth);

  const handleImageUpload = (e) => {
    const [ file ] = e.target.files;
    if (file) {
      setSelectedImage(e.target.files[ 0 ]);
      const payload = new FormData();
      payload.append("file", e.target.files[ 0 ]);
      props.uploadProfilePhoto({ payload: payload });
    }
  };

  useEffect(() => {
    return () => { mountedRef.current = false; };
  }, [ dashboardPath ]);

  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [ selectedImage ]);

  const deleteFile = (file_url) => {
    props.removeProfilePhoto({ id: auth.user._id });
  };

  const handleSignOut = () => {
    props.logout()
    localStorage.removeItem('token');
    localStorage.removeItem('gapFundrJwtToken');
    window.location.href = "/";
  };

  return (
    <Static display={ props?.display }>
      <Spacer size='40px' />
      <Layout>
        <VStack>
          <Spacer size={ '10px' } />
          <VStack alignitems='center' justifycontent='center'>
            {
              props.profile_photo_url ?
                <ProfileImage
                  src={ props.profile_photo_url }
                  width={ '80px' }
                  height={ '80px' } /> :
                <ProfileImage
                  src={ userPlaceholder }
                  width={ '80px' }
                  height={ '80px' } />
            }
            <Container
              alignitems
              justifycontent='center'
              style={ { marginTop: '-40px' } }
            >
              <input
                type="file"
                accept="image/*"
                onChange={ handleImageUpload }
                ref={ imageUploader }
                style={ {
                  display: "none",
                } } />
              {
                !props.profile_photo_url ?
                  <IconImage
                    src={ uploadIcon }
                    width={ '30px' }
                    height={ '30px' }
                    onClick={ () => imageUploader.current.click() } /> :
                  <IconImage
                    src={ removeCircle }
                    width={ '30px' }
                    height={ '30px' }
                    onClick={ () => deleteFile(props.profile_photo_url) } />
              }
            </Container>
            <Font size='18px'>Hi, { props.first_name }</Font>
          </VStack>

          <VStack justifycontent={ 'start' } gap='10px'>
            <HStack
              style={ { cursor: 'pointer' } }
              alignitems='center'
              justifycontent='start'
              cursor={ 'pointer' }
              onClick={ () => selectContent(sideMenuItems.Home) }>
              <IconImage src={ home }
                style={ { color: 'gray' } } width={ '22px' } height={ '22px' }
              />
              <Font color={ '#000' } size={ '16px' } weight={ 500 }>Dashboard</Font>
            </HStack>

            <HStack
              style={ { cursor: 'pointer' } }
              justifycontent='start'
              alignitems='center'
              hovercolor={ '#CCDBF0' }
              radius={ '6px' }
              onClick={ () => selectContent(sideMenuItems.Campaigns) }>
              <IconImage src={ campaignicon } style={ { color: 'gray' } } width={ '22px' } height={ '22px' }
              />
              <Font color={ '#000' } weight={ 500 } size={ '16px' }>Campaigns</Font>
            </HStack>

            <HStack
              style={ { cursor: 'pointer' } }
              justifycontent='start'
              alignitems='center'
              cursor={ 'pointer' }
              hovercolor={ '#CCDBF0' }
              radius={ '6px' }
              onClick={ () => selectContent(sideMenuItems.Users) }>
              <IconImage src={ usericon } style={ { color: 'gray' } } width={ '22px' } height={ '22px' }
              />
              <Font color={ '#000' } weight={ 500 } size={ '16px' }>Users</Font>
            </HStack>

            <HStack
              style={ { cursor: 'pointer' } }
              justifycontent='start'
              alignitems='center'
              cursor={ 'pointer' }
              hovercolor={ '#CCDBF0' }
              radius={ '6px' }
              onClick={ () => selectContent(sideMenuItems.Comments) }>
              <IconImage src={ commentsicon } style={ { color: 'gray' } } width={ '22px' } height={ '22px' }
              />
              <Font color={ '#000' } weight={ 500 } size={ '16px' }>Comments</Font>
            </HStack>

            <Spacer size='100%' />

            <VStack width='100%' gap={ '0' } style={ { justifycontent: 'end', bottom: '0' } }>
              <HStack onClick={ handleSignOut }
                style={ { cursor: 'pointer' } }
                justifycontent='start'
                alignitems='center'
                cursor={ 'pointer' }
                hovercolor={ '#CCDBF0' }
                radius={ '6px' }                
              >
                <IconImage src={ signouticon } style={ { color: 'gray' } } width={ '22px' } height={ '22px' }
                />
                <Font color={ '#000' } weight={ 500 } size={ '16px' }>Sign out</Font>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </Layout>
    </Static >
  );
}

const Static = styled.div`
	top: 0px;
  bottom: 90px
  // overflow-x: hidden;
	position: -webkit-fixed;
	position: sticky;
	padding-right:20px;
	height: calc(100vh - 10px);
	background: #F4F4F4 !important;
	@media(max-width: 768px) {
		width: 100% !important;
		margin:0px auto; 
		display: none;
`;

AdminSideMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  removeProfilePhoto: PropTypes.func.isRequired,
  uploadProfilePhoto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout, removeProfilePhoto, uploadProfilePhoto })(AdminSideMenu);