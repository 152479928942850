import {Box, Button } from '@mui/material';
import profileImage from '../../Assets/user_placeholder.svg';
import { Font, HStack, VStack } from '../../Component/Components';
import { formatTimeAgo } from '../../Util/time';
import EditCommentDialog from '../../Component/EditCommentDialog';
import { useState } from 'react';
import { updateComment } from '../Campaign/CampaignAPI';
import { useDispatch } from 'react-redux';

export function CommentCard(props) {
  const {commentInfo ,onDelete} = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleSaveExit = (editedComment) => {
    dispatch(updateComment(commentInfo.comments.owner, editedComment));
    // Trigger the onEdit function when Save button is clicked
    // onEdit(commentInfo, editedComment);
    setEditDialogOpen(false);
  };
  // const navigate = useNavigate();
  const handleDeleteClick = () => {
    onDelete(commentInfo);
  };

  return (
    <>
    <HStack
      gap='10px'
      justifycontent='center'
      alignitems='center'
      style={{ border: '1px solid #ccc', padding: '10px' }}
      sx={{
        '&:hover': {
          backgroundColor: '#333333',
          transition: 'background-color 0.3s ease-in-out',
        },
      }}
    >
      <Box component={ 'img' }
        sx={ {
          borderRadius: '100%',
          width: 80,
          height: 80,
          objectFit: 'cover'
        } }
        src={ commentInfo?.comments.owner_profile_photo ? commentInfo?.comments.owner_profile_photo : profileImage }
      />
      <VStack gap={ '1' } width='auto'>
        <Font size={ '16px' }
          weight={ 600 }>{ commentInfo?.comments.posted_by }</Font>
        <Font size={ '14px' }
          weight={ 500 }>{ commentInfo?.comments.content } </Font>
        <Font size={ '14px' }
          weight={ 500 }>{ formatTimeAgo(commentInfo.comments.created_at) } </Font>
      </VStack>
      <Button onClick={handleEditClick}
        sx={{
          "&:hover":{
            color:"white",
            backgroundColor:"darkblue"
          }
        }}
        >Edit</Button>
      <Button onClick={handleDeleteClick}
        sx={{
          color:"red",
          "&:hover":{
            color:"white",
            backgroundColor:"red"
          }
        }}
        >Delete</Button>
    </HStack>
    <EditCommentDialog open={editDialogOpen}
      onClose={handleDialogClose}
      onSave={handleSaveExit}
      commentInfo={commentInfo}
    />
    </>
  );
}

