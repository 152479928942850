import { isEmpty } from '../../Util/validation';
import { ActionType } from '../actionTypes/index';

const initialState = {
  isAuthenticated: false,
  // isAdmin: false,
  user: {},
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case ActionType.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
      // case ActionType.SET_CURRENT_ADMIN:
      // return {
      //   ...state,
      //   isAdmin: true,
      //   user: action.payload,
      // };
    default:
      return state;
  }
}

export default authReducer;
