import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container, ContentView, Font, Form, HStack, Spacer, VStack
} from "../../Component/Components";
import { FormField } from "../../Component/InputFields";
import { signUp } from "../../State/actions/auth";
import '../../StyleModule/main_style.css';
import { getLocalItem, setLocalItem } from '../../Util/localStorage';
import { campaignInputValidation } from '../../Util/validation';


/////////////////////////////////////////////////////////////////////
//==================/ Page One /===========================/
// Fields here:
// Start Date
// campaign goal
// Funds use
// This data is saved in local storage for later access on the next page
////////////////////////////////////////////////////////////////////////

function Campaign(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(campaignInputValidation)
  });
  const [ campaignData, setCampaignData ] = useState(null);
  const [ labFees, setLabFees ] = useState('');
  const [ roomBoard, setRoomBoard ] = useState('');
  const [ mealPlan, setMealPlan ] = useState('');
  const [ onlineFees, setOnlineFees ] = useState('');
  const [ tuition, setTuition ] = useState('');
  const [ fundsUse, setFundsUse ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.auth.isAuthenticated) return navigate('/login');
    const campaign = getLocalItem({ key: 'campaign' });

    if (campaign) {
      let data = {
        goal: campaign.goal,
      };
      setCampaignData(data);
    }
  }, [ navigate, props.auth.isAuthenticated ]);

  const handleSignup = (data) => {
    
    if (labFees || tuition || onlineFees || roomBoard || mealPlan) {
      // create object
      const selectedValues = {
        labFees,
        tuition,
        roomBoard,
        mealPlan,
        onlineFees
      };

      let funds_use = '';
      // Convert object into array of strings using the values taken from the key value object
      const propertyValues = Object.values(selectedValues);
      // Loop through the converted array of strings and add the values to our 
      // temp funds_use
      propertyValues.forEach(element => {
        if (element)
          funds_use += element + ',';
      });

      // remove the last comma
      funds_use = funds_use.replace(/,(\s+)?$/, '');
      const payload = {
        goal: data.goal,
        funds_use: funds_use
      };

      // Save to local storage
      setLocalItem({ key: 'campaign', value: payload });
      navigate('/compaign/cover');
    } else {
      setFundsUse(true);
      return;
    }
  };

  useEffect(() => {
    reset(campaignData);
  }, [ reset, campaignData ]);

  return (
    <ContentView
      className="invite"
      width='800px'
      alignitems
      justifycontent='center' >
      <Spacer size={ '60px' } />

      <Form onSubmit={ handleSubmit(handleSignup) }>
        <Container
          alignitems
          display='flex'
          gap='10px'
          width='100%'
          justifycontent='center' >

          <HStack alignitems>
            <Font size='32px' weight={ 500 }> Creating GapFundr Profile</Font>
          </HStack>

          <Spacer size={ '10px' } />

          <VStack>
            <FormField
              label="What is your compaign goal? (Amount to raise)"
              name="goal"
              type="Number"
              register={ register }
              errors={ errors.goal } />
            <Font size='18px'>
              How do you plan to use the funds?
            </Font>

            <HStack>
              <VStack>
                <Button
                  type="button"
                  className='__btn__outlined__'
                  onClick={ () => {
                    setLabFees(labFees !== 'Lab Fees' ? 'Lab Fees' : '');
                    setFundsUse(false);
                  } }
                >
                  Lab Fees { ' ' }    { labFees && <CheckIcon /> }
                </Button>
              </VStack>

              <VStack>
                <Button
                  type="button"
                  className='__btn__outlined__'
                  onClick={ () => {
                    setRoomBoard(roomBoard !== 'On Campus Housing' ? 'On Campus Housing' : '');
                    setFundsUse(false);
                  } }>
                  On Campus Housing { ' ' } { roomBoard && <CheckIcon /> }
                </Button>
              </VStack>
            </HStack>

            <HStack>
              <VStack>
                <Button
                  type="button"
                  style={ {
                    background: 'transparent',
                    color: '#000',
                  } }
                  className='__btn__outlined__'
                  onClick={ () => {
                    setMealPlan(mealPlan !== 'Meal Plan' ? 'Meal Plan' : '');
                    setFundsUse(false);
                  } }>
                  Meal Plan { ' ' }  { mealPlan && <CheckIcon /> }
                </Button>
              </VStack>

              <VStack>
                <Button
                  type="button"
                  style={ {
                    color: '#1877F2',
                    border: '2px solid #1877F2'
                  } }
                  className='__btn__outlined__'
                  onClick={ () => {
                    setOnlineFees(onlineFees !== 'Online Fees' ? 'Online Fees' : '');
                    setFundsUse(false);
                  } }>
                  Online Fees  { ' ' }  { onlineFees && <CheckIcon /> }
                </Button>
              </VStack>

              <VStack>
                <Button
                  type="button"
                  style={ {
                    background: 'transparent',
                    color: '#000',
                    border: '2px solid #1877F2'
                  } }
                  className='__btn__outlined__'
                  onClick={ () => {
                    setTuition(tuition !== 'Tuition' ? 'Tuition' : '');
                    setFundsUse(false);
                  } }
                >
                  Tuition { ' ' }    { tuition && <CheckIcon /> }
                </Button>
              </VStack>
            </HStack>

            <div className="__column__">
              { fundsUse &&
                <Font
                  justifycontent="center"
                  size="16px"
                  color="red"
                  weight={ 500 }>
                  Please select how you would like to use you future funds
                </Font>
              }
            </div>
          </VStack>

          <HStack justifycontent="center">
            <Button className="__btn__"
              type={ "submit" }
              style={ {
                background: '#1877F2',
                color: 'white !important'
              } }
            >Continue { ' ' } <ArrowForwardIos sx={ { height: 18 } } /></Button>
          </HStack>
        </Container>
      </Form>

    </ContentView>
  );
};
Campaign.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth
});
export default connect(mapStateToProps, { signUp })(Campaign);