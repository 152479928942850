
import { Fragment } from 'react';
import { Container, Font, HStack, VStack, } from '../Components';
import profileImage from '../../Assets/user_placeholder.svg';
import { Box } from '@mui/material';

export default function TopSection(props) {
	return (
		<Fragment>
			{ props &&
				<Container
					justifycontent='center'
					className='fade-in-content'
					display={ 'flex' }
					position={ 'relative' }
					width={ '100%' }
					style={ { background: '#D2E4FF' } }
					right={ 0 }
					left={ 0 }
					radius={ '0px' } >
					<HStack gap='10px' justifycontent='center' alignitems='center' style={ { padding: '10px' } }>
						<VStack gap={ '1' } width='auto'>
							<Font size={ '18px' }
								weight={ 600 }>{ props?.owner?.first_name } { ' ' } { props?.owner?.last_name }</Font>
							<Font size={ '16px' }
								weight={ 500 }>{ props?.owner?.institution?.prospective_institution } </Font>
						</VStack>

					</HStack>
				</Container>
			}
		</Fragment>
	);
}