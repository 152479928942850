import axios from 'axios';
import { configOption } from '../../Util/requireAuth';
import { BASE_URL } from '../../State/Api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Font,  Spacer,} from '../../Component/Components';
import { getProfile, updateProfile } from '../../State/actions/profile';
import { getAllCampaigns, getCampaignsbyPage } from '../../State/actions/campaign';
import Pagination from './Paginate';
import Page from '../Page';
import DeleteConfirmationDialog from '../../Component/DeleteConfirmationDialog';
import DeleteDoubleCheckDialog from '../../Component/DeleteDoubleCheckDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEdit, faTrash, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import EventIcon from '@mui/icons-material/Event';
function Campaigns(props) {
  const {data, dataTotalCnt} = props;
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
	const [deleteCampaignId, setDeleteCampaignId] = useState("");

	const defaultImageUrl = "https://gapfundr-media.s3.us-west-2.amazonaws.com/assets/default_campaign_cover.jpg";


	const fetchData = async (pageIndex = -1) => {
		const pageIndexValue = pageIndex === -1? currentPageIndex : pageIndex;
		await props.getCampaignsbyPage({ page: pageIndexValue, pagesize: pageSize });
		await props.getAllCampaigns();
};
  useEffect(() => {
    fetchData();
	// eslint-disable-next-line
  }, [currentPageIndex, pageSize]);
	
  const paginate = (pageNumber) => {
		setCurrentPageIndex(pageNumber);
	};

	const numberOfPages = Math.ceil(dataTotalCnt / pageSize);
	const handlePageSizeChange = e => {
		const newPageSize = parseInt(e.target.value);
		setPageSize(newPageSize)
  }

	const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
	const [doubleCheckDialogStatus, setDoubleCheckDialogStatus] = useState(false);

  const handleDeleteClick = (id) => {
		setDeleteCampaignId(id);
    setDeleteDialogStatus(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialogStatus(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteDialogStatus(false);
		setDoubleCheckDialogStatus(true);
  };
	const handleFinalDeleteConfirm = async () => {
		setDoubleCheckDialogStatus(false);
		axios.delete(BASE_URL.concat(`/campaign/${deleteCampaignId}`), configOption())
    .then(res => {
			fetchData(1);
    })
    .catch(err => {
			console.log(err);
    });
	};

	const iconStyle = {
    marginRight: '10px', // Adjust the right margin to create space between icons
    cursor: 'pointer',
  };
	const iconCalendar = {
    marginRight: '10px', // Adjust the right margin to create space between icons
    marginLeft: '10px', // Adjust the right margin to create space between icons
    marginTop: '0px', // Adjust the right margin to create space between icons
		color: '#00a8ff',
  };

  return (
		<Page>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div><Font>Campaigns</Font></div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{/* <input type="text" placeholder="Search here" style={{ height: '30px', marginTop: '3px', marginRight: '10px'}} /> */}
					<div style={{ marginLeft: 'auto' }}>
						{numberOfPages ? (
							<Pagination 
								onPress={paginate} 
								pageCount={numberOfPages || 0} 
								handlePageSizeChange={handlePageSizeChange}
								pageSize={pageSize}/>
						) : null}
					</div>
				</div>
			</div>
      <div>
        {data ? (
				<div className="campaign-content-section">
					<div className="campaign-container">
						{data.map((currentPost) => (
							<div className="campaign-post" key={currentPost._id}>
								<img className="cover-img" src={currentPost.cover_photo?.file_url || defaultImageUrl} alt="" style={{ margin: '5px', width: 'calc(100% - 10px)' }} />
								<h2 className="title">Education for Tomorrow</h2>
								<p className="description">{currentPost.description}</p>
								<div className="card-details">
									<div className="lh-details">
										<FontAwesomeIcon
											icon={faCalendarAlt}
											style={iconCalendar}
											title="CreateAt"
										/>
										<p className="date">
											{new Date(
												`${currentPost.created_at}`
											).toLocaleDateString('en-us', {
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											})}
										</p>
									</div>
									<div style={{ marginLeft: 'auto' }}>
										<FontAwesomeIcon
											icon={faEye}
											onClick={() => navigate(`/profile/${currentPost.owner}`)}
											className="icon read-more"
											style={iconStyle}
											title="View"
										/>						
										<FontAwesomeIcon
											icon={faEdit}
											onClick={() => navigate(`/profile/edit/${currentPost.owner}`)}
											className="icon read-more"
											title="Edit"
											style={iconStyle}
										/>
										<FontAwesomeIcon
											icon={faTrash}
											onClick={() => handleDeleteClick(currentPost._id)}
											className="icon read-more"
											title="Delete"
											style={iconStyle}
										/>
										</div>
								</div>
							</div>
						))}
					</div>
					<DeleteConfirmationDialog
						open={deleteDialogStatus}
						onClose={handleCloseDialog}
						onConfirm={handleConfirmDelete}
					/>
					<DeleteDoubleCheckDialog
						open={doubleCheckDialogStatus}
						onClose={() => setDoubleCheckDialogStatus(false)}
						onConfirm={handleFinalDeleteConfirm}
					/>
				</div>
			) : (
				<div className="loading">Loading...</div>
			)}
      </div>
      <Spacer size='40px' />
		</Page>
  );
};

Campaigns.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  dataTotalCnt: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  dataTotalCnt: Number(state.campaign.allcampaign),
  data: state.campaign.currentcampaigns
});

export default connect(mapStateToProps, { getProfile, updateProfile, getAllCampaigns, getCampaignsbyPage })(Campaigns);