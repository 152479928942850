import axios from 'axios';
import { configOption } from '../../../Util/requireAuth';
import { isEmpty } from '../../../Util/validation';
import { BASE_URL } from '../../Api';
import {  hideLoading, setAllContributions, setAllUsers, setAllVisits,  setError, setGraduateUsers, setMonthlyContributions, setMontlyGoals, setThisYearCont, setThisYearUsers, setThisYearVisits, setUserDemographics} from '../../setPayload';
// import { getProfile } from '../profile';
import { handleError } from '../../../Util/handleError';

/**
 * Login 
 *  Authenticates user to access dashboard etc
 * @param {*} payload 
 * @param {*} navigate 
 * @returns 
 */
export const getSessionStats = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/users/getSessionStats`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setAllVisits(res.data.totalSessions));
          dispatch(setThisYearVisits(res.data.sessionsCreatedThisYear));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

export const getUsersStats = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/users/getUsersStats`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setAllUsers(res.data.totalUsers));
          dispatch(setThisYearUsers(res.data.usersSignedInThisYear));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

export const getTotalContribution = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/getContribution`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setAllContributions(res.data.totalAmountAllCampaigns));
          dispatch(setThisYearCont(res.data.totalAmountThisYear));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};
export const getMonthlyContribution = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/getMonthlyContributions`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setMonthlyContributions(res.data.monthlyContributions));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};
export const getMonthlyGoals = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/getMonthlyGoals`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setMontlyGoals(res.data.monthlyGoals));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};
export const getUserDemographics = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/users/getDemographics`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setUserDemographics(res.data));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

export const getGraduatingUsers = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/users/getGraduatingUsers`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setGraduateUsers(res.data));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};