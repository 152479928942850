import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentView, Font, Form, HStack, Layout, Space, Spacer, VStack } from '../../Component/Components';
import { ContainerCard } from '../../Component/ContainerCard';
import { FormField, FormTextarea, NumberField } from '../../Component/InputFields';
import { getCampaignProfile, sendContribution } from '../../State/actions/campaign';
import { showLoading } from '../../State/setPayload';
import { paymentValidation } from '../../Util/validation';
import { ErrorComponent } from '../../Component/ErrorComponent';
import Page from '../Page';
import '../style.css';
import Stripe from './Stripe';


function PaymentView(props) {
  const [ messageCount, setMessageCount ] = useState(0);
  const navigate = useNavigate();
  const [ loader, setLoader ] = useState(false);
  const [ payloadData, setPayloadata ] = useState();
  const [ total, setTotal ] = useState(0);
  const dispatch = useDispatch();
  const [ isOpen, setIsOpen ] = useState(false);
  const auth = useSelector(state => state.auth);
  const [ termsAndConditions, setTermsAndConditions ] = useState();
  const [ termsAndConditionsError, setTermsAndConditionsError ] = useState(false);
  const params = useParams();
  const stripeRef = useRef();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(paymentValidation)
  });

  useEffect(() => {
    if (params.id) {
      props.getCampaignProfile(params.id);
    } else {
      if (auth.isAuthenticated) {
        props.getCampaignProfile(auth?.user._id);
      }
    }
    return () => { };
  }, []);


  const setLoaderState = (value) => {
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  };


  const confirmPayment = (data) => {
    if (!termsAndConditions) setTermsAndConditionsError(true);
    if (data.amount && data.email && termsAndConditions) {
      setTermsAndConditionsError(false)
      setTotal(data.amount);
      setLoader(true);

      const contributionData = {
        ...data,
        _id: props.campaignProfile.owner?._id,
        student_email: props.campaignProfile.owner?.email,
        notification: props.campaignProfile.owner?.email_notification,
        student_name: props.campaignProfile.owner?.first_name,
        accept_terms: termsAndConditions
      }

      setPayloadata(contributionData)
      setTimeout(() => {
        stripeRef.current.handleSubmit(contributionData);
      }, 2000)
    }
    dispatch(showLoading());
    setIsOpen(!isOpen);
  };

  const contribution = () => {
    props.sendContribution(props.campaignProfile.owner?._id, payloadData);
  }

  const onChange = () => {
    setTermsAndConditions(!termsAndConditions);
    setTermsAndConditionsError(false);
  };

  let modifiedTotal = ((parseInt(total) * 0.1195) + parseInt(total)).toFixed(2);

  return (
    <Page>
      <Fragment>
        <ContentView width={ '1400px' } className='fade-in-content'>
          <HStack gap='5px' alignitems='center' onClick={ () => navigate(-1) } className='cursor'>
            <ArrowBack />
            <Font size='16'>Back</Font>
          </HStack>
          {/* <Spacer />
          <TopPayment { ...props } /> */}
          <Spacer />
          {/* Form area */ }
          <ContainerCard>
            <Layout>
              <Form onSubmit={ handleSubmit(confirmPayment) }>
                <HStack alignitems>
                  <Font size="22px" weight={ 600 }>Payment Information</Font>
                </HStack>
                {/* Right */ }
                <NumberField
                  left='$'
                  right='USD'
                  placeholder='00'
                  label={ 'How much would you like to donate?' }
                  name={ 'amount' }
                  type={ 'number' }
                  min={ 0 }
                  onChange={ (a) => setTotal(a.target.value) }
                  register={ register }
                  errors={ errors.amount }
                />
                <HStack>
                  <Font size={ '14px' } weight={ '400' }>All campaigns must meet minimum of $25 USD</Font>
                </HStack>
                <FormField
                  label="Email"
                  name={ 'email' }
                  type={ 'email' }
                  register={ register }
                  placeholder={ 'johndoe@example.com' }
                  errors={ errors.email }
                />
                <VStack gap='5px'>
                  <FormField
                    label="Name (Optional: It will appear as Anonymous)"
                    name={ 'name' }
                    type={ 'name' }
                    placeholder={ 'Eg. John Doe or John' }
                    register={ register }
                    errors={ errors.name }
                  />
                  <FormTextarea
                    label={ 'Write a brief note (optional)' }
                    style={ {
                      height: '10em',
                    } }
                    onChange={ e => setMessageCount(e.target.value.length) }
                    name={ 'message' }
                    type={ 'message' }
                    register={ register }
                    placeholder={ 'Leave the student a note/comment' }
                    errors={ errors.message }
                  />
                  { messageCount ?
                    <Font size='12px'>Character limit  <span style={ { color: 200 - messageCount < 5 && 'red' } }> { 200 - messageCount } </span>
                    </Font> : null
                  }
                </VStack>
                <HStack>
                  <Font>Card Information</Font>
                </HStack>
                {/* Strip payment */ }
                <Stripe price={ 10 }
                  contribution={ () => contribution() }
                  ref={ stripeRef }
                  onPaymentDone={ () => setLoaderState() } />
                <HStack alignitems='center'>
                  <input
                    className='__checkbox__'
                    type="checkbox"
                    value={ true || undefined }
                    checked={ termsAndConditions }
                    onChange={ onChange } />
                  <Font size='14px'>Do you accept both the <a href='https://gapfunr-legal-docs.s3.us-west-2.amazonaws.com/Donor+Policies+PDF.pdf' target='blank'>contributor policies</a> along with the 11.95% processing fee in support of our service</Font>
                </HStack>
                {
                  termsAndConditionsError && <ErrorComponent error={ 'Please accept the terms and conditions' } />
                }
                <HStack>
                  <Font>Payment Summary </Font>
                </HStack>
                {/* Submit button */ }
                <VStack justifycontent='end'>
                  <>
                    <HStack>
                      <Font size='16px'>Total Due: </Font>
                    </HStack>
                    <Font weight={ '600' } color={ 'black' }>$ { modifiedTotal } </Font>
                    <Font weight={ '400' } size='12px'>One-time contribution</Font>
                    <HStack>
                      <VStack>
                        <Button type="submit" className="btn" sx={ {
                          background: '#FFB35C',
                          borderRadius: '10px',
                          color: '#000000',
                          p: 2
                        } }>
                          { loader ? <HStack justifycontent='center'>
                            <Font>Processing Payment</Font>
                            <Spacer size='1px' />
                            <CircularProgress sx={ { color: 'inherit', height: 2 } } />
                            <Space />
                          </HStack> : <Font>Confirm Contribution</Font>
                          }
                        </Button>
                      </VStack>
                    </HStack>
                  </>
                </VStack>
              </Form>
            </Layout>
          </ContainerCard>
        </ContentView >
        <Spacer size={ '40px' } />
      </Fragment >
    </Page>
  );
}

PaymentView.propTypes = {
  campaignProfile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  getCampaignProfile: PropTypes.func.isRequired,
  sendContribution: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error.error,
  loading: state.loading,
  campaignProfile: state.campaign.campaignProfile,
});

export default connect(mapStateToProps, {
  getCampaignProfile,
  sendContribution
})(PaymentView);
