import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const CampaignCard = ({ campaign, user }) => {
  const navigate = useNavigate();
  const defaultImageUrl = "https://gapfundr-media.s3.us-west-2.amazonaws.com/assets/default_campaign_cover.jpg";

  const handleCardClick = () => {
    navigate(`/profile/${campaign?.owner._id || user._id}`);
  };

  return (
    <div className="campaign-post" key={campaign?._id} onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <img className="cover-img" src={campaign?.cover_photo?.file_url || defaultImageUrl} alt="" style={{ margin: '5px', width: 'calc(100% - 10px)' }} />
      <h2 className="title">{user?.first_name + " " + user?.last_name}</h2>
      <h2 className="institution">{user?.institution?.prospective_institution}</h2>
      <p className="description">{campaign?.description}</p>
      <div className="card-details">
      </div>
    </div>
  );
};

export default CampaignCard;