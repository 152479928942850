import axios from 'axios';
import { BASE_URL } from '../../State/Api';
import { configOption } from '../../Util/requireAuth';
import PropTypes from 'prop-types';
import {useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Font, Spacer, VStack } from '../../Component/Components';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getProfile, updateProfile, getAllUsersCounter, getUserList } from '../../State/actions/profile';
import Pagination from './Paginate';
import './style.css';
import Page from '../Page';
import { UserCard } from './UserCard';
import DeleteConfirmationDialog from '../../Component/DeleteConfirmationDialog';

function Users(props) {
  const { data, dataTotalCnt} = props;
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
	const [deleteUserId, setDeleteUserId] = useState("");
  const [pageSize, setPageSize] = useState(5);
	const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
	const [notification, setNotification] = useState({
		open: false,
		message: '',
		severity: 'success', // Set the severity for success/error info
	});

	const fetchData = async (pageIndex = -1) => {
		await props.getUserList({ page: currentPageIndex, pagesize: pageSize });
		await props.getAllUsersCounter();
	};
  useEffect(() => {
  	fetchData();
	// eslint-disable-next-line
  }, [currentPageIndex, pageSize]);
  const paginate = (pageNumber) => {
		setCurrentPageIndex(pageNumber);
	};

	const numberOfPages = Math.ceil(dataTotalCnt / pageSize);
	const handlePageSizeChange = e => {
		const newPageSize = parseInt(e.target.value);
		setPageSize(newPageSize)
  }

	const handleCloseNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setNotification({ ...notification, open: false });
	};

  const handleDeleteClick = (id) => {
		setDeleteUserId(id);
    setDeleteDialogStatus(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialogStatus(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteDialogStatus(false);
		axios.delete(BASE_URL.concat(`/user/${deleteUserId}`), configOption())
    .then(res => {
			fetchData(1);
			setNotification({
				open: true,
				message: 'User deleted successfully!',
				severity: 'success',
			});
    })
    .catch(err => {
			setNotification({
				open: true,
				message: 'Oops, something went wrong!',
				severity: 'error',
			});
    });
  };

  return (
    <Page>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div><Font>Users</Font></div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{/* <input type="text" placeholder="Search here" style={{ height: '30px', marginTop: '3px', marginRight: '10px'}} /> */}
					<div style={{ marginLeft: 'auto' }}>
						{numberOfPages ? (
							<Pagination 
								onPress={paginate} 
								pageCount={numberOfPages || 0} 
								handlePageSizeChange={handlePageSizeChange}
								pageSize={pageSize}/>
						) : null}
					</div>
				</div>
			</div>
			{data && 
				(
					<div>
						{data.map((userInfo) => (
							<VStack key={userInfo._id}>
								<div sx={{ustifyContent: 'start', background: '#FFFF', marginBottom: '10px', borderRadius: '10px'}} >
									<UserCard userInfo = {userInfo} onDelete = {() => handleDeleteClick(userInfo._id)}/>
								</div>
							</VStack>
						))}
					</div>
				) 
			}
			<DeleteConfirmationDialog
						open={deleteDialogStatus}
						onClose={handleCloseDialog}
						onConfirm={handleConfirmDelete}
			/>
			<Snackbar
      open={notification.open}
      autoHideDuration={3000} // Adjust the duration as needed
      onClose={handleCloseNotification}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    	>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleCloseNotification}
					severity={notification.severity}
				>
					{notification.message}
				</MuiAlert>
    	</Snackbar>
			{!data &&
				(
					<div className="loading">Loading...</div>
				)
			}
      <Spacer size='40px' />
    </Page>
  );
};

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
	dataTotalCnt: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  dataTotalCnt: state.profile.NumberOfUsers,
  data: state.profile.currentUsers
});

export default connect(mapStateToProps, { getProfile, updateProfile, getUserList, getAllUsersCounter })(Users);