import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from "prop-types";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { object, string } from 'yup';
import sideImage from '../../Assets/side-image-login.png';
import { Container, ContentView, Font, Form, HStack, Spacer, VStack } from "../../Component/Components";
import { ErrorComponent, SuccessComponent } from '../../Component/ErrorComponent';
import { FormField } from "../../Component/InputFields";
import { requestPasswordReset } from "../../State/actions/auth";
import { showLoading } from '../../State/setPayload';
import './style.css';

function PasswordReset(props) {
  const navigate = useNavigate();
  const [ success, setSuccess ] = useState('')

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(object({
      email: string()
        .email('Invalid email address')
        .required('required')
    }))
  });

  const handleRequest = async (data) => {
    // dispatch(showLoading());
    const response = await props.requestPasswordReset(data, navigate);
    setSuccess(response);
  };

  const { loading } = props;
  const { error } = props.error;
  let showError = false;
  if (error) {
    showError = true
  }

  let showLoading;
  if (loading) {
    showLoading = <CircularProgress color='inherit' size={ 30 } />
  }

  return (
    <Container
      className='fade-in-content'
      display={ 'flex' }
      gap={ '0px' }
      padding='0px'
      justifycontent='center'
      alignitems='center'
      direction={ 'row' }
      width={ 'auto' }
    >
      <Container width={ '100%' } >
        <Spacer size={ '60px' } />

        <ContentView width={ '600px' }>
          { success ? <VStack justifyContent="center" alignitems="center" style={ { marginTop: "100px" } }>
            <SuccessComponent success={ success }></SuccessComponent>
            <Link to='/login'>Go to login</Link>

          </VStack>
            : <>

              <HStack>
                <Font size={ '32px' }>Request Password Reset</Font>
              </HStack>
              <Form onSubmit={ handleSubmit(handleRequest) }>
                <VStack>
                  <Spacer size={ '10px' } />
                  <FormField
                    name={ "email" }
                    label={ "School Email" }
                    type={ "email" }
                    register={ register }
                    placeholder={ "Enter email" }
                    errors={ errors.email }
                  />
                  { showError && <ErrorComponent error={ error } /> }
                  <Button className='__btn__' type={ 'submit' }>
                    { props.loading ? showLoading : 'Submit Request' }
                  </Button>
                  <HStack alignitems='center' justifycontent='center'>
                    <Link to='/login'>Cancel Request</Link>
                  </HStack>
                </VStack>
              </Form>
            </> }
        </ContentView>
      </Container>
      <Container className='__hide__side__image' width={ '100%' }
        padding='0px'>
        <img className='sideImg' src={ sideImage } alt='somethng' />
      </Container>
    </Container>
  );
};

PasswordReset.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
  loading: state.loading.loading,
});

export default connect(mapStateToProps, { requestPasswordReset })(PasswordReset);


