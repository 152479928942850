export const educationTrack = [
    "Associate in Arts Direct",
    "Associate in Science",
    "Major Related Program(MRP)",
    "Professional - Technical Certificates",
    "Associate of Applied Science Degree(AAS)",
    "Applied Baccalaureate Degrees(BAS,BSN,BAT)",
    "Bachelor of Architecture(B.Arch.)",
    "Bachelor of Arts(B.A.)",
    "Bachelor of Business(B.B.)",
    "Bachelor of Business Administration(B.B.A.)",
    "Bachelor of Science in Business(B.S.B.)",
    "Bachelor of Canon Law(B.C.L.)",
    "Bachelor of Computer Science(B.C.S.)",
    "Bachelor of Science in Computer Science(B.S.C.S.)",
    "Bachelor of Criminal Justice(B.C.J.)",
    "Bachelor of Science in Criminal Justice(B.S.C.J.)",
    "Bachelor of Divinity(B.D.)",
    "Bachelor of Education(B.Ed.)",
    "Bachelor of Science in Education(B.S.Ed.)",
    "Bachelor of Wireless Engineering(B.W.E.)",
    "Bachelor of Engineering(B.E./ B.Eng.)",
    "Bachelor of Science in Engineering(B.S.E./ B.S.EN.)",
    "Bachelor of Science in Aerospace Engineering(B.S.A.E.)",
    "Bachelor of Science in Agricultural Engineering(B.S.A.E.)",
    "Bachelor of Science in Biological Systems(B.S.B.S.)",
    "Bachelor of Science in Biosystems and Agricultural Engineering(B.S.B.A.E.)",
    "Bachelor of Science in Biological Engineering(B.S.B.E.)",
    "Bachelor of Biomedical Engineering(B.B.m.E.)",
    "Bachelor of Science in Biomedical Engineering(B.S.B.E./ B.S.B.M.E.)",
    "Bachelor of Science in Chemical Engineering(B.S.Ch.E.)",
    "Bachelor of Science in Chemical and Biomolecular Engineering(B.S.Ch.B.E.)",
    "Bachelor of Science in Chemical and Materials Engineering(B.S.C.M.E.)",
    "Bachelor of Civil Engineering(B.C.E.)",
    "Bachelor of Science in Civil Engineering(B.S.C.E.)",
    "Bachelor of Science in Civil and Infrastructure Engineering(B.S.- C.I.E.)",
    "Bachelor of Computer Engineering(B.Comp.E.)",
    "Bachelor of Science in Computer Engineering(B.S.C.E./ B.S.Cmp.E.)",
    "Bachelor of Science in Computer Science and Engineering(B.S.C.S.E.)",
    "Bachelor of Science in Electrical and Computer Engineering(B.S.E.C.E.)",
    "Bachelor of Electrical Engineering(B.E.E.)",
    "Bachelor of Science in Electrical Engineering(B.S.E.E.)",
    "Bachelor of Science in Engineering Management(B.S.E.Mgt.)",
    "Bachelor of Science in Environmental Engineering(B.S.En.E./ B.S.Env.E.)",
    "Bachelor of Fiber Engineering(B.F.E.)",
    "Bachelor of Science in Industrial Engineering(B.S.I.E.)",
    "Bachelor of Science in Manufacturing Engineering(B.S.Mfg.E.)",
    "Bachelor of Science in Manufacturing Systems Engineering(B.S.M.S.E.)",
    "Bachelor of Science in Materials Science and Engineering(B.S.M.S.E.)",
    "Bachelor of Science in Materials Engineering(B.S.MA.E.)",
    "Bachelor of Mechanical Engineering(B.M.E.)",
    "Bachelor of Science in Mechanical Engineering(B.S.M.E.)",
    "Bachelor of Science in Metallurgical Engineering(B.S.Mt.E.)",
    "Bachelor of Science in Mining Engineering(B.S.MI.E.)",
    "Bachelor of Science in Systems(B.S.- SYST.)",
    "Bachelor of Software Engineering(B.S.W.E.)",
    "Bachelor of Science in Software Engineering(B.S.S.E.)",
    "Bachelor of Systems Engineering(B.S.E.)",
    "Bachelor of Science in Systems Engineering(B.S.S.E.)",
    "Bachelor of Engineering Technology(B.E.T.)",
    "Bachelor of Science in Engineering Technology(B.S.E.T.)",
    "Bachelor of Science in Civil Engineering Technology(B.S.C.E.T./ B.S.Civ.E.T.)",
    "Bachelor of Science in Computer Engineering Technology(B.S.C.E.T.)",
    "Bachelor of Science in Construction Engineering Technology(B.S.Con.E.T.)",
    "Bachelor of Science in Drafting Design Technology(B.S.D.D.T.)",
    "Bachelor of Science in Electrical / Electronics Technology(B.S.E.T.)",
    "Bachelor of Science in Electrical Engineering Technology(B.S.E.E.T.)",
    "Bachelor of Science in Electro - Mechanical Engineering Technology(B.S.E.M.E.T.)",
    "Bachelor of Science in Mechanical Engineering Technology(B.S.M.E.T.)",
    "Bachelor of Fine Arts(B.F.A.)",
    "Bachelor of Forestry(B.F.)",
    "Bachelor of Science in Forest Research(B.S.For.Res.)",
    "Bachelor of Hebrew Letters(B.H.L.)",
    "Bachelor of Journalism(B.J.)",
    "Bachelor of Laws(LL.B.)",
    "Bachelor of Liberal Studies(B.L.S.)",
    "Bachelor of Literature(B.Lit.)",
    "Bachelor of Marine Science(B.M.S.)",
    "Bachelor of Music(B.M.)",
    "Bachelor of Nursing(B.N.)",
    "Bachelor of Science in Nursing(B.S.N.)",
    "Bachelor of Pharmacy(B.Pharm.)",
    "Bachelor of Philosophy(B.Phil.)",
    "Bachelor of Religious Education(B.R.E.)",
    "Bachelor of Science(B.S.)",
    "Bachelor of Science in Chemistry(B.S.Ch.)",
    "Bachelor of Technology(B.T./ B.Tech.)",
    "Doctor of Arts(DA)",
    "Doctor of Business Administration(DBA)",
    "Doctor of Canon Law(JCD)",
    "Doctor of Design(DDes)",
    "Doctor of Engineering or Engineering Science(DEng,DESc,DES)",
    "Doctor of Education(EdD)",
    "Doctor of Fine Arts(DFA.)",
    "Doctor of Hebrew Letters(DHL)",
    "Doctor of Juridical Science(JSD,SJD)",
    "Doctor of Musical Arts(DMA)",
    "Doctor of Music Education(DME)",
    "Doctor of Modern Languages(DML)",
    "Doctor of Nursing Science(DNSc)",
    "Doctor of Philosophy(PhD)",
    "Doctor of Public Health(DPH)",
    "Doctor of Sacred Theology(STD)",
    "Doctor of Science(DSc,ScD)",
    "Doctor of Theology(ThD)",
    "Doctor of Acupuncture(D.Ac.,D.Acu.)",
    "Doctor of Acupuncture and Oriental Medicine(D.A.O.M.)",
    "Doctor of Anesthesia Practice(Dr.AP)",
    "Doctor of Applied Science(D.A.S.)",
    "Doctor of Architecture(D.Arch.)",
    "Doctor of Athletic Training(D.A.T.)",
    "Doctor of Audiology(Au.D)",
    "Doctor of Behavioral Health(D.B.H.)",
    "Doctor of Chemistry(D.Chem.)",
    "Doctor of Chiropractic(D.C.)",
    "Doctor of Church Music(D.C.M.)",
    "Doctor of Clinical Nutrition(D.C.N.)",
    "Doctor of Clinical Science in Speech - Language Pathology(CScD)",
    "Doctor of Comparative Law(D.C.L.)",
    "Doctor of Civil Law(D.C.L.)",
    "Doctor of Computer Science(D.C.S.)",
    "Doctor of Criminal Justice(D.C.J.)",
    "Doctor of Criminology(D.Crim.)",
    "Doctor of Divinity(Divinitatis Doctor) (D.D.)",
    "Doctor of Dental Medicine(D.M.D.)",
    "Doctor of Dental Surgery(D.D.S.)",
    "Doctor of Environmental Science and Engineering(D.Env.)",
    "Doctor of Forestry(D.F.)",
    "Doctor of Geological Science(D.G.S.)",
    "Doctor of Health Administration(D.H.A.)",
    "Doctor of Health and Safety(D.H.S.)",
    "Doctor of Health Education(D.H.Ed)",
    "Doctor of Hebrew Literature / Letters(D.H.L.)",
    "Doctor of Health Science(D.H.Sc.,D.H.S.)",
    "Doctor of Hebrew Studies(D.H.S.)",
    "Doctor of Humane Letters(D.Hum.Litt.)",
    "Doctor of Industrial Technology(D.I.T.)",
    "Doctor of Information Technology(D.I.T.)",
    "Juris Doctor(J.D.)",
    "Doctor of Law and Policy(L.P.D.,D.L.P.)",
    "Doctor of Liberal Studies(D.L.S.)",
    "Doctor of Library Science(D.L.S.)",
    "Doctor of Management(D.M.)",
    "Doctor of Medical Humanities(D.M.H.)",
    "Doctor of Medical Physics(D.M.P)",
    "Doctor of Medical Science(D.M.Sc.)",
    "Doctor of Medicine(M.D.)",
    "Doctor of Ministry(D.Min.)",
    "Doctor of Missiology(D.Miss)",
    "Doctor of Music(D.M.,D.Mus.)",
    "Doctor of Music Therapy(D.M.T.)",
    "Doctor of Naprapathic Medicine(D.N.)",
    "Doctor of Naturopathic Medicine(N.D.,N.M.D.)",
    "Doctor of Nursing Practice(D.N.P.)",
    "Doctor of Occupational Therapy(O.T.D.,D.O.T.)",
    "Doctor of Optometry(O.D.)",
    "Doctor of Organizational Leadershi",
    "Doctor of Oriental Medicine(D.O.M.,O.M.D.)",
    "Doctor of Osteopathic Medicine(D.O.)",
    "Doctor of Pastoral Counseling(D.PC)",
    "Doctor of Pastoral Music(D.P.M.)",
    "Doctor of Pharmacy(Pharm.D.)",
    "Doctor of Physical Education(D.P.E.)",
    "Doctor of Physical Therapy(D.P.T.)",
    "Doctor of Podiatric Medicine(D.P.M.)",
    "Doctor of Practical Theology(D.P.T.,D.Th.P.)",
    "Doctor of Professional Studies(D.P.S.)",
    "Doctor of Psychology(Psy.D)",
    "Doctor of Public Administration(D.P.A.)",
    "Doctor of Recreation(D.Rec.,D.R.)",
    "Doctor of Rehabilitation(Rh.D.)",
    "Doctor of Sacred Music(D.S.M.)",
    "Doctor of Science in Dentistry(D.Sc.D.)",
    "Doctor of Science and Hygiene(D.Sc.H.)",
    "Doctor of Science in Veterinary Medicine(D.Sc.V.M.)",
    "Doctor of the Science of Law(J.S.D.)",
    "Doctor of Social Science(D.S.Sc.)",
    "Doctor of Social Work(D.S.W.)",
    "Doctor of Transformational Leadership(D.T.L.)",
    "Doctor of Veterinary Medicine(D.V.M.)",
    "Master of Arts(MA)",
    "Master of Science(MSc)",
    "Master of Fine Arts(MFA)",
    "Master of Letters(MLitt)",
    "Master of Laws(LLM)",
    "Master of Engineering(MEng)",
    "Master of Research(MRes)",
    "Master of Philosophy(MPhil)",
    "Legal Practice Course(LPC)",
    "Graduate Diploma in Law(GDL)",
    "Master of Business Administration(MBA)",
    "Master of Architecture(MArch)",
    "Masters in Management(MiM)",
    "Postgraduate Certificate in Education(PGCE)",
    "Professional Graduate Diploma in Education(PGDE)",
    "N/A"
];
