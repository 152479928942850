import { yupResolver } from '@hookform/resolvers/yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  ContentView,
  Font,
  Form,
  HStack, Image, Size,
  Spacer,
  VStack
} from "../../Component/Components";
import FileUploader from '../../Component/FileUploader';
import { FormTextarea, NumberField } from "../../Component/InputFields";
import { getCampaign, removeCampaignCover, updateCampaign } from "./CampaignAPI";
import { showLoading } from '../../State/setPayload';
import '../../StyleModule/main_style.css';
import { campaignInputValidation, isEmpty } from '../../Util/validation';
import Page from '../Page';
const EditCampaign = (props) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(campaignInputValidation)
  });

  const [ campaignData, setCampaignData ] = useState(null);
  const [ fundsUseList, setFundsUseList ] = useState([]);
  const [ coverFile, setCoverFile ] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [ fileError, setFileError ] = useState(false);
  const auth = useSelector(state => state.auth);
  const [ loading, setLoading ] = useState(false);


  const handleImageUpload = (file) => {
    if (file) {
      setCoverFile(file);
    }
    const fileType = file?.type.split('/');
    const requiredFileType = 'png|jpg|jpeg|PNG|JPG|JPEG';
    const checkFileType = fileType.some(e => requiredFileType.includes(e))
    const fileSize = Math.round((file.size / 1024));
    if (checkFileType && fileSize) {
      setFileError(false)
    } else {
      setFileError(true)
    }
  };

  useEffect(() => {
    props.getCampaign(params.id);
  }, [auth]);

  useEffect(() => {
    const campaign = props.campaign;
    if (campaign) {
      let data = {
        goal: !isEmpty(campaign?.goal) ? campaign?.goal : 0,
        description: !isEmpty(campaign?.description) ? campaign?.description : 'Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.',
        cover_photo: !isEmpty(campaign?.cover_photo?.file_url) ? campaign?.cover_photo?.file_url : '',
      };
      setFundsUseList(campaign?.funds_use?.split(','));
      setCampaignData(data);
    }
  }, [ navigate, props.auth.isAuthenticated, props.campaign ]);

  useEffect(() => {
    reset(campaignData);
  }, [ reset, campaignData ]);

  const handleUpdate = (data) => {
    dispatch(showLoading());
    // create object
    let funds_use = '';

    // Loop through the converted array of strings and add the values to our 
    fundsUseList.forEach(element => {
      if (element)
        funds_use += element + ',';
    });
    // remove the last comma
    funds_use = funds_use.replace(/,(\s+)?$/, '');
    const payload = new FormData();

    if (data.description) {
      payload.append('file', coverFile);
      payload.append('funds_use', funds_use);
      payload.append('description', data.description);
      payload.append('goal', data.goal);
    } else {
      payload.append('file', coverFile);
      payload.append('funds_use', funds_use);
      payload.append('goal', data.goal);
      payload.append('description', "Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.")
    }

    setLoading(true)
    props.updateCampaign(props.campaign.owner, payload, navigate);
    setTimeout(() => {
      setLoading(false)
      navigate(-1)
    }, 5000)
  };

  const remove = (pt) => setFundsUseList([ ...fundsUseList.slice(0, pt) ]);
  const removeFile = (id) => {
    props.removeCampaignCover(id);
  }

  const addFundUseType = (type) => {
    let array = [];
    const check = fundsUseList.includes(type);
    if (!check) {
      // Create new array and add to fundsUseList
      array.push(type);
      setFundsUseList([ ...fundsUseList, ...array ]);
    }
  };

  return (
    <Page>
      <ContentView className="invite"
        alignitems
        justifycontent='center' >
        {
          !props.campaign ?
            < HStack alignitems='center' justifycontent='center'>
              <CircularProgress color='inherit' size={ 20 } />
            </HStack> :
            <>
              <Form onSubmit={ handleSubmit(handleUpdate) }>
                <Container
                  radius='10px'
                  alignitems
                  display='flex'
                  gap='10px'
                  width='100%'
                  justifycontent='center' >
                  <HStack alignitems>
                    <Font
                      size='22px'
                      weight={ 600 }>
                      Edit Campaign
                    </Font>
                  </HStack>
                  <Spacer size={ Size.s10 } />
                  <VStack>
                    <NumberField
                      placeholder='00'
                      left='$'
                      right='USD'
                      label="What is your compaign goal? (Amount to raise)"
                      name="goal"
                      type="text"
                      maxLength='10'
                      inputMode="numeric"
                      pattern="\d*"
                      register={ register }
                      errors={ errors.goal } />
                    <label style={ {
                      weight: '400',
                      size: '22px'
                    } }>
                      <Font size='16px' weight='400'>
                        How do you plan to use the funds?
                      </Font>
                    </label>
                    <HStack wrap='wrap'>
                      { fundsUseList?.map((fund, idx) => (
                        <Container
                          key={ idx }
                          radius='10px'
                          padding={ '10px' }
                          style={ {
                            background: '#56C5CA',
                            color: '#000',
                            border: '2px solid #56C5CA'
                          } }
                          onClick={ () => remove(idx) }>
                          <HStack justifycontent='center'>
                            <Font
                              size={ '14px' }
                              weight={ '400' }>
                              { fund }
                            </Font>
                            <HighlightOffIcon />
                          </HStack>
                        </Container>
                      )) }
                    </HStack>
                    { isEmpty(fundsUseList) &&
                      <Font
                        justifycontent="center"
                        size="14px"
                        color="red"
                        weight={ 500 }>
                        Please select one or more funds use below
                      </Font>
                    }
                    <Font size='16px' weight='400'>Select More</Font>
                    <HStack wrap='wrap'>
                      <Container
                        radius='10px'
                        type="button"
                        style={ {
                          color: '#000',
                          border: '2px solid #56C5CA'
                        } }
                        className={ 'card' }
                        onClick={ () => {
                          addFundUseType('Tuition');
                        } }
                        >
                        <Font
                          size={ '12px' }
                          weight={ '400' }>
                          Tuition
                        </Font>
                      </Container>
                      <Container
                        padding={ '10px' }
                        radius='10px'
                        type="button"
                        style={ {
                          color: '#000',
                          border: '2px solid #56C5CA'
                        } }
                        className='card'
                        onClick={ () => {
                          addFundUseType('Lab Fees');
                        } }
                      >
                        <HStack justifycontent='center'>
                          <Font
                            size={ '14px' }
                            weight={ '400' }>
                            Lab Fees
                          </Font>
                        </HStack>
                      </Container>
                      <Container
                        radius='10px'
                        type='button'
                        className={ 'card' }
                        style={ {
                          color: '#000',
                          border: '2px solid #56C5CACA'
                        } }
                        onClick={ () => {
                          addFundUseType('On Campus Housing');
                        } }>
                        <Font
                          size={ '14px' }
                          weight={ '400' }>
                          On Campus Housing
                        </Font>
                      </Container>
                      <Container
                        radius='10px'
                        type='button'
                        className={ 'card' }
                        style={ {
                          color: '#000',
                          border: '2px solid #56C5CA'
                        } }
                        onClick={ () => {
                          addFundUseType('Meal Plan');
                        } }>
                        <Font
                          size={ '14px' }
                          weight={ '400' }>
                          Meal Plan
                        </Font>
                      </Container>
                      <Container
                        radius='10px'
                        type="button"
                        style={ {
                          color: '#000',
                          border: '2px solid #56C5CACA'
                        } }
                        className={ 'card' }
                        onClick={ () => {
                          addFundUseType('Online Fees');
                        } }>
                        <Font
                          size={ '14px' }
                          weight={ '400' }>
                          Online Fees
                        </Font>
                      </Container>
                    </HStack>
                  </VStack>
                </Container>
                <FormTextarea
                  label={ 'Description (Optional  Field)' }
                  style={ { height: '10em' } }
                  type={ 'text' }
                  name={ 'description' }
                  placeholder={ 'Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.' }
                  register={ register }
                  errors={ errors.description }
                />
                <Container
                  radius='10px'
                  alignitems
                  display='flex'
                  gap='10px'
                  width='100%'
                  justifycontent='center'>
                  {
                    props.campaign?.cover_photo?.file_url ?
                      <VStack>
                        <HStack alignitems='center'>

                          <Font size='20px' weight='400'>
                            Delete and Upload New { ' ' }
                          </Font>
                          <RemoveCircleOutlineIcon sx={ { color: 'red', cursor: 'pointer' } } onClick={ () => removeFile(props.campaign.owner) } />
                        </HStack>
                        {
                          props.campaign?.cover_photo?.file_url &&
                          <Image src={ props.campaign?.cover_photo?.file_url } />
                        }
                      </VStack>
                      : <>
                        <FileUploader handleFile={ (file) => handleImageUpload(file) } />
                        {
                          fileError && <Font
                            justifycontent="center"
                            size="14px"
                            color="red"
                            weight={ 500 }>
                            Please upload cover image
                          </Font>
                        }
                      </>
                  }
                </Container>
                <Spacer size={ Size.s10 } />

                <HStack alignitems='center' justifycontent='center'>
                  <Button type={ "submit" } className='__button__'>
                    {
                      loading ? <HStack alignitems='center' justifycontent='center'>
                        <Font color='#FFFFFF' size='14px' weight='300'>Submit Changes</Font><CircularProgress sx={ { height: '10px', color: 'inherit' } } />
                      </HStack> : 'Submit Changes'
                    }
                  </Button>
                </HStack>
              </Form>
            </>
        }
        <Spacer size={ '50px' } />
      </ContentView>
    </Page>
  );
};

EditCampaign.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  getCampaign: PropTypes.func.isRequired,
  removeCampaignCover: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  ...state.error,
  ...state.campaign
});

export default connect(mapStateToProps, { updateCampaign, getCampaign, removeCampaignCover })(EditCampaign);
