export const animateValue = (obj, start, end, duration) => {
  start = start === undefined ? 0 : start;
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Number(Math.floor(progress * (parseInt(start) - parseInt(end)))).toLocaleString();
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};