import { Container, Font } from "./Components";

export const ErrorComponent = (props) => {
  return <Container
    display='flex'
    width='100%'
    alignitems='center'
    justifycontent={ 'center' }
    radius='10px'
    style={ { backgroundColor: 'rgba(255, 0, 79, 0.27)' } }>
    <Font size="16px" color="red" weight={ 500 }>{ props.error }</Font>
  </Container>;
};

export const SuccessComponent = (props) => {
  return <Container
    display='flex'
    width='100%'
    alignitems='center'
    justifycontent={ 'center' }
    radius='10px'
    style={ { backgroundColor: '#3CCBDA' } }>
    <Font size="16px" color="white" weight={ 500 }>{ props.success }</Font>
  </Container>;
};


export const SimpleError = (props) => {
  return <Container
    display='flex'
    width='100%'
    justifycontent={ 'center' }>
    <Font size="16px" color="red" weight={ 500 }>{ props.error }</Font>
  </Container>;
};