export const institutions = [
    "Bastyr University",
    "Central Washington University",
    "City University of Seattle ",
    "Cornish College of the Arts",
    "Eastern Washington University",
    "Gonzaga University",
    "Heritage University",
    "Northwest Indian College",
    "Northwest University",
    "Pacific Lutheran University",
    "Saint Martin's University",
    "Seattle Pacific University",
    "Seattle University",
    "The Evergreen State College",
    "University of Puget Sound",
    "University of Washington",
    "University of Washington Bothell",
    "University of Washington Tacoma",
    "Walla Walla University",
    "Washington State University",
    "Washington State University Tri - Cities",
    "Washington State University Vancouver",
    "Western Governors University ",
    "Western Washington University",
    "Whitman College",
    "Whitworth University",
    "Bellevue Community College",
    "Bellingham Technical College",
    "Big Bend Community College",
    "Cascadia Community College",
    "Centralia College",
    "Clark College",
    "Clover Park Technical College",
    "Columbia Basin College",
    "Edmunds Community College",
    "Everett Community College",
    "Grays Harbor Community College",
    "Green River Community College",
    "Highline Community College",
    "Lake Washington Institute of Technology",
    "Lower Columbia College",
    "North Seattle College",
    "Olympic College",
    "Peninsula College",
    "Pierce College",
    "Renton Technical College",
    "Seattle Central Community College",
    "Skagit Valley College",
    "Shoreline Community College",
    "South Puget Sound Community College",
    "South Seattle Community College",
    "Spokane Community College",
    "Spokane Falls Community College",
    "Tacoma Community College",
    "Walla Walla Community College",
    "Wenatchee Valley College",
    "Whatcom Community College",
    "Whitworth University",
    "Yakima Valley Community College"
];