import React from 'react';
import ReactPaginate from 'react-paginate'
import './style.css';
import {NativeSelect} from "@mui/material";

const Pagination = ({pageCount, pageSize, onPress, handlePageSizeChange}) => {
  const handlePageClick = (data) => {
    onPress(data.selected + 1)
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{marginRight: '10px'}}>
        {pageSize > 0 && (
          <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          containerClassName={"pagination justify-content-center p-3 mt-5"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        )}
      </div>
      <div style={{ marginRight: 'auto', marginTop : '5px' , padding: '10px'}}>
        <NativeSelect id="page-size-select"
          onChange={handlePageSizeChange}
          value={pageSize}
          className='centered-options'
        >
          <option value={0}> ★ Featured</option>
          <option value={5}> 5 rows per page</option>
          <option value={10}> 10 rows per page</option>
          <option value={15}> 15 rows per page</option>
          <option value={-1}> All rows</option>
        </NativeSelect>
      </div>
    </div>

  )
}
export default Pagination;
