import { Button } from '@mui/material';
import React, { useState } from 'react';
import { HStack, Font, VStack } from './Components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import imageCompression from 'browser-image-compression';

const FileUploader = props => {
  const hiddenFileInput = React.useRef(null);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = async event => {
    const file = event.target.files[0];
    const fileSize = Math.round((file.size / 1024)); // size in KB
    const fileType = file.type.split('/');
    const requiredFileType = 'png|jpg|jpeg|PNG|JPG|JPEG';
    const checkFileType = fileType.some(e => requiredFileType.includes(e));

    if (checkFileType) {
      if (fileSize > 1024) { // File size is more than 1MB
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          fileType: 'image/jpeg'
        };
        try {
          const compressedBlob = await imageCompression(file, options);
          const compressedFile = new File([compressedBlob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          console.log(compressedFile);
          setFile(compressedFile);
          setPreview(URL.createObjectURL(compressedFile));
          props.handleFile(compressedFile, setUploadProgress);
          document.getElementById('size').innerHTML = '';
        } catch (error) {
          console.error('Compression failed:', error);
          document.getElementById('size').innerHTML = '<b>Compression failed, please try another file.</b>';
        }
      } else {
        setFile(file);
        setPreview(URL.createObjectURL(file));
        props.handleFile(file, setUploadProgress);
        document.getElementById('size').innerHTML = '';
      }
    } else {
      document.getElementById('size').innerHTML = '<b>Please upload files with: png|jpg|jpeg|PNG|JPG|JPEG</b>';
    }
  };

  return (
    <VStack>
      <Font color='red' id='size' weight='400' size='16px'></Font>
      <HStack>
        <div onClick={handleClick} className="__upload__file">
          {props.title ? props.title : <Font size='16px' weight='400'>Browse and upload file</Font>}
          <br />
          <Font size='14px' weight='400'>
            Supports <b>png, jpg, jpeg</b> files up to <b>10MB</b>.
          </Font>
          <Font size='14px' weight='400'>
            On iPhones, adjust the photo size by selecting 'Actual Size' at the bottom of your screen.
          </Font>
          <br />
          {preview && (
            <div className="image-preview" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
              <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain', transition: 'opacity 0.3s ease-in-out' }} />
            </div>
          )}
          {uploadProgress > 0 && (
            <div style={{ width: 100, height: 100, margin: '10px auto' }}>
              <CircularProgressbar
                value={uploadProgress}
                text={`${uploadProgress}%`}
                styles={buildStyles({
                  textColor: "#333",
                  pathColor: "rgba(62, 152, 199, 0.85)",
                  trailColor: "#d6d6d6",
                  pathTransitionDuration: 0.5,
                  textSize: '16px',
                  strokeLinecap: 'round'
                })}
              />
            </div>
          )}
          <br />
          <Button className="__btn__outlined__" type={"button"}>Select File {' '} <UploadFileIcon /></Button>
        </div>
        <input type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </HStack>
    </VStack>
  );
}; export default FileUploader;