import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loaderSpinner from '../../Assets/oval.svg';
import { Container, ContentView, Font, Form, HStack, IconImage, Layout, Size, Spacer } from '../../Component/Components';
import { ErrorComponent } from '../../Component/ErrorComponent';
import { FormField, SelectOption } from '../../Component/InputFields';
import { educationTrack } from '../../Constants/educationTrack';
import { institutions } from '../../Constants/institutions';
import { signUp } from '../../State/actions/auth';
import { showLoading } from '../../State/setPayload';
import '../../StyleModule/main_style.css';
import { clearStorage, getLocalItem } from '../../Util/localStorage';
import { formMonthDate, signupThreeValidation } from '../../Util/validation';
import Page from '../Page';


function SignupThree(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(signupThreeValidation)
  });
  const [ signupData, setSignupData ] = useState(null);
  const [ signupDataTwo, setSignupDataTwo ] = useState(null);
  const [ signupDataThree, setSignupDataThree ] = useState(null);
  const [ signUpError, setSignUpError ] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth);
  const [ mmYYYY, setMMYYYY ] = useState("");

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard/home');
    }
    setSignUpError(null);
  }, []);

  useEffect(() => {
    reset(signupDataThree);
  }, [ navigate, props.auth.user?.campaign_page, reset, signupDataThree ]);

  useEffect(() => {
    const signupOne = getLocalItem({ key: 'signupOne' });
    if (signupOne) {
      let data = {
        phone_number: signupOne.phone_number,
        email: signupOne.email,
        password: signupOne.password,
        terms_and_conditions: true,
      };
      setSignupData(data);
    }

    const signupTwo = getLocalItem({ key: 'signupTwo' });

    if (signupTwo) {
      let data = {
        first_name: signupTwo.first_name,
        middle_name: signupTwo.middle_name,
        last_name: signupTwo.last_name,
        state: signupTwo.state,
        ethnicity: signupTwo.ethnicity,
        city: signupTwo.city,
        dob: signupTwo.dob,
        gender: signupTwo.gender,
        desired_major: signupTwo.desired_major,
      };
      setSignupDataTwo(data);
    }

    const signupThree = getLocalItem({ key: 'signupThree' });

    if (signupThree) {
      let data = {
        education_program_track: signupThree.education_program_track,
        prospective_institution: signupThree.prospective_institution,
        transfer_enrollment_date: signupThree.transfer_enrollment_date,
        student_institution_id: signupThree.student_institution_id,
        expected_graduation: signupThree.expected_graduation,
        city: signupThree.city,
        state: signupThree.state,
        degree: signupThree.degree,
      };
      setSignupDataThree(data);
    }
    setSignUpError(props.error.error);
  }, [ props.error.error ]);

  const handleSignup = (data) => {
    const institution = {
      education_program_track: data.education_program_track,
      prospective_institution: data.prospective_institution,
      student_institution_id: data.student_institution_id,
      expected_graduation: data.expected_graduation,
      city: data.city,
      state: data.state,
      degree: data.degree,
    };

    const payload = {
      first_name: signupDataTwo.first_name,
      middle_name: signupDataTwo.middle_name,
      last_name: signupDataTwo.last_name,
      email: signupData.email,
      password: signupData.password,
      phone_number: signupData.phone_number,
      terms_and_conditions: signupData.terms_and_conditions,
      user_type: 'student',
      dob: signupDataTwo.dob,
      gender: signupDataTwo.gender,
      ethnicity: signupDataTwo.ethnicity,
      city: signupDataTwo.city,
      state: signupDataTwo.state,
      desired_major: signupDataTwo.desired_major,
      institution: institution,
      isFirstSignin: true,
    };

    dispatch(showLoading());
    clearStorage({ key: 'signupThree' });
    clearStorage({ key: 'signupOne' });
    clearStorage({ key: 'signupTwo' });
    setSignUpError(null);
    props.signUp(payload, navigate);
  };

  const handleFormattedDate = (e) => {
    const formattedDate = formMonthDate(e.target.value)
    setMMYYYY(formattedDate)
  }
  let spinner = null;
  const { loading } = props.loading;

  if (loading) {
    spinner = <IconImage src={ loaderSpinner } />;
  }

  return (
    <Page>
      <Layout size='0px'>
        <ContentView
          className='invite fade-in-content'
          width='800px'
          alignitems
          justifycontent='center' >
          <Spacer size={ Size.s40 } />

          {/* Form area */ }
          <Form onSubmit={ handleSubmit(handleSignup) }>
            <Container
              alignitems
              display='flex'
              gap='10px'
              width='100%'
              justifycontent='center'>

              <HStack alignitems>
                <Font size='26px' weight={ 600 }>Institution Information</Font>
              </HStack>

              <Spacer size={ Size.s10 } />

              {/* Form area */ }
              <div className='__row__ __address__column__'>
                <SelectOption
                  label='Prospective Institution or College'
                  name='prospective_institution'
                  type='text'
                  register={ register }
                  errors={ errors.prospective_institution }>
                  { institutions.map((college, index) => (
                    <option key={ index } value={ college }>{ college }</option>
                  )) }
                </SelectOption>

                <SelectOption
                  label='Degree (Declared/Of Interest)'
                  name='degree'
                  type='text'
                  register={ register }
                  errors={ errors.degree }>
                  <option value=''>Select</option>
                  <option value='Bachelors'>Bachelors</option>
                  <option value='Associates/ Technical Certificates'>Associates/ Technical Certificates</option>
                  <option value='Masters'>Masters</option>
                  <option value='Doctorate'>Doctorate</option>
                  <option value='Prefer not to say'>Not Decided</option>
                </SelectOption>
              </div>

              <div className='__row__ __address__column__'>
                <FormField
                  label='City'
                  name='city'
                  type='text'
                  placeholder='ex. Seattle'
                  register={ register }
                  errors={ errors.city }
                />
                <FormField
                  label='Student ID (ex: A12345678 or HS)'
                  name='student_institution_id'
                  type='text'
                  maxLength='9'
                  placeholder='ex:P000513614 or HS'
                  register={ register }
                  errors={ errors.student_institution_id }
                />
              </div>

              <div className='__row__ __address__column__'>
                <SelectOption
                  style={ { display: 'none !important' } }
                  label='State'
                  name='state'
                  type='text'
                  register={ register }
                  errors={ errors.state }>
                  <option value='Washington'>Washington</option>
                </SelectOption>

                <FormField
                  label='Expected Graduation'
                  name='expected_graduation'
                  type='text'
                  placeholder='mm/yyyy'
                  value={ mmYYYY }
                  onChange={ (e) => handleFormattedDate(e) }
                  register={ register }
                  errors={ errors.expected_graduation }
                />
              </div>

              <div className='__row__ __address__column__'>
                <SelectOption
                  label='Educational Track/Enter N/A'
                  name='education_program_track'
                  type='text'
                  register={ register }
                  errors={ errors.education_program_track }>
                  { educationTrack.map((et) => (
                    <option value={ et } key={ et }>{ et }</option>
                  )) }
                </SelectOption>
              </div>

              <Spacer size={ Size.s2 } />

              { signUpError && <ErrorComponent error={ signUpError } /> }

              <Spacer size={ Size.s2 } />

              <HStack justifycontent='center'>
                <Button className='__btn__outlined__'
                  type={ 'button' }
                  onClick={ () => navigate(-1) }>
                  <ArrowBackIosIcon /> Back
                </Button>
                <Button className='__btn__' type={ 'submit' }>
                  { spinner ? spinner : 'Sign Up' }
                </Button>
              </HStack>
              <Spacer size={ Size.s10 } />
            </Container>
          </Form>
          <Spacer size={ Size.s20 } />
        </ContentView>
      </Layout>
    </Page>
  );
};

SignupThree.propTypes = {
  auth: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  loading: state.loading,
  auth: state.auth
});

export default connect(mapStateToProps, { signUp })(SignupThree);