import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isEmpty } from "./validation";
import PropTypes from 'prop-types';
import { connect, useSelector } from "react-redux";
import { getLocalItem } from "./localStorage";

const Dashboard = (props) => {
	// const account = useSelector(state => state.auth);
	// const location = useLocation();
	return <Outlet />;
};

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps, null)(Dashboard);

export function configOption() {

	const token = !isEmpty(getLocalItem({ key: 'gapFundrJwtToken' })) ? getLocalItem({ key: 'gapFundrJwtToken' }) : '';
	return {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	};
}

export const ProtectRoute = ({ element: RouteComponent }) => {
	const token = getLocalItem({ key: 'gapFundrJwtToken' });
	const account = useSelector((state) => state.auth);
	if (token && account.user.role === 0) {
		return <RouteComponent />;
	}
	return <Navigate to="/" />;
};

export const PrivateRoute = ({ element: RouteComponent }) => {
	const token = getLocalItem({ key: 'gapFundrJwtToken' });
	if (token) {
		return <RouteComponent />;
	}
	return <Navigate to="/" />;
};

// Not used
export const Dashboardboard = ({ element: RouteComponent }) => {
	const account = useSelector((state) => state.auth);
	if (!account.isAuthenticated) {
		return <RouteComponent />;
	}
	return <Navigate to="/" />;
};