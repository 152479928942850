import axios from 'axios';
import { configOption } from '../../../Util/requireAuth';
import { isEmpty } from '../../../Util/validation';
import { BASE_URL } from '../../Api';
import { clearCurrentProfile, hideLoading, setCurrentUser, setError, setProfile, setToken } from '../../setPayload';

/**
 * Login 
 *  Authenticates user to access dashboard etc
 * @param {*} payload 
 * @param {*} navigate 
 * @returns 
 */
export const login = (payload, navigate) => (dispatch) => {
  axios.post(BASE_URL.concat('/login'), payload)
    .then(res => {
      dispatch(hideLoading());
      const data = res.data;
      setToken(data.token);
      dispatch(setProfile(data.user));
      dispatch(setCurrentUser(data.user));
      dispatch(setError({}));

    }).catch(err => {
      dispatch(hideLoading());
      return dispatch(setError(err.response.data));
    });
};

/**
 * Sign up
 *  Creates a user account
 * @param {*} payload 
 * @param {*} navigate 
 * @returns 
 */
export const signUp = (payload, navigate) => (dispatch) => {
  axios.post(BASE_URL.concat('/signup'), payload)
    .then(res => {
      dispatch(hideLoading());
      const data = res.data;
      setToken(data.token);
      dispatch(setProfile(data.user));
      dispatch(setCurrentUser(data.user));
      dispatch(setError({}));
      navigate('/campaign/create', { state: 'success' });
    }).catch(err => {
      dispatch(hideLoading());
      return dispatch(setError(err.response.data));
    });
};

/**
 * Password Rest
 *  Rests user password
 * @param {*} id 
 * @param {*} payload 
 * @returns 
 */
export const passwordReset = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/password-reset/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        // double check this part of the solution. 
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          clearCurrentProfile();
          dispatch(setError({}));
          window.location.href = '/login';
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      // double check this part of the solution. 
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

/**
 * Request Password Reset
 * @param {*} id 
 * @param {*} payload 
 * @returns 
 */
export const requestPasswordReset = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.put(BASE_URL.concat('/request-password-reset'), payload)
      .then(res => {
        
        dispatch(hideLoading());
        dispatch(setError({}));
        resolve(res.data.success);
      }).catch(err => {
        dispatch(hideLoading());
        dispatch(setError(err.response.data));
        reject(err.response);
      })
  })
};


export const logout = () => (dispatch) => {
  dispatch(hideLoading());
  dispatch(clearCurrentProfile());
  
};



