import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from "react-redux";
import { VStack } from "../../Component/Components";
import { ContainerCard } from '../../Component/ContainerCard';
import { getContributions } from '../../State/actions/campaign';
import DonationItem from "./DonationItem";

function DonationList(props) {

  return (
    <Fragment>
      <VStack gap='10px'>
        {
          props?.contributions?.map((d) => (
            <ContainerCard key={ d._id }>
              <DonationItem { ...d } />
            </ContainerCard>
          ))
        }
      </VStack>
    </Fragment >
  );
}

DonationList.propTypes = {
  auth: PropTypes.object.isRequired,
  getContributions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getContributions })(DonationList);