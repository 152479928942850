import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContentView, Font, Form, HStack, Layout, Size, Spacer, VStack } from '../../Component/Components';
import { FormField, SelectOption } from '../../Component/InputFields';
import { listOfStates } from '../../Constants/lisOfStates';
import { signUp } from '../../State/actions/auth';
import '../../StyleModule/main_style.css';
import { getLocalItem, setLocalItem } from '../../Util/localStorage';
import { formattedDOB, signupTwoValidation } from '../../Util/validation';
import Page from '../Page';

function SignupTwo(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(signupTwoValidation)
  });
  const [ dob, setDOB ] = useState("")
  const [ signupData, setSignupData ] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    reset(signupData);
  }, [ reset, signupData ]);

  useEffect(() => {
    const signupTwo = getLocalItem({ key: 'signupTwo' });
    if (signupTwo) {
      let data = {
        first_name: signupTwo.first_name,
        middle_name: signupTwo.middle_name,
        last_name: signupTwo.last_name,
        state: signupTwo.state,
        ethnicity: signupTwo.ethnicity,
        city: signupTwo.city,
        dob: signupTwo.dob,
        gender: signupTwo.gender,
        desired_major: signupTwo.desired_major,
      };
      setSignupData(data);
    }
  }, [ props.error.error ]);

  const handleSignup = (data) => {
    const payload = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      state: data.state,
      ethnicity: data.ethnicity,
      city: data.city,
      dob: data.dob,
      gender: data.gender,
      desired_major: data.desired_major,
    };

    // Save to local storage
    setLocalItem({ key: 'signupTwo', value: payload });
    navigate('/step-three');
    return;
  };

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formatDOB = formattedDOB(e.target.value);
    // we'll set the input value using our setInputValue
    setDOB(formatDOB);
  };

  return (
    <Page>
      <Layout size='0px'>
        <ContentView
          className='invite fade-in-content'
          width='800px'
          alignitems
          justifycontent='center'>
          <Spacer size={ Size.s40 } />
          {/* Form area */ }
          <Form onSubmit={ handleSubmit(handleSignup) }>
            <VStack>
              <HStack alignitems>
                <Font size='32px' weight={ 500 }>
                  Personal Information
                </Font>
              </HStack>

              {/* Form area */ }
              <HStack wrap='wrap' justifycontent='center'>
                <FormField
                  label='First Name'
                  name='first_name'
                  type='text'
                  placeholder='ex. John'
                  register={ register }
                  errors={ errors.first_name }
                />
                <FormField
                  label='Middle Name (Optional)'
                  name='middle_name'
                  type='text'
                  placeholder='ex. J. or Jones'
                  register={ register }
                  errors={ errors.middle_name }
                />
                <FormField
                  label='Last Name'
                  name='last_name'
                  type='last_name'
                  placeholder='Jonathan'
                  register={ register }
                  errors={ errors.last_name }
                />
              </HStack>

              <HStack wrap='wrap'>
                <FormField
                  label='What city do you live in? '
                  name='city'
                  type='text'
                  placeholder='ex. Seattle'
                  register={ register }
                  errors={ errors.city }
                />

                <SelectOption
                  style={ { display: 'none !important' } }
                  label='State'
                  name='state'
                  type='text'
                  register={ register }
                  errors={ errors.state }
                >
                  { Object.keys(listOfStates).map((key, idx) =>
                    (<option value={ key } key={ idx }>{ listOfStates[ key ] }</option>)) }
                </SelectOption>
              </HStack>

              <HStack wrap='wrap'>
                <SelectOption
                  style={ { display: 'none !important' } }
                  label='Ethnicity'
                  name='ethnicity'
                  type='text'
                  register={ register }
                  errors={ errors.ethnicity }
                >
                  <option value=''>Select</option>
                  <option value='Asian'>Asian</option>
                  <option value='Black, including African American'>Black, including African American</option>
                  <option value='Hispanic, Latino/a, Latinx'>Hispanic, Latino/a, Latinx</option>
                  <option value='Middle Eastern or North African'>Middle Eastern or North African</option>
                  <option value='Native American or Alaska Native'>Native American or Alaska Native</option>
                  <option value='Pacific Islander, including Native Hawaiian'>Pacific Islander, including Native Hawaiian</option>
                  <option value='White or Caucasian'>White or Caucasian</option>
                  <option value='Prefer not to say'>Prefer not to say</option>
                </SelectOption>
                <FormField
                  label='Date of Birth'
                  name='dob'
                  placeholder='01/01/2000'
                  value={ dob }
                  type='text'
                  onChange={ (e) => handleInput(e) }
                  register={ register }
                  errors={ errors.dob }
                />

                <SelectOption
                  label='Gender'
                  name='gender'
                  type='text'
                  register={ register }
                  errors={ errors.gender }
                >
                  <option value=''>Select</option>
                  <option value='Female'>Female</option>
                  <option value='Male'>Male</option>
                  <option value='Non-binary'>Non-binary</option>
                  <option value='Prefer not to say'>Prefer not to say</option>
                </SelectOption>
              </HStack>

              <HStack wrap='wrap'>
              <FormField
                  label='What is your desired major? '
                  name='desired_major'
                  type='text'
                  placeholder='ex. Computer Science'
                  register={ register }
                  errors={ errors.desired_major }
                />
              </HStack>
              <Spacer size={ Size.s10 } />

              <HStack justifycontent='center' alignitems='center'>
                <Button className='__btn__outlined__' type={ 'button' } onClick={ () => { navigate(-1) } }>
                  <ArrowBackIos sx={ { height: 16, color: '#2B81F3' } } /> Back
                </Button>
                <Button className='__btn__' type={ 'submit' }>
                  Next <ArrowForwardIos sx={ { height: 16 } } />
                </Button>
              </HStack>
            </VStack>
          </Form>
        </ContentView>
      </Layout >
    </Page>
  );
};

SignupTwo.propTypes = {
  auth: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth
});

export default connect(mapStateToProps, { signUp })(SignupTwo);