import { string, boolean, object, number, ref } from 'yup';
import moment from 'moment';

export const isEmpty = (value) => {
	return value === undefined ||
		value === null ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0) ||
		(Object.getOwnPropertyNames(value).length === 0 && value.constructor === Object);
};

// Phone number
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export function formatPhoneNumber(value) {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '');

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6, 10)}`;
}

export function formattedDOB(value) {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const dob = value.replace(/[^\d]/g, '');

	// dobLength is used to know when to apply our formatting for the phone number
	const dobLength = dob.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (dobLength < 3) return dob;

	// if dobLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (dobLength < 5) {
		return `${dob.slice(0, 2)}/${dob.slice(2)}`;
	}

	// finally, if the dobLength is greater then 6, we add the last
	// bit of formatting and return it.
	return `${dob.slice(0, 2)}/${dob.slice(2, 4)}/${dob.slice(4, 8)}`;
}

export function formMonthDate(value) {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const mmYYYY = value.replace(/[^\d]/g, '');

	// mmYYYYLength is used to know when to apply our formatting for the phone number
	const mmYYYYLength = mmYYYY.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (mmYYYYLength < 3) return mmYYYY;

	// if mmYYYYLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (mmYYYYLength < 6) {
		return `${mmYYYY.slice(0, 2)}/${mmYYYY.slice(2)}`;
	}

	// finally, if the mmYYYYLength is greater then 6, we add the last
	// bit of formatting and return it.
	return `${mmYYYY.slice(0, 2)}/${mmYYYY.slice(2, 6)}`;
}

// Full Name Validation
export const fullNameRegExp = /^([a-zA-z,/.-]+)\s([a-zA-z,/.-]+)$/;

export const paymentValidation = object({
	amount: number().typeError('Must be a number').min(25, '$25 Minimum campaign'),
	name: string(),
	email: string()
		.email('Invalid email address')
		.required('Field is required')
		.test(
			'len',
			'Can not be less than 2 characters or too long',
			(val) => val.length >= 2 && val.length <= 50),
}).required('Field is required');

// Login
export const loginValidation = object({
	email: string().email('Invalid email address').required('Please enter email address'),
	password: string().min(6, 'Must be 6 characters or more').required('Please enter password')
})

// Validate form input
export const signupTwoValidation = object({
	first_name: string().required('Field is required'),
	middle_name: string(),
	last_name: string().required('Field is required'),
	dob: string().required('Field is required').test(
		'DOB',
		'You must be 16 years old',
		value => {
			return moment().diff(moment(value), 'years') >= 16;
		}
	),
	ethnicity: string().required('Field is required'),
	city: string().required('Field is required'),
	state: string().required('Field is required'),
	gender: string().required('Field is required')
}).required();

export const validateUpdateUserProfile = object({
	first_name: string().required('First name is required'),
	middle_name: string(),
	email: string()
		.email('Invalid email address')
		.required('required'),
	last_name: string().required('Last name is required'),
	dob: string().min(10, "Please enter the correct DOB").max(10).required('Date of birth is required'),
	phone_number: string().required('Field is required')
		.max(14, "Please enter the correct number")
		.min(14, "Please enter the correct number"),
	ethnicity: string().required('Ethnicity field is required'),
	gender: string().required('Gender is required'),
	dream_job: string().required('Dream job is required'),
	hobbies: string().required('Hobbies is required'),
	street: string().required('Street is required'),
	zip_code: string().required('Zip code is required'),
	city: string().required('City is required'),
	state: string().required('State is required'),
}).required();


export const validatePrivateUserProfile = object({
	current_password: string().min(6, 'Password too short').required('Password is required'),
	new_password: string().min(6, 'Field too short').required('Field is required'),
	confirm_new_password: string().min(6, 'Password is too short')
		.oneOf([ ref('new_password'), null ], 'Password must match').required('Password is required'),
}).required();


// Validate form input
export const campaignInputValidation = object({
	goal: number().positive().integer()
		.typeError('Goal must be greater than $ 500')
		.min(500, 'Goal must be greater than $ 500')
		.required('Please provide amount'),
	description: String(),
})

// Validate form input
export const campaignDescriptionValidation = object({
	description: string()
})

// Validate form input
export const signupThreeValidation = object({
	education_program_track: string().required('Education track is required'),
	prospective_institution: string().required('Prospective institution is required'),
	student_institution_id: string().min(2, 'Must be 2 or 9 characters/values')
		.required('Student ID is required'),
	expected_graduation: string().required('Expected graduation is required'),
	city: string().required('City is required'),
	state: string().required('State is required'),
	degree: string().required('Degree is required')
}).required();

// Validate form input
export const signupOne = object({
	email: string()
		.email('Invalid email address')
		.required('required'),
	terms_and_conditions: boolean().required(),
	password: string()
		.min(1, 'Must be 6 characters or more')
		.required('Please enter password'),
	phone_number: string()
		.matches(phoneRegExp, 'Please enter correct phone number')
		.max(14, 'Please enter the correct phone numnber')
		.required('Field is required'),
}).required();

// Chef Info Validation
export const infoValidation = object({
	first: string()
		.matches(fullNameRegExp, 'Field is required: eg John Doe')
		.min(4, 'Field is required: eg John Doe')
		.required('Field is required'),
	phone_number: string()
		.matches(phoneRegExp, 'Please enter correct phone number')
		.max(10, 'Please enter phone number')
		.required('Field is required'),
	street: string()
		.required('Street is required'),
	city: string()
		.max(50, 'Must be less then 50')
		.required('Street is required'),
	state: string()
		.max(50, 'Must be less then 50')
		.required('State is required'),
	zip_code: string()
		.max(5, 'Zip code must be 5')
		.required('Zip code is required'),
	about: string().required('About is required'),
}).required();
