import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const reactDocument = document.getElementById('root');
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ theme } >
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  reactDocument
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
