import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ContentView, Font, Form, HStack, Layout, Size, Spacer, VStack } from "../../Component/Components";
import { ErrorComponent, SimpleError } from '../../Component/ErrorComponent';
import { TextField } from "../../Component/InputFields";
import { signUp } from "../../State/actions/auth";
import '../../StyleModule/main_style.css';
import { setLocalItem } from '../../Util/localStorage';
import { formatPhoneNumber } from '../../Util/validation';
import Page from '../Page';
import { setError } from '../../State/setPayload';
import { useDispatch } from 'react-redux';
import './style.css';

function SignupModal(props) {
  const [ termsAndConditionsError, setTermsAndConditionsError ] = useState(false);
  const [ termsAndConditions, setTermsAndConditions ] = useState(false);
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();
  const [ error, setEmailError ] = useState({ email: false });

  const [ passwordError, setPasswordError ] = useState();
  const [ phoneNumberError, setPhoneNumberError ] = useState({
    phoneNumber: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setError({}));
  },[]);

  const handleInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
    setPhoneNumberError({ phoneNumber: false });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    let valid = true;
    if (!password || password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      valid = false;
    } else {
      setPasswordError(null);
    }

    if (!confirmPassword || confirmPassword !== password) {
      setPasswordError('Passwords do not match');
      valid = false;
    }

    if (!phoneNumber) {
      setPhoneNumberError({ phoneNumber: true });
      valid = false;
    }

    if (!email) {
      setEmailError({ email: true });
      valid = false;
    }

    if (!termsAndConditions) {
      setTermsAndConditionsError(true);
      valid = false;
    }

    if (valid) {
      setTermsAndConditionsError(false);
      const payload = {
        password: password,
        phone_number: phoneNumber,
        email: email,
        terms_and_conditions: termsAndConditions,
      };
      setLocalItem({ key: 'signupOne', value: payload });
      navigate("/step-two");
    }
  };

  const onChange = () => {
    setTermsAndConditions(!termsAndConditions);
    setTermsAndConditionsError(false);
  };

  return (
    <Page>
      <ContentView
        className='fade-in-content'
        display={ 'flex' }
        gap={ '0px' }
        padding='0px'
        justifycontent='center'
        alignitems='center'
        direction={ 'column' }
        width={ '600px' }
      >
        <Spacer size={ '40px' } />
        <VStack>
          <Layout>
            <Form onSubmit={ handleSignup }>
              <VStack>
                <HStack alignitems='center'>
                  <Font size={ '32px' }>Create New Account</Font>
                </HStack>
                <TextField
                  label="Phone Number"
                  placeholder=''
                  value={ phoneNumber }
                  name="phone_number"
                  type="text"
                  onChange={ (e) => handleInput(e) }
                  errors={ phoneNumberError?.phoneNumber }
                />
                <TextField
                  label="School Email"
                  name="email"
                  type="email"
                  onChange={ (e) => {
                    setEmail(e.target.value);
                    setEmailError({ email: false });
                  } }
                  errors={ error?.email }
                />
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  onChange={ (e) => setPassword(e.target.value) }
                  errors={ passwordError?.password }
                />
                <TextField
                  label="Confirm Password"
                  name="password"
                  type="password"
                  onChange={ (e) => setConfirmPassword(e.target.value) }
                  errors={ passwordError?.confirmPassword }
                />

                { passwordError && <HStack><SimpleError error={passwordError} /></HStack> }

                {/* Checkbox terms and condition */ }
                <HStack alignitems='center'>
                  <input
                    className='__checkbox__'
                    type="checkbox"
                    value={ true }
                    checked={ termsAndConditions }
                    onChange={ onChange } />

                  <Font size={ '16px' } weight={ '500' }>
                    By creating an account I agree to the
                    <a href="https://gapfunr-legal-docs.s3.us-west-2.amazonaws.com/Website+Terms+of+Use_v2-2+PDF.pdf" target='blank'>Terms & Conditions</a> and { ' ' }
                    <a href='https://gapfunr-legal-docs.s3.us-west-2.amazonaws.com/Privacy+Policy_Revised+for+Pledge-2+PDF.pdf' target='blank'>Privacy Policy.</a>
                  </Font>
                </HStack>

                {/* Show error here too */ }

                { termsAndConditionsError && <ErrorComponent error={ '	Please accept the terms and conditions' } /> }
                <HStack justifycontent='center'>
                  <Button className="__btn__" type={ "submit" }>
                    Continue <ArrowForwardIos sx={ { height: 16 } } />
                  </Button>
                </HStack>
              </VStack>
            </Form>
          </Layout>
          <HStack alignitems='center' justifycontent='center'>
            Got an account already? <Link to='/login'>Login</Link>
          </HStack>
          <Spacer size={ Size.s20 } />
        </VStack>
      </ContentView>
    </Page>
  );
};

SignupModal.propTypes = {
  auth: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth
});

export default connect(mapStateToProps, { signUp })(SignupModal);