import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import {Dialog, DialogTitle, DialogContent, DialogContentText, Button, CircularProgress, Paper } from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from "prop-types";
import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ContentView,
  Font,
  Form,
  HStack, Size,
  Spacer,
  VStack
} from "../../Component/Components";
import FileUploader from '../../Component/FileUploader';
import imageCompression from 'browser-image-compression';
import { FormTextarea, NumberField } from "../../Component/InputFields";
import { createCampaign } from './CampaignAPI';
import { showLoading } from '../../State/setPayload';
import '../../StyleModule/main_style.css';
import { campaignInputValidation } from '../../Util/validation';
import Page from '../Page';
import Confetti from 'react-confetti';
import {useWindowSize} from 'react-use';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: '2px solid #000',
  boxShadow: 'none',
  color: 'white',
  padding: theme.spacing(2),
}));

const CreateCampaign = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(campaignInputValidation)
  });
  const [ coverFile, setCoverFile ] = useState();
  const [ labFees, setLabFees ] = useState('');
  const [ roomBoard, setRoomBoard ] = useState('');
  const [ mealPlan, setMealPlan ] = useState('');
  const [ onlineFees, setOnlineFees ] = useState('');
  const [ tuition, setTuition ] = useState('');
  const [ fundsUse, setFundsUse ] = useState(false);
  const [ fundsUseError, setFundsUseError ] = useState(false);
  const [ coverFileRequired, setCoverFileRequired ] = useState(false);
  const [ defaultDescription, setDefaultDescription ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const auth = useSelector(state => state.auth);

  const {width, height} = useWindowSize();
  const [modalOpen, setModalOpen] = useState(true);
  const [confetti, setConfetti] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  const handleClose = () => {
    setModalOpen(false);
    setConfetti(false);
  }

  const handleNext = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      handleClose();
    }
  }

  const dialogContent = () => {
    switch (currentStep) {
      case 1:
        return "Here is where the funding begins! Take a moment and personalize your page. Remember, the more effort you put into personalizing your page, the more funds you will receive! Create your Campaign to get started!";
      case 2:
        return "Write a heartfelt why! Take some time to create or revise, why you need this funding for college in the What is Your Story field. Be sure to include what your goals are and how this funding will help you achieve your goals.";
      case 3:
        return "Ask for the amount you need and take a moment to share where your funds will be most beneficial for you at school.";
      case 4:
        return "When you achieve your goal you can always jump back in and increase your ask. Don’t forget to pick a fun engaging picture that will share donors you representing your college or HS.";
      default:
        return "Welcome!";
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (modalOpen || confetti) {
        handleClose();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [modalOpen, confetti]);

  const handleImageUpload = async (file, setUploadProgress) => {
    let imageFile = file;
  
    setCoverFileRequired(false);
    setCoverFile(imageFile);
  
    const simulateProgress = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) clearInterval(interval);
      }, 100);
    };
  
    simulateProgress();
    setCoverFileRequired(false);
  };

  const handleUpdate = (data) => {
    dispatch(showLoading());
    if (!labFees && !tuition && !onlineFees && !roomBoard && !mealPlan) {
      setFundsUseError(true);
    }
    if ((labFees || tuition || onlineFees || roomBoard || mealPlan) && coverFile) {
      setCoverFileRequired(false)
      // create object
      const selectedValues = {
        labFees,
        tuition,
        roomBoard,
        mealPlan,
        onlineFees
      };

      let funds_use = '';
      const propertyValues = Object.values(selectedValues);
      propertyValues.forEach(element => {
        if (element)
          funds_use += element + ',';
      });

      // remove the last comma
      funds_use = funds_use.replace(/,(\s+)?$/, '');
      const payload = new FormData();
      if (data.description) {
        payload.append('file', coverFile);
        payload.append('funds_use', funds_use);
        payload.append('goal', data.goal);
        payload.append('description', data.description);
      }
      else {
        payload.append('file', coverFile);
        payload.append('funds_use', funds_use);
        payload.append('goal', data.goal);
        payload.append('description', "Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.");
      }

      setLoading(true);
      props.createCampaign(payload, auth.user._id);
      setTimeout(() => {
        setLoading(false)
        navigate('/dashboard/home')
      }, 10000)
    };
    if (!coverFile) setCoverFileRequired(true);
  }

  return (
    <Page>
      {confetti && <Confetti width={width} height={height}/>}
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="welcome-dialog-title"
        aria-describedby="welcome-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(2px)', // Optional blur effect
          }
        }}
        PaperComponent={StyledPaper}
      >
        <DialogTitle id="welcome-dialog-title">{"Welcome to GapFundr!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="welcome-dialog-description">
            {dialogContent()}
            {/* Here is where the funding begins! Take a moment and personalize your page. Remember, the more effort you put into personalizing your page, the more funds you will receive! Create your Campaign to get started! */}
          </DialogContentText>
          {/* <Button onClick={handleClose} color="primary">
            Start Creating
          </Button> */}
          <Button onClick={handleNext} color="primary" style={{
            marginTop: '10px',
            padding: '5px 10px',
            fontSize: '16px',
            fontWeight: 'bold',
            border: '1px solid',
            borderColor: '#007BFF' // Example blue border color
          }}>
            {currentStep < 4 ? 'Next' : "Let's get started"}
          </Button>
        </DialogContent>
      </Dialog>
      <ContentView className="invite"
        width='1400px'
        alignitems
        justifycontent='center' >

        {/* Form area */ }
        <Form onSubmit={ handleSubmit(handleUpdate) }>
          <VStack >
            <HStack alignitems>
              <Font> Create Your Campaign</Font>
            </HStack>

            <VStack>
              {/* Select More */ }
              <HStack wrap='wrap'>
                <VStack>
                  <NumberField
                    placeholder='00'
                    left='$'
                    right='USD'
                    label="What is your compaign goal? (Amount to raise)"
                    name="goal"
                    type="text"
                    maxLength='10'
                    inputMode="numeric"
                    pattern="\d*"
                    register={ register }
                    errors={ errors.goal } />

                  <Font size='18px'>
                    How do you plan to use the funds?
                  </Font>

                  <HStack wrapt='wrap'>
                    <VStack>
                      <Button
                        type="button"
                        className='__btn__outlined__'
                        onClick={ () => {
                          setLabFees(labFees !== 'Lab Fees' ? 'Lab Fees' : '');
                          setFundsUse(false);
                        } }
                      >
                        <p className='font'>Lab Fees</p> { ' ' }    { labFees && <CheckIcon /> }
                      </Button>
                    </VStack>

                    <VStack>
                      <Button
                        type="button"
                        className='__btn__outlined__'
                        onClick={ () => {
                          setRoomBoard(roomBoard !== 'On Campus Housing' ? 'On Campus Housing' : '');
                          setFundsUse(false);
                        } }>
                        <p className='font'>On Campus Housing</p> { ' ' } { roomBoard && <CheckIcon /> }
                      </Button>
                    </VStack>
                  </HStack>

                  <HStack wrapt='wrap'>
                    <VStack>
                      <Button
                        type="button"
                        style={ {
                          background: 'transparent',
                          color: '#000',
                        } }
                        className='__btn__outlined__'
                        onClick={ () => {
                          setMealPlan(mealPlan !== 'Meal Plan' ? 'Meal Plan' : '');
                          setFundsUse(false);
                        } }>
                        <p className='font'>Meal Plan</p> { ' ' }  { mealPlan && <CheckIcon /> }
                      </Button>
                    </VStack>

                    <VStack>
                      <Button
                        type="button"
                        style={ {
                          fontSize: "10px",
                          color: '#1877F2',
                          border: '2px solid #1877F2'
                        } }
                        className='__btn__outlined__'
                        onClick={ () => {
                          setOnlineFees(onlineFees !== 'Online Fees' ? 'Online Fees' : '');
                          setFundsUse(false);
                        } }>
                        <p className='font'>Online Fees</p> { ' ' }  { onlineFees && <CheckIcon /> }
                      </Button>
                    </VStack>

                    <VStack>
                      <Button
                        type="button"
                        style={ {
                          background: 'transparent',
                          color: '#000',
                          border: '2px solid #1877F2'
                        } }
                        className='__btn__outlined__'
                        onClick={ () => {
                          setTuition(tuition !== 'Tuition' ? 'Tuition' : '');
                          setFundsUse(false);
                        } }
                      >
                        <p className='font'>Tuition</p> { ' ' }    { tuition && <CheckIcon /> }
                      </Button>
                    </VStack>
                  </HStack>
                  {fundsUseError && <Font color="red" size = '14px'>Please select at least one option for fund usage.</Font>}

                  <div className="__column__">
                    { fundsUse &&
                      <Font
                        justifycontent="center"
                        size="16px"
                        color="red"
                        weight={ 500 }>
                        Please select how you would like to use you future funds
                      </Font>
                    }
                  </div>
                </VStack>
              </HStack>
            </VStack>
          </VStack>

          {/* Form area */ }

          <FormTextarea
            label={ 'What is your story? (Optional Field)' }
            style={ { height: '10em' } }
            type={ 'text' }
            name={ 'description' }
            placeholder={ 'Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.' }
            register={ register }
            errors={ errors.description }
          />


          {/* Cover display and upload */ }
          <Container
            radius='10px'
            alignitems
            display='flex'
            gap='10px'
            width='100%'
            justifycontent='center'>
            {/* Upload Image */ }
            <Font size='18px'>
              Campaign Picture
            </Font>
            <FileUploader handleFile={ (file, setUploadProgress) => handleImageUpload(file, setUploadProgress) } />
            {
              coverFileRequired && <Font
                justifycontent="center"
                size="14px"
                color="red"
                weight={ 500 }>
                Please upload cover image
              </Font>
            }

          </Container>
          <Spacer size={ Size.s10 } />

          <HStack alignitems='center' justifycontent='center'>
            <Button type={ "submit" } className='__button__'>
              {
                loading ? <HStack alignitems='center' justifycontent='center'>
                  <Font color='#FFFFFF' size='14px' weight='300'>Creating Campaign</Font><CircularProgress sx={ { height: '10px', color: 'inherit' } } />
                </HStack> : 'Create Campaign'
              }
            </Button>
          </HStack>
          <Spacer size={ Size.s10 } />
        </Form>
      </ContentView>
    </Page>
  );
};

CreateCampaign.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  createCampaign: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  ...state.profile,
  ...state.error,
});

export default connect(mapStateToProps, { createCampaign })(CreateCampaign);
