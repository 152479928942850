import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Font, HStack, Layout, Spacer, VStack } from "../../Component/Components";
import { ContainerCard } from "../../Component/ContainerCard";
import { ErrorComponent } from "../../Component/ErrorComponent";
import { createPaymentIntent } from '../../State/actions/profile/index';
import LockIcon from '@mui/icons-material/Lock';

const CARD_ELEMENT_OPTIONS = {
  style: {
    // borderColor: "#32325d",
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "auto",
      size: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      borderColor: "#32325d",
      marginBottom: "100px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = forwardRef((props, ref) => {
  const [ error, setError ] = useState('')
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  let toastNotification;
  let carderror;

  useImperativeHandle(ref, () => ({
    async handleSubmit(paymentData) {

      if (!stripe || !elements) {
        return;
      }

      // let modifiedTotal = ((parseInt(paymentData.amount) * 0.1195) + parseInt(paymentData.amount)).toFixed(2);
      const data = {
        amount: paymentData.amount,
        name: paymentData.name
      };

      const secretKey = await createPaymentIntent(data);

      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card", card: cardElement,
      });

      if (error) {
        setError(error.message)
        props.onPaymentDone(false)
      } else {

        const confirmCardPayment = await stripe.confirmCardPayment(
          secretKey.client_secret, {
          payment_method: paymentMethod.id
        });

        if (confirmCardPayment.error) {
          setError('Your card has been declined, please check your card or use a different card');
          props.onPaymentDone()

        } else if (confirmCardPayment.paymentIntent.status === "succeeded") {

          props.onPaymentDone()
          setTimeout(() => {
            // Send data to the backend to update running total
            props.contribution();

            navigate('/successfull-payment', { state: true })

            setError('')

          }, 3000);

        } else {
        }
      }
    }
  }));

  return (
    <VStack>
      { toastNotification }

      { carderror }

      {/* <Spacer /> */ }
      <Font size='16px'>Enter your credit information
      </Font>

      <Spacer size='-15px' />

      <ContainerCard>
        <Layout>
          <CardElement style={ { padding: "20px" } }
            options={ CARD_ELEMENT_OPTIONS }
            className="input-box"
          />
        </Layout>
      </ContainerCard>

      <HStack alignitems='center' wrap='wrap'>
        <LockIcon color="#444444" />
        <Font size='16px'>
          Guaranteed secure checkout
        </Font>

        <Container backgroundcolor='#444444' radius='4px'>
          <Font size='16px' color='white'>
            Powered by
          </Font> { " " }
          <Font size='20px' weight='500' color='white'>
            Stripe
          </Font>
        </Container>

      </HStack>

      {
        error ? <ErrorComponent error={ error } /> : null
      }

      {/* Show success message after a campaign has been made */ }

    </VStack >
  );
});

export default CheckoutForm;
