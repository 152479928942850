import PropTypes from 'prop-types';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Font, IconImage, ProfileImage, Spacer, VStack, Container } from '../../../Component/Components';
import { getProfile, updateProfile, uploadProfilePhoto, removeProfilePhoto } from '../../../State/actions/profile';
import userPlaceholder from '../../../Assets/user_placeholder.svg';
import ProfileSettingDisplay from '../ProfileSettingDisplay';
import PrivateInfoUpdate from './PrivateInfoUpdate';
import ProfileUpdate from './ProfileUpdate';
import removeCircle from '../../../Assets/remove-circle.svg';
import uploadIcon from '../../../Assets/upload.svg';
import Page from '../../Page';
import './style.css';


function ProfileSetting(props) {
  const { reset } = useForm();
  const [ showEdit, setShowEdit ] = useState(false);
  const [ user, setUser ] = useState(null);
  const {id} = useParams();
  const navigate = useNavigate();

  const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  

  useEffect(() => {
    // getUserToEdit(id);
    props.getProfile(id, navigate);
    setUser(props.profile);
    return () => { };
  }, [user]);

  const [ selectedImage, setSelectedImage ] = useState();
  const imageUploader = useRef(null);
  
  const handleImageUpload = (e) => {
    const [ file ] = e.target.files;
    if (file) {
      setSelectedImage(e.target.files[ 0 ]);
      const payload = new FormData();
      payload.append("file", e.target.files[ 0 ]);
      props.uploadProfilePhoto({ payload: payload });
    }
  };

  
  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    // setImagePreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [ selectedImage ]);

  const deleteFile = (file_url) => {
    props.removeProfilePhoto({ query: 'profile', fileUrl: file_url });
  };
  return (
    <Page>
      <Fragment>
        <Spacer size='80px' />
        <Font>Settings</Font>

        <Spacer />

        <Container className='hide__profile__image'
          alignitems>
          <VStack alignitems='center'>
            {
              user?.profile_photo_url ?
                <ProfileImage
                  src={ user?.profile_photo_url }
                  width={ '80px' }
                  height={ '80px' } /> :
                <ProfileImage
                  src={ userPlaceholder }
                  width={ '80px' }
                  height={ '80px' } />
            }
            <Container
              alignitems
              justifycontent='center'
              style={ { marginTop: '-40px' } }>
              <input
                type="file"
                accept="image/*"
                onChange={ handleImageUpload }
                ref={ imageUploader }
                style={ {
                  display: "none",
                } } />

              {
                !user?.profile_photo_url ?
                  <IconImage
                    src={ uploadIcon }
                    width={ '30px' }
                    height={ '30px' }
                    onClick={ () => imageUploader.current.click() } /> :
                  <IconImage
                    src={ removeCircle }
                    width={ '30px' }
                    height={ '30px' }
                    onClick={ () => deleteFile(user.profile_photo_url) } />
              }
            </Container>
          </VStack>
          <Spacer size='20px' />

        </Container>
        {
          user && <>
            { !showEdit && <ProfileSettingDisplay
              { ...user }
              setShowEdit={ () => setShowEdit(!showEdit) }
              showEdit={ showEdit } /> }
            {
              showEdit &&
              <Fragment>
                <ProfileUpdate { ...user } setShowEdit={ (state) => setShowEdit(!state) } />
                <Spacer size={ '40px' } />
                <PrivateInfoUpdate { ...user } setShowEdit={ (state) => setShowEdit(!state) } />
              </Fragment>
            }
          </>
        }
        {
          !user &&
          <div className="__column__">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        }
        <Spacer size={ '50px' } />
      </Fragment >
    </Page>
  );
};

ProfileSetting.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  uploadProfilePhoto: PropTypes.func.isRequired,
  removeProfilePhoto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  profile: state.profile.profile
});

export default connect(mapStateToProps, { getProfile, updateProfile, uploadProfilePhoto, removeProfilePhoto })(ProfileSetting);
