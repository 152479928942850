import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container, Font, Form, HStack, Layout, Line, Spacer, VStack
} from '../../../Component/Components';
import { FormField, SelectOption } from '../../../Component/InputFields';
import { educationTrack } from '../../../Constants/educationTrack';
import { institutions } from '../../../Constants/institutions';
import { updateInstitution } from '../../../State/actions/profile';
import { showLoading } from '../../../State/setPayload';
import { formMonthDate, isEmpty, signupThreeValidation } from '../../../Util/validation';
import Page from '../../Page';
function InstitutionUpdate(props) {
  const [ user, setUser ] = useState();
  const dispatch = useDispatch();
  const [ mmYYYY, setMMYYYY ] = useState("");
  const navigate = useNavigate();

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(signupThreeValidation)
  });

  const handleFormattedDate = (e) => {
    const formattedDate = formMonthDate(e.target.value)
    setMMYYYY(formattedDate)
  }

  useEffect(() => {
    if (props.profile?.institution) {
      setTimeout(() => {
        let data = {
          degree: !isEmpty(props.profile?.institution.degree) ? props.profile?.institution.degree : '',
          city: !isEmpty(props.profile?.institution.city) ? props.profile?.institution.city : '',
          state: !isEmpty(props.profile?.institution?.state) ? props.profile?.institution.state : '',
          education_program_track: !isEmpty(props.profile?.institution.education_program_track) ? props.profile?.institution.education_program_track : '',
          expected_graduation: !isEmpty(props.profile?.institution.expected_graduation) ? props.profile?.institution.expected_graduation : '',
          prospective_institution: !isEmpty(props.profile?.institution.prospective_institution) ? props.profile?.institution.prospective_institution : '',
          student_institution_id: !isEmpty(props.profile?.institution.student_institution_id) ? props.profile?.institution.student_institution_id : '',
        };
        setUser(data);
      }, 500);
    }
  }, [ props ]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [ reset, user ]);

  const handleAccountUpate = (data) => {
    const payload = {
      degree: data.degree,
      city: data.city,
      state: data.state,
      education_program_track: data.education_program_track,
      expected_graduation: data.expected_graduation,
      prospective_institution: data.prospective_institution,
      student_institution_id: data.student_institution_id,
    };

    dispatch(showLoading());

    setTimeout(function () {
      props.updateInstitution(props.profile._id, payload)
      navigate(-1)
    }, 3000)
  }

  let spinner;

  if (props.loading) {
    spinner = <CircularProgress sx={ { color: 'inherit' } } />
  }

  return (
    <Page>
      <Fragment>
        <Spacer size='80px' />

        <Container
          className='fade-in-content'
          style={ {
            border: '1px solid #CCDBF0',
            boxSizing: 'border-box',
            background: '#FFFFFF'
          } }
          padding={ '0px' }
          width={ '100%' }
          radius={ '10px' }>
          <Layout size="20px">

            <HStack
              style={ {
                padding: '10px'
              } }
              justifycontent='space-between'>
              <Font
                size='18px'
                weight='500'>Institution Settings</Font>
            </HStack>
          </Layout>
          <Line />

          <Layout size="20px">
            <VStack>
              <Form onSubmit={ handleSubmit(handleAccountUpate) }>
                <HStack wrap='wrap'>
                  <SelectOption
                    label='Prospective Institution or College'
                    name='prospective_institution'
                    type='text'
                    register={ register }
                    errors={ errors.prospective_institution }>
                    { institutions.map((college, index) => (
                      <option key={ index } value={ college }>{ college }</option>
                    )) }
                  </SelectOption>

                  <SelectOption
                    label='Degree (Declared/Of Interest)'
                    name='degree'
                    type='text'
                    register={ register }
                    errors={ errors.degree }>
                    <option value=''>Select</option>
                    <option value='Bachelors'>Bachelors</option>
                    <option value='Associates/ Technical Certificates'>Associates/ Technical Certificates</option>
                    <option value='Masters'>Masters</option>
                    <option value='Doctorate'>Doctorate</option>
                    <option value='Prefer not to say'>Not Decided</option>
                  </SelectOption>
                </HStack>

                <HStack wrap='wrap'>
                  <FormField
                    label='City'
                    name='city'
                    type='text'
                    placeholder='ex. Seattle'
                    register={ register }
                    errors={ errors.city }
                  />
                  <FormField
                    label='Student ID (ex: A12345678 or HS'
                    name='student_institution_id'
                    type='text'
                    maxLength='9'
                    placeholder='ex:P000513614 or HS'
                    register={ register }
                    errors={ errors.student_institution_id }
                  />
                </HStack>

                <HStack wrap='wrap'>
                  <SelectOption
                    style={ { display: 'none !important' } }
                    label='State'
                    name='state'
                    type='text'
                    register={ register }
                    errors={ errors.state }>
                    <option value='Washington'>Washington</option>
                  </SelectOption>

                  <FormField
                    label='Expected Graduation'
                    name='expected_graduation'
                    type='text'
                    placeholder='mm/yyyy'
                    value={ mmYYYY }
                    onChange={ (e) => handleFormattedDate(e) }
                    register={ register }
                    errors={ errors.expected_graduation }
                  />
                </HStack>

                <HStack wrap='wrap'>
                  <SelectOption
                    label='Educational Track/Enter N/A'
                    name='education_program_track'
                    type='text'
                    register={ register }
                    errors={ errors.education_program_track }>
                    { educationTrack.map((et) => (
                      <option value={ et } key={ et }>{ et }</option>
                    )) }
                  </SelectOption>
                </HStack>

                <HStack alignitems='center' justifycontent='center'>
                  <Button className='__btn__outlined__' type='button' onClick={ () => navigate(-1) }>
                    Cancel
                  </Button>
                  <Button className='__btn__' type='submit'>
                    { spinner ? spinner : 'Save' }
                  </Button>
                </HStack>
              </Form>
            </VStack>
          </Layout>
        </Container>
        <Spacer size={ '70px' } />

      </Fragment >
    </Page>

  );
}

InstitutionUpdate.propTypes = {
  updateInstitution: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth.user,
  profile: state.profile.profile,
  loading: state.loading.loading,
});
export default connect(mapStateToProps, { updateInstitution })(InstitutionUpdate);