import { Box, Container, LinearProgress, Link, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Font, HStack, Layout, Spacer, VStack } from "../../Component/Components";
import sideImage from '../../Assets/side-image-login.png';
import './style.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

function Success(props) {
  // Modal Button 
  const [ open, setOpen ] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      handleOpen(state)
    } else {
      navigate('/')
    }
  }, [])


  return (
    <HStack className='success__pattern' width={ '100%' }>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ style }>
          <HStack alignitems='center' justifycontent='center'>
            <Typography sx={ { alignSelf: 'center' } }
              id="modal-modal-title" variant="h4" component="h2">
              Congratulation!
            </Typography>
          </HStack>
          <Typography sx={ { alignSelf: 'center', mt: 2 } }
            id="modal-modal-description">
            Your GapFundr account has been created. Please <Link href="/login">sign in</Link> to create your profile.
          </Typography>

        </Box>
      </Modal>
      <VStack alginitems='center' justifycontent='center' padding={ '10px' }>
        <Font color='black' align='center' size='22px'>
          Welcome to GapFunder! Please click here to <Link href="/login">sign in</Link>
        </Font>
      </VStack>
    </HStack>
  )
}

export default Success;