import axios from 'axios';
import { BASE_URL } from '../../Api';
import { configOption } from '../../../Util/requireAuth';
import {
  isEmpty
} from '../../../Util/validation';
import { ActionType } from '../../actionTypes';

import {
  clearCurrentProfile,
  hideLoading,
  setAllUsers,
  setCurrentUser,
  setProfile,
  setProfiles,
  showLoading
} from '../../setPayload';
import {
  setError
} from '../../setPayload';

export const getUser = (id, navigate) => (dispatch) => {
  dispatch(showLoading());
  axios.get(BASE_URL.concat(`/user/${id}`)).then(res => {
    dispatch(hideLoading());
    if (!isEmpty(res.data.error)) {
      dispatch(setError(res.data));
    } else {
      const user = res.data;
      dispatch(setProfile(user));
      dispatch(setCurrentUser(user));
      dispatch(setError({}));
    }
  }).catch(err => {
    dispatch(hideLoading());
    if (!isEmpty(err)) {
      if (err.response.data.statusCode === 401) {
        clearCurrentProfile();
        return navigate('/login');
      }
      dispatch(setError(err.response.data));
    }
  });
};

export const getUserList = ({ page , pagesize }) => (dispatch) => {
  axios.get(BASE_URL.concat(`/user/${page}/${pagesize}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_CURRENT_USERS,
            payload: res.data
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(setError(err.response.data));
    });
};

export const getAllUsersCounter = (navigate) => (dispatch) => {
  dispatch(showLoading());
  axios.get(BASE_URL.concat(`/users/All`)).then(res => {
    dispatch(hideLoading());
    if (!isEmpty(res.data.error)) {
      dispatch(setError(res.data));
    } else {
      const users = res.data;
      dispatch(setAllUsers(users));
      dispatch(setError({}));
    }
  }).catch(err => {
    dispatch(hideLoading());
    if (!isEmpty(err)) {
      if (err.response.data.statusCode === 401) {
        clearCurrentProfile();
        return navigate('/login');
      }
      dispatch(setError(err.response.data));
    }
  });
};

export const getProfile = (id) => (dispatch) => {
  dispatch(showLoading());
  axios.get(BASE_URL.concat(`/user/${id}`)).then(res => {
    dispatch(hideLoading());
    if (!isEmpty(res.data.error)) {
      dispatch(setError(res.data));
    } else {
      const user = res.data;
      // dispatch(setCurrentUser(user));
      dispatch(setProfile(user));
      dispatch(setError({}));
    }
  }).catch(err => {
    dispatch(hideLoading());
    if (!isEmpty(err)) {
      if (err.response.data.statusCode === 401) {
        clearCurrentProfile();
        window.location.href = '/login';
      }
      dispatch(setError(err.response.data));
    }
  });
};

export const getProfiles = (id, navigate) => (dispatch) => {
  axios.get(BASE_URL.concat(`/users`), configOption()).then(res => {
    dispatch(hideLoading());
    if (!isEmpty(res.data.error)) {
      dispatch(setError(res.data));
    } else {
      const users = res.data;
      dispatch(setProfiles(users));
      dispatch(setError({}));
    }
  }).catch(err => {
    dispatch(hideLoading());
    if (!isEmpty(err)) {
      if (err.response.data.statusCode === 401) {
        clearCurrentProfile();
        return navigate('/login');
      }
      dispatch(setError(err.response.data));
    }
  });
};

export const updateInstitution = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/user/institution/${id}`), payload, configOption())
    .then(res => {
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(hideLoading())
          const user = res.data;
          dispatch(setProfile(user));
          dispatch(setError({}));
          dispatch(hideLoading());
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

export const updateProfile = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/user/${id}`), payload, configOption())
    .then(res => {
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          dispatch(setProfile(user));
          dispatch(setCurrentUser(user));
          dispatch(setError({}));
          dispatch(hideLoading());
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

export const updateUserProfile = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/user/${id}`), payload, configOption())
    .then(res => {
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          dispatch(setProfile(user));
          // dispatch(setCurrentUser(user));
          dispatch(setError({}));
          dispatch(hideLoading());
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          // clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};


export const deactivateCampaignAccount = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/deactivate-campaign/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        // double check this part of the solution. 
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          dispatch(setProfile(user));
          // dispatch(setCurrentUser(user));
          dispatch(setError({}));
          dispatch(hideLoading());
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      // double check this part of the solution. 
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};


export const uploadProfilePhoto = ({ payload }) => (dispatch) => {
  axios.put(BASE_URL.concat(`/user-media`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          setProfile(user);
          dispatch(setProfile(user));
          dispatch(setCurrentUser(user));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

export const removeProfilePhoto = ({ id }) => (dispatch) => {
  axios.delete(BASE_URL.concat(`/user/media/${id}?`))
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          setProfile(user);
          dispatch(setProfile(user));
          dispatch(setCurrentUser(user));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

export const updateSocialMedia = (payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/user-social`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          const user = res.data;
          dispatch(setProfile(user));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (!isEmpty(err)) {
        if (err.response.data.status === 401) {
          window.location.href = '/login';
          clearCurrentProfile();
        }
        dispatch(setError(err.response.data));
      }
    });
};

export function createPaymentIntent(payload) {
  return new Promise((resolve, reject) => {
    axios.post(BASE_URL.concat(`/payment-intent`), payload, configOption())
      .then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
  });
}