import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { Provider } from "react-redux";
import { getUser } from './State/actions/profile';
import { clearCurrentProfile } from './State/setPayload';
import store from './State/store';
import { getLocalItem } from './Util/localStorage';
import setAuthToken from "./Util/setAuth";
import ViewRoutes from "./viewRoutes";

function App(props) {

	useEffect(() => {

		const token = getLocalItem({ key: 'gapFundrJwtToken' });
		if (token) {
			checkCurrentUser(token);
		}
	}, []);

	const checkCurrentUser = (token) => {
		setAuthToken(token);
		const decoded = jwt_decode(token);
		// On application load, set the user data for global access
		store.dispatch(getUser(decoded.user._id))

		const currentTime = Date.now() / 1000;
		if (!(currentTime < decoded.exp)) {
			// Log user out and dispatch t
			store.dispatch(clearCurrentProfile());
			window.location.href = "/";
		}
	};

	return (
		<Provider store={ store }>
			<ViewRoutes />
		</Provider>
	);
}

export default App;


