// Check if the code is code is running locally. 
// Then assign the right value.
// Add URL to .env file. 
// check it the .env is not empty that export local URL.

let url;

if (process.env.REACT_APP_DEV === 'production') {
  url = process.env.REACT_APP_GAPFUNDR_API;
} else {
  url = process.env.REACT_APP_GAPFUNDR_LOCAL;
}
export const BASE_URL = url.concat('/v1');
export async function isAvailable() {

  const timeout = new Promise((_, reject) => setTimeout(reject, 5000, 'Request timed out'));
  const request = fetch(url);
  try {

    await Promise.race([ timeout, request ]);
    return true;
  } catch (__2) {
    return false;
  }
}
