
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import profileImage from '../../Assets/user_placeholder.svg';
import { Container, Font, HStack, Layout, ProfileImage, VStack } from '../../Component/Components';
import { ContainerCard } from '../../Component/ContainerCard';

function DonationItem(props) {
  const navigate = useNavigate();

  return (
    <Layout>
      <VStack gap='0'>
        <Font size={ '16px' } weight={ 500 } >
          { props?.donor_name ? props.donor_name : "Anonymous" } { " " } sent you ${ props?.amount }
        </Font>
        <Font size='14px' weight='300'>{ moment(props?.posted_at).format('MMMM Do YYYY, h:mm:ss a') }</Font>
      </VStack>
    </Layout>
  );
}
export default DonationItem;