import { ActionType } from '../actionTypes/index';

const initialState = {
  notification: []
};

function notificationReducer(state = initialState,action) {
  switch(action.type) {
    case ActionType.CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case ActionType.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
}

export default notificationReducer;
