import axios from 'axios';
import { ActionType } from '../../State/actionTypes';
import { BASE_URL } from '../../State/Api';
import { clearCurrentProfile, hideLoading, setCampaignProfile, setCampaignProfiles, setSearchResultCnt, setError, setProfile, showLoading } from '../../State/setPayload';
import { handleError } from '../../Util/handleError';
import { configOption } from '../../Util/requireAuth';
import { isEmpty } from '../../Util/validation';

/**
 * Create Campaign
 * Creates a new profile. 
 * @param {*} payload 
 * @param {*} id 
 * @param {*} navigate 
 * @returns   
 */
export const createCampaign = (payload, id) => (dispatch) => {
  axios.post(BASE_URL.concat(`/campaign/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      dispatch(setCampaignProfile(res.data));
      dispatch(setProfile(res.data?.owner));

    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
        window.location.href = '/login';
      }
      dispatch(setError(err.response.data));
    });
};

/**
 * Update Campaign
 * Update a single campaign
 * @param {*} id 
 * @param {*} payload 
 * @param {*} navigate 
 * @returns 
 */
export const updateCampaign = (id, payload, navigate) => (dispatch) => {
  axios.put(BASE_URL.concat(`/campaign/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          getCampaignProfile(id);
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
};

export const updateComment = (id, payload) => (dispatch) => {
  axios.put(BASE_URL.concat(`/campaign/comments/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
};
/**
 * Remove Campaign Cover Image 
 * @param {*} id 
 * @returns 
 */
export const removeCampaignCover = (id) => (dispatch) => {
  axios.delete(BASE_URL.concat(`/campaign/media/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_CAMPAIGN,
            payload: res.data
          });
          dispatch(setError({}));
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get CampaignProfile
 * Get a profile with its respective campaign
 * @returns 
 */
export const getCampaignProfile = (id) => (dispatch) => {
  axios.get(BASE_URL.concat(`/student/profile/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setCampaignProfile(res.data));
        }
      }
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get CampaignProfiles
 *  Get profiles with their respective campaigns 
 * @returns 
 */
export const getCampaignProfiles = (search, page = 1, limit = 0) => (dispatch) => {
  axios.get(BASE_URL.concat(`/users?search=${search}&page=${page}&limit=${limit}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      dispatch(setCampaignProfiles(res.data.userCampaigns));
      dispatch(setSearchResultCnt(res.data.totalCount));
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get all campaigns from Database
 *   
 * @returns 
 */
export const getAllCampaigns = (id) => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/getAll`), configOption())
    .then(res => {
      dispatch(hideLoading());
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get Campaign
 *  Gets a single campaign
 * @param {*} id 
 * @returns 
 */
export const getCampaign = (id) => (dispatch) => {
  dispatch(showLoading());
  axios.get(BASE_URL.concat(`/campaign/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_CAMPAIGN,
            payload: (res.data)
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get contributions that belong to a user campaign
 * Gets contributions for a unique student
 * @param {*}  
 * @returns 
 */
export const getContributions = ({ id }) => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_DONATIONS,
            payload: res.data.contributions
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * SendContribution 
 * Sends/makes contributions/contribution to a student
 * @param {*}  
 * @returns 
 */
export const sendContribution = (id, payload) => (dispatch) => {
  axios.post(BASE_URL.concat(`/campaign/fund/${id}`), payload)
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          getCampaignProfile(id);
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
}

/**
 * Request funds 
 * Request funds that have been contributed to the campaign
 * @param {*}  
 * @returns 
 */
export const requestFunds = (id, payload) => (dispatch) => {
  axios.post(BASE_URL.concat(`/request-funds/${id}`), payload)
    .then(res => {
      dispatch(hideLoading());
      handleError(dispatch, {});

      dispatch({
        type: ActionType.REQUEST_FUNDS,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response.status === 401) {
        dispatch(hideLoading());
        dispatch(clearCurrentProfile());
      }
      handleError(dispatch, err);
    });
}