import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import {
  Container, Font, Form, HStack, Layout, Line, Spacer, VStack
} from '../../../Component/Components';
import { FormField, SelectOption } from '../../../Component/InputFields';
import { updateUserProfile } from '../../../State/actions/profile';
import { showLoading } from '../../../State/setPayload';
import { formatPhoneNumber, isEmpty, validateUpdateUserProfile } from '../../../Util/validation';

function ProfileUpdate(props) {
  const [ user, setUser ] = useState();
  const dispatch = useDispatch();
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ emailNotificationEnable, setEmailNotificationEnable ] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(validateUpdateUserProfile)
  });

  useEffect(() => {
    if (props.profile) {
      setTimeout(() => {
        let data = {
          first_name: !isEmpty(props.profile?.first_name) ? props.profile?.first_name : '',
          last_name: !isEmpty(props.profile?.last_name) ? props.profile?.last_name : '',
          middle_name: !isEmpty(props.profile?.middle_name) ? props.profile?.middle_name : '',
          ethnicity: !isEmpty(props.profile?.ethnicity) ? props.profile?.ethnicity : '',
          dream_job: !isEmpty(props.profile?.dream_job) ? props.profile?.dream_job : '',
          hobbies: !isEmpty(props.profile?.hobbies) ? props.profile?.hobbies : '',
          gender: !isEmpty(props.profile?.gender) ? props.profile?.gender : '',
          phone_number: !isEmpty(props.profile?.phone_number) ? props.profile?.phone_number : '',
          email: !isEmpty(props.profile?.email) ? props.profile?.email : '',
          dob: !isEmpty(props.profile?.dob) ? props.profile?.dob : '',
          about: !isEmpty(props.profile?.about) ? props.profile?.about : '',
          city: !isEmpty(props.profile?.address?.city) ? props.profile?.address?.city : '',
          state: !isEmpty(props.profile?.address?.state) ? props.profile?.address?.state : '',
          street: !isEmpty(props.profile?.address?.street) ? props.profile?.address?.street : '',
          zip_code: !isEmpty(props.profile?.address?.zip_code) ? props.profile?.address?.zip_code : '',
          role: !isEmpty(props.profile?.role) ? props.profile?.role : '',
        };
        setEmailNotificationEnable(props.profile.email_notification ? props.profile.email_notification : false);
        setUser(data);

      }, 500);
    }
  }, [ props.profile ]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [ reset, user ]);

  const handleAccountUpate = (data) => {
    const payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      dream_job: data.dream_job,
      hobbies: data.hobbies,
      gender: data.gender,
      email: data.email,
      state: data.state,
      street: data.street,
      city: data.city,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      email_notification: emailNotificationEnable,
      dob: data.dob,
      ethnicity: data.ethnicity,
      role: parseInt(data.role,10),
    };

    dispatch(showLoading());
    setTimeout(function () {
      props.setShowEdit(true);
      props.updateUserProfile(props.profile._id, payload)
    }, 3000)
  }

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhoneNumber(formattedPhoneNumber);
  };

  let spinner;

  if (props.loading) {
    spinner = <CircularProgress sx={ { color: 'inherit' } } />
  }

  return (
    <Fragment>
      <Container
        className='fade-in-content'
        style={ {
          border: '1px solid #CCDBF0',
          boxSizing: 'border-box',
          background: '#FFFFFF'
        } }
        padding={ '0px' }
        width={ '100%' }
        radius={ '10px' }>

        <HStack
          style={ {
            padding: '20px'
          } }
          justifycontent='space-between'>
          <Font
            size='18px'
            weight='500'>Profile Settings</Font>
        </HStack>
        <Line />

        <Layout size="20px">

          <VStack>
            <Form onSubmit={ handleSubmit(handleAccountUpate) }>
              <HStack wrap='wrap'>
                <FormField
                  placeholder={ "" }
                  register={ register }
                  errors={ errors.first_name }
                  label='First Name'
                  name='first_name'
                  type='text' />
                <FormField
                  placeholder={ "" }
                  register={ register }
                  errors={ errors.middle_name }
                  label='Middle Name'
                  name='middle_name'
                  type='text' />
              </HStack>

              <HStack wrap='wrap'>
                <FormField
                  label="Last Name"
                  name="last_name"
                  type="last_name"
                  register={ register }
                  errors={ errors.last_name } />

                <SelectOption
                  style={ { display: 'none !important' } }
                  label='Ethnicity'
                  name="ethnicity"
                  type="text"
                  register={ register }
                  errors={ errors.ethnicity }
                >
                  <option value="">Select</option>
                  <option value="Asian">Asian</option>
                  <option value="Black, including African American">Black, including African American</option>
                  <option value="Hispanic, Latino/a, Latinx">Hispanic, Latino/a, Latinx</option>
                  <option value="Middle Eastern or North African">Middle Eastern or North African</option>
                  <option value="Native American or Alaska Native">Native American or Alaska Native</option>
                  <option value="Pacific Islander, including Native Hawaiian">
                    Pacific Islander, including Native Hawaiian</option>
                  <option value="White or Caucasian">White or Caucasian</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </SelectOption>
              </HStack>

              <HStack wrap='wrap'>
                <FormField
                  label="Date of Birth"
                  name="dob"
                  placeholder='01/01/2000'
                  maxLength='10'
                  type="text"
                  register={ register }
                  errors={ errors.dob } />

                <SelectOption
                  label='Gender'
                  name="gender"
                  type="text"
                  register={ register }
                  errors={ errors.gender }
                >
                  <option value="">Select</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Non-binary">Non-binary</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </SelectOption>
                <SelectOption
                  label='User Role'
                  name="role"
                  type="text"
                  register={ register }
                  errors={ errors.role }
                >
                  <option value="">Select</option>
                  <option value="1">Moderator</option>
                  <option value="2">User</option>
                </SelectOption>
              </HStack>

              <HStack wrap='wrap'>
                <FormField
                  label="Dream Job after college"
                  name="dream_job"
                  type="text"
                  placeholder='Please add dream job eg. Amazon, Nike etc '
                  register={ register }
                  errors={ errors.dream_job } />
                <FormField
                  label={ 'Hobbies' }
                  name={ 'hobbies' }
                  placeholder={ 'Enter hobbies. eg. Tennis, Soccer, etc' }
                  register={ register }
                  errors={ errors.hobbies }
                  type='text' />
              </HStack>

              <HStack wrap='wrap'>
                <FormField
                  disabled
                  register={ register }
                  errors={ errors.email }
                  label='Email'
                  name='email'
                  type='text' />

                <FormField
                  register={ register }
                  label='Phone Number'
                  name='phone_number'
                  placeholder='(800) 444-1212'
                  type='text'
                  value={ phoneNumber }
                  onChange={ (e) => handleInput(e) }
                  errors={ errors.phone_number }
                />
              </HStack>

              <VStack>
                <Font size='16px'>Address</Font>
                <HStack wrap='wrap'>
                  <FormField
                    label="Street"
                    name="street"
                    type="text"
                    placeholder='Eg. 122 12th ave #202'
                    register={ register }
                    errors={ errors.street } />
                  <FormField
                    label="City"
                    name="city"
                    type="text"
                    placeholder='Eg. Seattle'
                    register={ register }
                    errors={ errors.city } />
                </HStack>
                <HStack wrap='wrap'>
                  <SelectOption
                    style={ { display: 'none !important' } }
                    label='State'
                    name='state'
                    type='text'
                    register={ register }
                    errors={ errors.state }>
                    <option value='Washington'>Washington</option>
                  </SelectOption>
                  <FormField
                    label={ 'Zip Code' }
                    name={ 'zip_code' }
                    placeholder={ 'Eg 98100' }
                    register={ register }
                    errors={ errors.zip_code }
                    type='text' />
                </HStack>
              </VStack>

              <VStack>
                <Font size='20px' weight='400'>Notification</Font>
                <HStack>
                  <input
                    className='__checkbox__'
                    type="checkbox"
                    value={ true }
                    checked={ emailNotificationEnable }
                    onChange={ () => setEmailNotificationEnable(!emailNotificationEnable) } />
                  <Font size='20px' weight='400'>Email</Font>
                </HStack>
              </VStack>
              <HStack alignitems='center' justifycontent='center'>
                <Button className='__btn__outlined__' type='button' onClick={ () => props.setShowEdit(true) }>
                  Cancel
                </Button>
                <Button className='__btn__' type='submit'>
                  { spinner ? spinner : 'Save' }
                </Button>
              </HStack>
            </Form>
          </VStack>
        </Layout>
      </Container>
      <Spacer size={ '50px' } />
    </Fragment>

  );
}

ProfileUpdate.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth.user,
  profile: state.profile.profile,
  loading: state.loading.loading,
});
export default connect(mapStateToProps, { updateUserProfile })(ProfileUpdate);