import React from 'react';
import { VStack, Font, HStack } from '../Components';
import style from '../../StyleModule/style.module.css';
import { useField } from "formik";
import "../../StyleModule/main_style.css";

export const TextField = ({
  onChange,
  label,
  options,
  errors,
  type,
  width,
  ...rest }) => {
  return (
    <div className='__input__field__container__'>
      { label ? <label>{ label }</label> : null }
      <input
        className="__input__field__"
        type={ type }
        style={ { border: `${errors ? '2px solid red' : ''}` } }
        onChange={ (e) => onChange(e) }
        { ...rest } />
      { errors ? <Font size={ '16px' } color={ 'red' }>Field Required</Font> : null }
    </div>
  );
};

export const SelectOption = ({ label, name, children, errors, register, ...rest }) => {
  return (
    <div className='__input__field__container__'>
      { label ? <label>{ label }</label> : null }
      <select className='__input__field__'
        { ...register(name) }
        { ...rest }
        style={ { border: `${errors?.message ? '2px solid red' : ''}` } }>
        { children }
      </select>
    </div>
  );
};


export const FormField = ({ label, name, register, errors, ...rest }) => {
  return (
    <div className="__input__field__container__">
      { label ? <label>{ label }</label> : null }
      <input className="__input__field__"
        { ...register(name) }
        { ...rest }
        style={ { border: `${errors?.message ? '2px solid red' : ''}` } } />
      { errors && <Font size={ '16px' } color={ 'red' }>{ errors?.message }</Font> }

    </div>
  );
};


export const NumberField = ({ label, left, right, name, register, errors, ...rest }) => {
  return (
    <div className="__input__field__container__">
      { label ? <label>{ label }</label> : null }
      <HStack backgroundcolor='white' className="__input__field__" justifycontent='space-between' alignitems='center'
        style={ { border: `${errors?.message ? '2px solid red' : ''}`, textAlign: 'right' } }>
        <p>{ left }</p>
        <input { ...register(name) } { ...rest }
          style={ { textAlign: 'right', width: '100%', height: '100%', boxSizing: 'none', border: 'none' } } />
        { right }
      </HStack>
      { errors && <Font size={ '16px' } color={ 'red' }>{ errors?.message }</Font> }

    </div>
  );
};

export const SmallFormField = ({ label, name, register, errors, ...rest }) => {
  return (
    <div className="__input__field__container__">
      { label ? <label>{ label }</label> : null }
      <input className="__input__field__"
        { ...register(name) }
        { ...rest } />
      { errors && <Font size={ '16px' } color={ 'red' }>{ errors?.message }</Font> }
    </div>
  );
};

export const FormCheckBox = ({ label, name, register, errors, ...rest }) => {
  return (
    <div className='___row__'>
      { label ? <label>{ label }</label> : null }
      <input className="__input__field__"{ ...register(name) } { ...rest } />
      { errors && <Font size={ '12px' } color={ 'red' }>{ errors?.message }</Font> }

    </div>
  );
};

export const PhotoUpload = ({ label, name, register, errors, ...rest }) => {
  return (
    <div className="__input__field__container__">
      <input className="__input__file__"{ ...register(name) } { ...rest } />
    </div>
  );
};

export const FormTextArea = ({ label, name, register, errors, ...rest }) => {
  const mutableStyle = {
    display: 'flex',
    firstDirection: 'row'
  };
  return (
    <VStack>
      { label && <label>{ label }</label> }
      <textarea className={ style.inputTextarea }
        style={ mutableStyle } { ...register(name) } { ...rest } />
      { errors && <Font size={ '12px' } color={ 'red' }>{ errors?.message }</Font> }
    </VStack>
  );
};

export const FormTextarea = ({ label, name, register, errors, ...rest }) => {
  return (
    <div className="__input__field__container__">
      { label ? <label>{ label }</label> : null }
      <textarea className="__textarea__field__" { ...register(name) } { ...rest } />
      { errors && <Font size={ '12px' } color={ 'red' }>{ errors?.message }</Font> }
    </div>
  );
};


export const FormikInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [ field, meta ] = useField(props);

  return (
    <div className="__input__field__container__">
      <label htmlFor={ props.id || props.name }>{ label }</label>
      <input className="__input__field__" { ...field } { ...props } />
      { meta.touched && meta.error ? (
        <Font size={ '14px' } color={ 'red' }>{ meta.error }</Font>
      ) : null }
    </div>
  );
};

export const CheckBox = ({ label, ...props }) => {
  const [ field, meta ] = useField(props);
  return (
    <div>
      <input className="__radio__button__" { ...field } { ...props } />
      { meta.touched && meta.error ? (
        <Font size={ '14px' } color={ 'red' }>{ meta.error }</Font>
      ) : null }
    </div>
  );
};

export const FormikTextarea = ({ label, ...props }) => {
  const [ field, meta ] = useField(props);
  return (
    <div className="__input__field__container__">
      <label htmlFor={ props.id || props.name }>{ label }</label>
      <textarea className="__textarea__field__" { ...field } { ...props } />
      { meta.touched && meta.error ? (
        <Font size={ '14px' } color={ 'red' }>{ meta.error }</Font>
      ) : null }
    </div>
  );
};


export const TextArea = ({
  onChange,
  label,
  options,
  ...rest
}) => {
  const mutableStyle = {
    display: 'flex',
    firstDirection: 'row'
  };
  return (
    <VStack>
      { label && <label>{ label }</label> }
      <textarea className={ style.inputTextarea } style={ mutableStyle }
        onChange={ (e) => onChange(e.target.value) } { ...rest } />
    </VStack>
  );
};

export const SelectField = ({
  onChange,
  label,
  options,
  width,
  ...rest }) => {
  const mutableStyle = {
    width: width && width + '% !important',
  };

  return (
    <VStack>
      { label && <div>{ label }</div> }
      <select className={ style.select } style={ mutableStyle }
        onChange={ (e) => onChange(e.target.value) } { ...rest }>
        { options && options.map(option => (
          <option key={ option } value={ option }>
            { option }
          </option>
        )) }
      </select>
    </VStack>
  );
};

