import { combineReducers } from 'redux';
import auth from "./auth.reducer";
import error from "./error.reducer";
import profile from "./profile.reducer";
import loading from "./loading.reducer";
import campaign from "./campaign.reducer";
import notification from './notification.reducer';
import admin from "./admin.reducer"

export default combineReducers({
	auth,
	admin,
	error,
	profile,
	campaign,
	loading,
	notification,
});
