import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import {
  Container, Font, Form, HStack, Layout, Line, Spacer
} from '../../../Component/Components';
import { FormField } from '../../../Component/InputFields';
import { passwordReset } from '../../../State/actions/auth';
import { deactivateCampaignAccount } from '../../../State/actions/profile';

import { validatePrivateUserProfile } from '../../../Util/validation';

function PrivateInfoUpdate(props) {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validatePrivateUserProfile) });
  const { auth } = props;
  const {deactivateCampaignAccount} = props;
  const [ checkBox, setCheckBox ] = useState(false);

  useEffect(() => {
    if (props.profile) {
      setTimeout(() => {
        setCheckBox(props.profile?.deactivate_account ? props.profile?.deactivate_account : false);
      }, 500);
    }
  }, [ props ]);

  const handlePrivateUpdate = (data) => {
    const payload = {
      current_password: data.current_password,
      new_password: data.new_password,
      confirm_new_password: data.confirm_new_password,
    };
    props.passwordReset(auth._id, payload);
    props.setShowEdit(!props.showEdit);
  };

  const submitDeactivate = () => {
    props.deactivateCampaignAccount(auth._id, { deactivate_account: checkBox });
    props.setShowEdit(!props.showEdit);
  }

  return (
    <Fragment>
      <Container className='fade-in-content'
        style={ { border: '1px solid #CCDBF0', boxSizing: 'border-box', background: '#FFFFFF' } }
        padding={ '0px' }
        width={ '100%' }
        radius={ '10px' } >

        <Layout size="20px">
          <HStack justifycontent='space-between'>
            <Font size='18px' weight='500'>Change Password</Font>
          </HStack>
        </Layout>

        <Line />

        <Layout size="20px">

          <Form onSubmit={ handleSubmit(handlePrivateUpdate) }>
            <FormField
              register={ register }
              errors={ errors.current_password }
              label='Current Password'
              name='current_password'
              type='password' />
            <FormField
              register={ register }
              errors={ errors.new_password }
              label='New Password'
              name='new_password'
              type='password' />
            <FormField
              register={ register }
              errors={ errors.confirm_new_password }
              label='Confirm New Password'
              name='confirm_new_password'
              type='password' />
            <Spacer />
            <Layout>
              <HStack alignitems='center'>
                <Button className='__btn__outlined__' type='button' onClick={ () => props.setShowEdit(true) }>Cancel</Button>
                <Button className='__btn__' type='submit'>Save</Button>
              </HStack>
            </Layout>
          </Form>
        </Layout>
      </Container >
      <Spacer size='20px' />

      <Container className='fade-in-content'
        style={ { border: '1px solid #CCDBF0', boxSizing: 'border-box', background: '#FFFFFF' } }
        padding={ '0px' }
        width={ '100%' }
        radius={ '10px' } >
        <HStack style={ { padding: '20px' } } justifycontent='space-between'>
          <Font size='18px' weight='500'>Deactivate Campaign Account</Font>
        </HStack>

        <Line />

        <Layout>
          <HStack alignitems='center'>
            <input
              className='__checkbox__'
              type="checkbox"
              value={ true }
              checked={ checkBox }
              onChange={ () => setCheckBox(!checkBox) } />
            <Font size='20px' weight='400'>{ checkBox ? 'Checked' : 'Unchecked' }</Font>
          </HStack>
          <Spacer />
          <HStack alignitems='center' justifycontent='center'>
            <Button className='__btn__outlined__' type='button' onClick={ () => props.setShowEdit(true) }>Cancel</Button>
            <Button className='__btn__' type='button' onClick={ () => submitDeactivate() }>Save</Button>
          </HStack>
        </Layout>
        <Spacer />
      </Container >
    </Fragment >

  );
}
PrivateInfoUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
  passwordReset: PropTypes.func.isRequired,
  deactivateCampaignAccount: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth.user
});
export default connect(mapStateToProps, { passwordReset, deactivateCampaignAccount })(PrivateInfoUpdate);
