import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BottomImage from '../../Assets/BottomImage.png';
import HeroImage from '../../Assets/HeroImage.png';
import LogoWhite from '../../Assets/Logo_White.svg';
import MiddleImage from '../../Assets/MiddleImage.png';
import { Article, Font, HStack, Spacer, StackedButton } from '../../Component/Components';
import './style.css';
import MenuBar from "../Navbar/MenuBar";
function Copyright() {
  return (
    <Typography variant="body2"
      color='#C4C4C4'
      align="center">
      { 'Copyright © ' }  { new Date().getFullYear() }{ ' ' }
      <Link color="inherit" href="https://gapfundr.org">
        GapFundr
      </Link>{ '.' }{ ' ' }
      All Rights Reserved.
    </Typography>
  );
}

const cardItems = [
  {
    content: 'Create a GapFundr profile, it’s easy!',
    buttonContent: 'Create a Gapfundr Profile'
  },
  {
    content: 'Share your profile link to your supporters via social media, text or email and begin receiving contributions.',
    buttonContent: ''
  },
  {
    content: 'Pay for online fees, tuition, school housing,and other college expenses affiliated with your institution.',
    buttonContent: ''
  }
];

const classes = {
  padding: {
    paddingTop: 40
  }
}

function LandingPage(props) {
  const navigate = useNavigate();
  const [ authenticatedUser, setAuthenticatedUser ] = useState();
  
  const user = useSelector(state => state.auth);
  useEffect(() => {
    setAuthenticatedUser(user);
    return () => {
    };
  }, [ user ]);

  const checkUserAuthState = () => {
    if (!authenticatedUser?.isAuthenticated) {
      return navigate('/signup');
    } else if(authenticatedUser.user.role === 0){
      return navigate('/admin/home');
    } else {
      return navigate('/dashboard/home');
    }
  };

  return (
    <>
    <MenuBar { ...props } />
    <main className='__space__from__height' style={ classes.padding }>
      <Container
        style={ { background: '#FFF' } }
        maxWidth='false'
        className='hero'>
        <Article wrap='wrap'>
          <Stack sx={ { pt: 0, mb: 0, p: 0, m: 0 } }
            zIndex="1000"
            direction={ { xs: 'column', md: 'row' } }
            spacing={ { xs: 4 } }>

            <Stack sx={ { p: 2, mb: 10 } }
              justifycontent="center"
              direction={ { xs: 'column', md: 'column' } }
              spacing={ 2 }
            >
              <Typography
                sx={ { fontWeight: 'bolder' } }
                component="h1"
                variant="h2"
                color="text.primary">
                Changing the way we fund college education.
              </Typography>
              <Font size='22px' weight='300' color=''>
                GapFundr is the purposeful solution that provides college students with an easy way to raise funds for college expenses and connect with their network of donors.
              </Font>
              <Font size='22px' weight='300' color=''>
                Anyone can give, so join the joyful experience!
              </Font>
              <Spacer />
              {/* Buttons */ }
              <HStack alignitems='center' justifycontent='center' wrap='wrap'>
                <Button className='button__contained' onClick={ () => navigate('/search') }>Provide Contribution</Button>
                <Button className='button__outlined' onClick={ checkUserAuthState }>
                  { authenticatedUser?.isAuthenticated ? 'Go to dashboard' : 'Create a Gapfundr Profile' } </Button>
              </HStack>
            </Stack>
            <Box
              className='__image__push__down__ fade-in-content'
              component="img"
              sx={ {
                p: 0,
                maxWidth: { xs: '100%', md: '60%' },
              } }
              alt="Student"
              src={ HeroImage }
            />
          </Stack>
        </Article>
      </Container>

      <Container maxWidth='false' className='__background__'>
        <Grid container sx={ { p: 2 } }>
          <Grid item xs={ 12 } sm={ 6 } md={ 4 } xl={ 4 }>
            <Typography variant="h6" color="white" paragraph>
              A welcoming environment for attending and aspiring college students alike.
            </Typography>
            <Typography variant="h5" color="white" paragraph>
              Simple 1-2-3 step process.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={ 2 } className='__call__to__action__'>
          {/* List of items */ }
          { cardItems.map((card, idx) => (
            <Grid item key={ idx } xs={ 12 } md={ 4 }>
              <Card
                sx={ {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 0,
                } }
              >
                <CardContent sx={ { flexGrow: 1 } }>
                  <Box
                    sx={ {
                      display: 'flex',
                      flexDirection: 'row',
                      alignitems: 'normal',
                    } }
                  >
                    <Typography gutterBottom variant="h2" component="h2" color={ '#DC4405' }>
                      { idx + 1 }.
                    </Typography>
                    <Spacer />
                    <Box
                      sx={ {
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignitems: 'center',

                      } }

                    >
                      <Font size='22px' weight='300' color=''> { card.content } </Font>
                      {/* Show button */ }
                      { card.buttonContent && <Button className='button__outlined' onClick={ checkUserAuthState }>
                        { !props.auth?.isAuthenticated ? card.buttonContent : 'Go to dashboard' }
                      </Button> }
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )) }
        </Grid>
      </Container>

      {/*  Middle section */ }

      <Article backgroundcolor='#FFF' wrap='wrap' radius='0px'>
        <Box
          className='fade-in-content'
          component="img"
          src={ MiddleImage }
          sx={ {
            position: 'relative',
            m: 0,
            maxWidth: { xs: '100%', md: '50%' }
          } }
          alt="The house from the offer."

        />
        <Stack
          sx={ {
            p: 2,
            pb: 4,
            pt: 10
          } }
          direction="column"
          spacing={ 2 }
          justifycontent="center">
          <Typography
            component="h2"
            variant="32"
            sx={ { top: '100px' } }
            color="text.primary"
            gutterBottom
          >
            Donors join the joyful experience of giving and encouraging our future leaders.
          </Typography>
          <Article>
            <Font size='22px' weight='300' color=''>
              Donors can easily provide contributions to any GapFundr student. Any amount not spent during a semester will roll over to the student’s next semester, providing students a head start on the next term’s expenses! Visit regularly and provide to any students at any time.
            </Font>
          </Article>
          <HStack alignitems='center' justifycontent='center' wrap='wrap'>
            {/* Buttons */ }
            <Button onClick={ () => navigate('/search') } className='btn button__contained'>
              Provide Contribution
            </Button>
            <Button
              className='btn button__search__middle__section'
              onClick={ () => navigate('/search') }>
              <StackedButton gap='6px'>
                <SearchIcon sx={ {
                  color: '#C4C4C4'
                } } />
                <Font size='14px'>
                  Find student by name or school
                </Font>
              </StackedButton>

              <ArrowRightAltIcon sx={ { color: '#DC4405' } } />
            </Button>
          </HStack>
        </Stack>
      </Article>
      {/* Bottom section  */ }
      <Article padding='0px' style={ { backgroundColor: '#F4F3F3' } }>
        <Stack
          sx={ { pt: 10, pb: 10 } }
          direction={ { xs: 'column', md: 'row' } }
          spacing={ 2 }
          justifycontent="center">
          <Stack
            sx={ {
              pt: 2,
              position: 'relative',
              zIndex: '-9999',
              p: 2
            } }
            justifycontent="center"
            direction='column'
            spacing={ 2 }>
            <Typography
              component="h2"
              variant="32"
              color="text.primary"
              gutterBottom
            >
              Securely transfer funds
            </Typography>

            <Font size='22px' weight='300' color=''>
              Identify the amount that you would like to be transferred to your college and let us handle the rest! We will contact your institution to initiate the transfer and the communication. Within no time the funds will be in your student account!
            </Font>
            <Stack
              sx={ { pt: 4, pb: 4 } }
              direction="row"
              spacing={ 2 }
              justifycontent="center"
            >
              {/* Button */ }
              <Button className='btn button__outlined' onClick={ checkUserAuthState }>
                { !props.auth?.isAuthenticated ? 'Create a GapFundr Profile' : 'Go to dashboard' }
              </Button>
            </Stack>

          </Stack>
          <Box
            className='fade-in-content'
            component="img"
            sx={ {
              maxWidth: { xs: '100%', sm: '100%', md: '50%' },
            } }
            alt="The house from the offer."
            src={ BottomImage }
          />
        </Stack>
      </Article>
      {/* Footer */ }
      <Container maxWidth='false' sx={ {
        backgroundColor: '#000', height: '50%', padding: 8
      }
      }>
        <Article wrap='wrap' justifycontent='space-between'>
          <Box component="img"
            sx={ {
              display: 'block',
              maxWidth: { xs: '100px', md: '100px' }
            } }
            alt="The house from the offer."
            src={ LogoWhite }
          />
          <Typography
            variant="body2"
            align="center"
            color='#C4C4C4'
            component="p"
          >
            <a href="https://gapfunr-legal-docs.s3.us-west-2.amazonaws.com/Privacy+Policy_Revised+for+Pledge-2+PDF.pdf" target='blank'>Privacy Policy</a> { ' ' }  | { ' ' }
            <a href="https://gapfunr-legal-docs.s3.us-west-2.amazonaws.com/Website+Terms+of+Use_v2-2+PDF.pdf" target='blank'>Terms and Conditions</a>  |  Site Map
          </Typography>
          <Copyright />
        </Article>

      </Container >
    </main >
    </>
  );
}

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error.error
});

export default connect(mapStateToProps)(LandingPage);