import { ActionType } from "./actionTypes";
import setAuthToken from '../Util/setAuth';
import { clearStorage, setLocalItem } from "../Util/localStorage";

export const setCurrentUser = (payload) => {
  return {
    type: ActionType.SET_CURRENT_USER,
    payload: payload
  };
};

export const setCurrentAdmin = (payload) => {
  return {
    type: ActionType.SET_CURRENT_ADMIN,
    payload: payload
  };
};

export const setCurrentComments = (payload) => {
  return {
    type: ActionType.SET_CURRENT_COMMENTS,
    payload: payload
  };
};

export const setAllCamp = (payload) => {
  return {
    type: ActionType.SET_ALL_CAMPAIGN,
    payload: payload
  };
};
export const setAllComments = (payload) => {
  return {
    type: ActionType.SET_ALL_COMMENTS,
    payload: payload
  };
};
export const setAllVisits = (payload) => {
  return {
    type: ActionType.SET_TOTAL_VISITS,
    payload: payload
  };
};
export const setAllContributions = (payload) => {
  return {
    type: ActionType.SET_TOTAL_CONTRIBUTIONS,
    payload: payload
  };
};
export const setMonthlyContributions = (payload) => {
  return {
    type: ActionType.SET_MONTHLY_CONTRIBUTIONS,
    payload: payload
  };
};
export const setMontlyGoals = (payload) => {
  return {
    type: ActionType.SET_MONTHLY_GOALS,
    payload: payload
  };
};
export const setThisYearVisits = (payload) => {
  return {
    type: ActionType.SET_THIS_YEAR_VISITS,
    payload: payload
  };
};
export const setThisYearCont = (payload) => {
  return {
    type: ActionType.SET_THIS_YEAR_CONTRIBUTIONS,
    payload: payload
  };
};
export const setThisYearCampaigns = (payload) => {
  return {
    type: ActionType.SET_THIS_YEAR_CAMPAIGNS,
    payload: payload
  };
};
export const setAllUsers = (payload) => {
  return {
    type: ActionType.SET_ALL_USERS,
    payload: payload
  };
};

export const setThisYearUsers = (payload) => {
  return {
    type: ActionType.SET_THIS_YEAR_USERS,
    payload: payload
  };
};

export const setCurrentCamp = (payload) => {
  return {
    type: ActionType.setCurrentCamp,
    payload: payload
  };
};

export const setError = (payload) => {
  return {
    type: ActionType.SET_ERROR,
    payload: payload
  };
};

export const setCampaignProfile = (payload) => {
  return {
    type: ActionType.SET_CAMPAIGN_PROFILE,
    payload: payload
  };
};

export const setCampaignProfiles = (payload) => {
  return {
    type: ActionType.SET_CAMPAIGN_PROFILES,
    payload: payload
  };
};

export const setSearchResultCnt = (payload) => {
  return {
    type: ActionType.SET_SEARCH_RESULT_COUNTS,
    payload: payload
  };
};

export const setProfile = (payload) => {
  return {
    type: ActionType.SET_PROFILE,
    payload: payload
  };
};


export const setProfiles = (payload) => {
  return {
    type: ActionType.SET_PROFILES,
    payload: payload
  };
};

export const showLoading = () => {
  return {
    type: ActionType.SET_LOADING,
    payload: {}
  };
};

export const hideLoading = () => {
  return {
    type: ActionType.HIDE_LOADING,
    payload: {}
  };
};

export const setUserLocally = (user) => {
  setCurrentUser(user);
  return user;
};


export const clearCurrentProfile = () => {
  clearStorage({ key: "gapFundrJwtToken" });
  return {
    type: ActionType.CLEAR_CURRENT_USER,
  };
};

export const setToken = (token) => {
  setAuthToken(token);
  setLocalItem({ key: 'gapFundrJwtToken', value: token });
};

export const setUserDemographics = (payload) => {
  return {
    type: ActionType.SET_USERDEMO,
    payload: payload
  };
}

export const setGraduateUsers = (payload) => {
  return {
    type: ActionType.SET_GRADUATE_USER,
    payload: payload
  };
}