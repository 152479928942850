import { ActionType } from '../actionTypes/index';

const initialState = {
	allcampaign: [],
	thisYearCampaigns: {},
	currentcampaigns: [],
	currentcomments: [],
	campaigns: [],
	campaign: {},
	contributions: [],
	campaignProfile: {},
	campaignProfiles: [],
	fundsRequest: {},
	commentsTotalCnt: {},
	loading: false
};

function campaignReducer(state = initialState, action) {
	switch (action.type) {
		case ActionType.SET_CAMPAIGN:
			return {
				...state,
				campaign: action.payload,
				loading: true
			};
		case ActionType.REQUEST_FUNDS:
			return {
				...state,
				fundsRequest: action.payload,
				loading: true
			};
		case ActionType.SET_CAMPAIGN_PROFILE:
			return {
				...state,
				campaignProfile: action.payload,
				loading: true
			};
		case ActionType.SET_CAMPAIGN_PROFILES:
			return {
				...state,
				campaignProfiles: action.payload,
				loading: true
			};
		case ActionType.SET_CAMPAIGNS:
			return {
				...state,
				campaigns: action.payload,
			};
		case ActionType.SET_ALL_CAMPAIGN:
			return {
				...state,
				allcampaign: action.payload,
			};
		case ActionType.SET_ALL_COMMENTS:
			return {
				...state,
				commentsTotalCnt: action.payload,
			};
		case ActionType.SET_CURRENT_CAMPAIGNS:
			return {
				...state,
				currentcampaigns: action.payload,
			};
		case ActionType.SET_THIS_YEAR_CAMPAIGNS:
			return {
				...state,
				thisYearCampaigns: action.payload,
			};
		case ActionType.SET_CURRENT_COMMENTS:
			return {
				...state,
				currentcomments: action.payload,
			};
		case ActionType.SET_DONATIONS:
			return {
				...state,
				contributions: action.payload,
			};
		default:
			return state;
	}
}

export default campaignReducer;
