/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-negated-condition */
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Paper, Typography,
  Snackbar,
  Button,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Activities from '../../Assets/Activities.png';
import BookOpen from '../../Assets/BookOpen.png';
import GraduationCap from '../../Assets/GraduationCap.png';
import House from '../../Assets/House.png';
import Languages from '../../Assets/Languages.png';
import Desire from '../../Assets/Desire.png';
import MapPin from '../../Assets/MapPin.png';
import {
  Container,
  ContentView,
  Font,
  HStack,
  IconImage,
  Image,
  Layout,
  Line,
  Spacer,
  VStack
} from '../../Component/Components';
import { ContainerCard } from '../../Component/ContainerCard';
import TopSection from '../../Component/TopSection/TopSection';
import { getCampaignProfile } from '../../State/actions/campaign';
import { showLoading } from '../../State/setPayload';
import { animateValue } from '../../Util/animateValue';
import '../style.css';
import { Box, Modal } from "@mui/material";
import './style.css'
import Page from '../Page';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

function ModalError(props) {
  return (
    <Modal
      sx={{ width: '100%' }}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ alignSelf: 'center', mt: 2 }}>
          This student’s profile is incomplete and cannot receive funds at this time.
        </Typography>
        <Typography sx={{ alignSelf: 'center', mt: 2 }}>
          Thank you!
        </Typography>
      </Box>
    </Modal>
  )
}

function Profile(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { campaignProfile } = props;
  const [widthChange, setWidthChange] = useState(0);
  const dispatch = useDispatch();
  const getCampaignProf = () => props.getCampaignProfile(id);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openError, setErrorOpen] = useState(false);
  const [openCopied, setOpenCopied] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);


  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
  }

  const defaultImageUrl = "https://gapfundr-media.s3.us-west-2.amazonaws.com/assets/default_campaign_cover.jpg";

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    dispatch(showLoading());
    getCampaignProf();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  useEffect(() => {
    const amountGoal = document.getElementById("amount_goal");
    if (campaignProfile?.owner?.campaign_create_status) {
      animateValue(amountGoal, campaignProfile?.goal, 0, 3000);
    } else {
      animateValue(amountGoal, parseInt(0), 0, 3000);
    }
  }, [campaignProfile?.goal]);

  useEffect(() => {
    const runningTotal = document.getElementById("running_total");
    if (campaignProfile?.owner?.campaign_create_status) {
      animateValue(runningTotal, parseInt(campaignProfile?.running_total), 0, 3000);
    }
    else {
      animateValue(runningTotal, parseInt(0), 0, 3000);
    }
  }, [campaignProfile?.running_total]);

  useEffect(() => {
    const runningTotal = parseInt(campaignProfile?.running_total) >
      parseInt(campaignProfile?.goal) ?
      campaignProfile?.goal : campaignProfile?.running_total
    if (runningTotal) {
      setWidthChange(Math.trunc(parseInt(runningTotal) / parseInt(campaignProfile?.goal) * 100));
    } else {
      setWidthChange(parseInt(0));
    }
  })

  let profileLoading;
  if (props.loading) {
    profileLoading = <HStack alignitems='center' justifycontent="center">
      <CircularProgress size={30} color='inherit' />
    </HStack>
  }

  const mobileContentView = () => (
    <ContentView >
      <HStack wrap='wrap'>
        <VStack>
          <Container
            padding={'0px'}
            width={'100%'}
            radius={'0px'}>
            <Image
              className='fade-image-in'
              src={campaignProfile?.cover_photo?.file_url || defaultImageUrl}
              width={'100%'}
              loading="lazy"
              style={{
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Container>
          <ContainerCard>
            <Layout size='20px'>
              <HStack justifycontent={'center'} alignItems='center'>

                <Button
                  onClick={handleOpen}
                  sx={{
                    bgcolor: '#3ccbda',
                    color: 'white',
                    padding: '6px 12px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: 1,
                    width: '40%',
                    '&:hover': {
                      color: 'black',
                    }
                  }}
                >
                  <ShareIcon sx={{ fontSize: '16px', mr: 1 }} />
                  <Typography variant="body2" component="span">
                    Share
                  </Typography>
                </Button>
                <Typography sx={{ marginTop: '4px', marginRight: '20px' }}>
                  Text your link or share your profile on social media.
                </Typography>
              </HStack>
            </Layout>
            <Line />

            <Layout size='20px'>
              <VStack>
                <Font size={'14px'} weight={500}>
                  CAMPAIGN STATUS
                </Font>
                <Container
                  direction={'row'}
                  alignitems
                  justifycontent={'center'}
                  display={'flex'}
                  position={'relative'}
                  padding={'0px'}
                  height={'60px'}
                  width={'100%'}
                  style={{ background: '#E5E5E5' }}
                  radius={'10px'} >

                  <Container
                    direction={'row'}
                    alignitems
                    justifycontent={widthChange === 100 ? 'end' : 'start'}
                    display={'flex'}
                    position={'relative'}
                    height={'100%'}
                    width={`${widthChange < 40 ? 30 : widthChange}%`}
                    style={{ background: widthChange === 100 ? '#78CF90' : '#ECD76B' }}
                    padding={'20px'}
                    radius={'10px'} >
                    $<Font size={'22px'} weight={500} id='running_total'></Font>
                  </Container>

                  <Container
                    direction={'row'}
                    alignitems
                    justifycontent={'end'}
                    display={widthChange === 100 ? 'none' : 'flex'}
                    position={'relative'}
                    height={'100%'}
                    width={`${100 - widthChange}%`}
                    padding={'20px'}
                    style={{ background: 'transparent' }}
                    radius={'10px'} >
                    $<Font size={'22px'} weight={500} id="amount_goal"></Font>
                  </Container>
                </Container>

                <HStack justifycontent={'space-between'}>
                  <Font size={'14px'} weight={500}>Amount raised</Font>
                  <Font size={'14px'} weight={500}>Fundraising goal</Font>
                </HStack>
              </VStack>
              <Spacer size={'20px'} />

              <HStack justifycontent='space-between' alignitems={'center'}>
                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Font size={{ xs: '14px', sm: '18px' }} weight={500}>
                    {campaignProfile?.contributions && campaignProfile?.contributions.length}
                  </Font>
                  <Font size={{ xs: '10px', sm: '14px' }} weight={500} align>
                    Contributions
                  </Font>
                </Card>

                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Font size={{ xs: '10px', sm: '14px' }} weight={500} align>
                    Created
                  </Font>
                  <Font size={{ xs: '14px', sm: '18px' }} weight={500}>
                    {campaignProfile?.created_at && moment(campaignProfile?.created_at).utc().format('MM/YYYY')}
                  </Font>
                </Card>

                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    textAlign: 'center',
                    padding: 1,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <Typography variant="body2" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '14px' } }}>Funding</Typography>
                      <Typography variant="body2" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '14px' } }}>&nbsp;Status</Typography>
                    </Box>
                    <Typography variant="h6" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>Active</Typography>
                  </Box>
                </Card>
                {/* <Spacer size={ '20px' }/> */}
              </HStack>

              <Spacer size={'40px'} />
              <VStack>
                <Font size={'14px'} weight={500}>FUNDING NEEDS</Font>

                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                  {
                    campaignProfile?.funds_use && campaignProfile?.funds_use.split(",").map((use, idx) => (

                      <Grid key={idx} item xs={6} sm={3}>
                        <Paper
                          elevation={0}
                          sx={{
                            background: '#C6C4FB', width: 'auto',
                            borderRadius: 6,
                            textAlign: 'center',
                            alignItems: 'center'

                          }}>
                          <Font size={'12px'} weight={600} >{use} </Font>
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              </VStack>
            </Layout >

            <Line />
            <Spacer size={'40px'} />

            <Layout size='20px'>

              <VStack>
                <button className="__bounce__donate__button"
                  style={{ background: '#FFB35C', borderRadius: '10px', padding: "20px", border: 0 }}
                  onClick={() => {
                    // if (campaignProfile?.owner?.campaign_create_status) {
                    if (1) {
                      navigate(`/campaign/payment/${campaignProfile?.owner?._id}`,
                        { state: campaignProfile })
                    } else {
                      setErrorOpen(true);
                    }
                  }
                  }>
                  <Font size={'22px'} weight={500}>Contribute</Font>
                </button>
              </VStack>
            </Layout>
          </ContainerCard >
        </VStack>
        <Dialog open={open} onClose={handleClose}>
          <DialogActions sx={{ top: 0, right: '0px' }}>
            <HighlightOffOutlinedIcon onClick={handleClose} />
          </DialogActions>
          <DialogContent>
            <DialogContentText fontSize={'20px'}>
              Share your profile
            </DialogContentText>
            <Spacer />
            <HStack wrap='wrap' gap='10px' justifycontent='center'>
              <HStack backgroundcolor={'#F1F1F1'} style={{ borderRadius: '10px', wordBreak: 'break-all' }} alignitems='center'>
                <Layout>
                  <Typography
                    sx={{
                      alignSelf: 'center',
                      overflow: 'hidden',
                      fontSize: '12px', color: '#000'
                    }} id="modal-modal-description">
                    <a href={`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`} rel='noreferrer' target='_blank' style={{ color: "#000" }}>
                      {`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`}
                    </a>
                  </Typography>
                </Layout>
              </HStack>

              <Button className='__btn__outlined__' onClick={() => {
                setOpenCopied(true)
                // navigator.clipboard.writeText(window.location.toString()))
                navigator.clipboard.writeText(`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`);
              }}>
                Copy
              </Button>
            </HStack>
          </DialogContent>
          <Spacer />
        </Dialog>
        <Snackbar open={openCopied} autoHideDuration={6000} onClose={() => setOpenCopied(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message="Copied to clipboard" />

        <VStack>
          <Container style={{
            border: ' 1px solid #CCDBF0',
            background: '#FFFFFF',
            boxSizing: ' border-box'
          }}
            padding={'0px'}
            width={'100%'}
            radius={'10px'}>
            <Layout size='20px'>
              {campaignProfile?.description ?
                <Font size={'18px'} weight={500}>{campaignProfile?.description}</Font> :
                <VStack>
                  <Font size={'18px'} weight={500}>
                    Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.
                  </Font>
                </VStack>}
            </Layout>
            <Line />
            <Layout size='20px'>
              <HStack justifycontent={'space-around'} wrap='wrap'>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={MapPin} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.prospective_institution &&
                      campaignProfile.owner?.institution?.prospective_institution}
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={GraduationCap} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.expected_graduation && <>
                      {campaignProfile.owner?.institution?.expected_graduation}
                    </>
                    }
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={BookOpen}
                    height={'40px'}
                    width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {
                      campaignProfile?.owner?.institution && <>
                        {campaignProfile.owner?.institution?.degree} Degree  in {' '}
                        {campaignProfile.owner?.institution?.education_program_track}
                      </>
                    }
                  </Font>
                </VStack>
              </HStack>
            </Layout>
          </Container>

          <ContainerCard>

            <Layout size='20px'>
              <VStack justifycontent={'space-around'}>
                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage src={House} height={'40px'} width={'40px'} />
                  <Font size={'16px'} weight={500}>
                    I’m from -- {campaignProfile.owner?.city + ", " + campaignProfile.owner?.state}
                  </Font>
                </HStack>

                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage
                    src={Desire}
                    height={'35px'}
                    width={'35px'} />
                  <Font size={'16px'} weight={500}>
                    My desired major is -- {campaignProfile.owner?.desired_major && campaignProfile.owner?.desired_major}
                  </Font>
                </HStack>

                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage
                    src={Languages}
                    height={'40px'}
                    width={'40px'} />
                  <Font size={'16px'} weight={500}>
                    My dream job is to work at -- {campaignProfile.owner?.dream_job && campaignProfile.owner?.dream_job}
                  </Font>

                </HStack>
                <HStack alignitems justifycontent={'start'}>
                  <IconImage src={Activities} height={'40px'} width={'40px'} />
                  <Font size={'16px'} weight={500}>My hobbies are{' '}{campaignProfile.owner?.hobbies}</Font>
                  {!campaignProfile?.owner?.hobbies && <Fragment>---</Fragment>}
                </HStack>
              </VStack>
            </Layout>
          </ContainerCard>
        </VStack >
      </HStack >

      <Spacer size={'20px'} />
      {/* <CommentView { ...campaignProfile } /> */}
    </ContentView >
  );

  const desktopContentView = () => (
    <ContentView >
      <HStack wrap='wrap'>
        <VStack>
          <Container
            padding={'0px'}
            width={'100%'}
            radius={'0px'}>
            <Image
              className='fade-image-in'
              src={campaignProfile?.cover_photo?.file_url || defaultImageUrl}
              width={'100%'}
              loading="lazy"
              style={{
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Container>
          {/* <Container style={{
            border: ' 1px solid #CCDBF0',
            background: '#FFFFFF',
            boxSizing: ' border-box'
          }}
            padding={'0px'}
            width={'100%'}
            radius={'10px'}>
            <Layout size='20px'>
              {campaignProfile?.description ?
                <Font size={'18px'} weight={500}>{campaignProfile?.description}</Font> :
                <VStack>
                  <Font size={'18px'} weight={500}>
                    Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.
                  </Font>
                </VStack>}
            </Layout>
            <Line />
            <Layout size='20px'>
              <HStack justifycontent={'space-around'} wrap='wrap'>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={MapPin} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.prospective_institution &&
                      campaignProfile.owner?.institution?.prospective_institution}
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={GraduationCap} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.expected_graduation && <>
                      {campaignProfile.owner?.institution?.expected_graduation}
                    </>
                    }
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={BookOpen}
                    height={'40px'}
                    width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {
                      campaignProfile?.owner?.institution && <>
                        {campaignProfile.owner?.institution?.degree} Degree  in {' '}
                        {campaignProfile.owner?.institution?.education_program_track}
                      </>
                    }
                  </Font>
                </VStack>
              </HStack>
            </Layout>
          </Container> */}
        </VStack>
        <Dialog open={open} onClose={handleClose}>
          <DialogActions sx={{ top: 0, right: '0px' }}>
            <HighlightOffOutlinedIcon onClick={handleClose} />
          </DialogActions>
          <DialogContent>
            <DialogContentText fontSize={'20px'}>
              Share your profile
            </DialogContentText>
            <Spacer />
            <HStack wrap='wrap' gap='10px' justifycontent='center'>
              <HStack backgroundcolor={'#F1F1F1'} style={{ borderRadius: '10px', wordBreak: 'break-all' }} alignitems='center'>
                <Layout>
                  <Typography
                    sx={{
                      alignSelf: 'center',
                      overflow: 'hidden',
                      fontSize: '12px', color: '#000'
                    }} id="modal-modal-description">
                    <a href={`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`} rel='noreferrer' target='_blank' style={{ color: "#000" }}>
                      {`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`}
                    </a>
                  </Typography>
                </Layout>
              </HStack>

              <Button className='__btn__outlined__' onClick={() => {
                setOpenCopied(true)
                // navigator.clipboard.writeText(window.location.toString()))
                navigator.clipboard.writeText(`https://www.gapfundr.org/profile/${campaignProfile?.owner?._id}`);
              }}>
                Copy
              </Button>
            </HStack>
          </DialogContent>
          <Spacer />
        </Dialog>
        <Snackbar open={openCopied} autoHideDuration={6000} onClose={() => setOpenCopied(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message="Copied to clipboard" />

        <VStack>
          <ContainerCard>
            <Layout size='20px'>
              <HStack justifycontent={'center'} alignItems='center'>

                <Button
                  onClick={handleOpen}
                  sx={{
                    bgcolor: '#3ccbda',
                    color: 'white',
                    padding: '6px 12px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: 1,
                    width: '40%',
                    '&:hover': {
                      color: 'black',
                    }
                  }}
                >
                  <ShareIcon sx={{ fontSize: '16px', mr: 1 }} />
                  <Typography variant="body2" component="span">
                    Share
                  </Typography>
                </Button>
                <Typography sx={{ marginTop: '4px', marginRight: '20px' }}>
                  Text your link or share your profile on social media.
                </Typography>
              </HStack>
            </Layout>
            <Line />

            <Layout size='20px'>
              <VStack>
                <Font size={'14px'} weight={500}>
                  CAMPAIGN STATUS
                </Font>
                <Container
                  direction={'row'}
                  alignitems
                  justifycontent={'center'}
                  display={'flex'}
                  position={'relative'}
                  padding={'0px'}
                  height={'60px'}
                  width={'100%'}
                  style={{ background: '#E5E5E5' }}
                  radius={'10px'} >

                  <Container
                    direction={'row'}
                    alignitems
                    justifycontent={widthChange === 100 ? 'end' : 'start'}
                    display={'flex'}
                    position={'relative'}
                    height={'100%'}
                    width={`${widthChange < 40 ? 30 : widthChange}%`}
                    style={{ background: widthChange === 100 ? '#78CF90' : '#ECD76B' }}
                    padding={'20px'}
                    radius={'10px'} >
                    $<Font size={'22px'} weight={500} id='running_total'></Font>
                  </Container>

                  <Container
                    direction={'row'}
                    alignitems
                    justifycontent={'end'}
                    display={widthChange === 100 ? 'none' : 'flex'}
                    position={'relative'}
                    height={'100%'}
                    width={`${100 - widthChange}%`}
                    padding={'20px'}
                    style={{ background: 'transparent' }}
                    radius={'10px'} >
                    $<Font size={'22px'} weight={500} id="amount_goal"></Font>
                  </Container>
                </Container>

                <HStack justifycontent={'space-between'}>
                  <Font size={'14px'} weight={500}>Amount raised</Font>
                  <Font size={'14px'} weight={500}>Fundraising goal</Font>
                </HStack>
              </VStack>
              <Spacer size={'20px'} />

              <HStack justifycontent='space-between' alignitems={'center'}>
                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Font size={{ xs: '14px', sm: '18px' }} weight={500}>
                    {campaignProfile?.contributions && campaignProfile?.contributions.length}
                  </Font>
                  <Font size={{ xs: '10px', sm: '14px' }} weight={500} align>
                    Contributions
                  </Font>
                </Card>

                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Font size={{ xs: '10px', sm: '14px' }} weight={500} align>
                    Created
                  </Font>
                  <Font size={{ xs: '14px', sm: '18px' }} weight={500}>
                    {campaignProfile?.created_at && moment(campaignProfile?.created_at).utc().format('MM/YYYY')}
                  </Font>
                </Card>

                <Card
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                    width: 140,
                    display: 'flex',
                    background: '#D2E4FF',
                    flexDirection: 'column',
                    borderRadius: 100,
                    textAlign: 'center',
                    padding: 1,
                    '@media (max-width:600px)': {
                      fontSize: '12px', // Even smaller font size on mobile
                    }
                  }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <Typography variant="body2" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '14px' } }}>Funding</Typography>
                      <Typography variant="body2" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '14px' } }}>&nbsp;Status</Typography>
                    </Box>
                    <Typography variant="h6" fontWeight={500} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>Active</Typography>
                  </Box>
                </Card>
                {/* <Spacer size={ '20px' }/> */}
              </HStack>

              <Spacer size={'40px'} />
              <VStack>
                <Font size={'14px'} weight={500}>FUNDING NEEDS</Font>

                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                  {
                    campaignProfile?.funds_use && campaignProfile?.funds_use.split(",").map((use, idx) => (

                      <Grid key={idx} item xs={6} sm={3}>
                        <Paper
                          elevation={0}
                          sx={{
                            background: '#C6C4FB', width: 'auto',
                            borderRadius: 6,
                            textAlign: 'center',
                            alignItems: 'center'

                          }}>
                          <Font size={'12px'} weight={600} >{use} </Font>
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              </VStack>
            </Layout >

            <Line />
            <Spacer size={'40px'} />

            <Layout size='20px'>

              <VStack>
                <button className="__bounce__donate__button"
                  style={{ background: '#FFB35C', borderRadius: '10px', padding: "20px", border: 0 }}
                  onClick={() => {
                    // if (campaignProfile?.owner?.campaign_create_status) {
                    if (1) {
                      navigate(`/campaign/payment/${campaignProfile?.owner?._id}`,
                        { state: campaignProfile })
                    } else {
                      setErrorOpen(true);
                    }
                  }
                  }>
                  <Font size={'22px'} weight={500}>Contribute</Font>
                </button>
              </VStack>
            </Layout>
          </ContainerCard >
          <Container style={{
            border: ' 1px solid #CCDBF0',
            background: '#FFFFFF',
            boxSizing: ' border-box'
          }}
            padding={'0px'}
            width={'100%'}
            radius={'10px'}>
            <Layout size='20px'>
              {campaignProfile?.description ?
                <Font size={'18px'} weight={500}>{campaignProfile?.description}</Font> :
                <VStack>
                  <Font size={'18px'} weight={500}>
                    Welcome to my GapFundr page! Please help me by contributing funds to assist paying for college.
                  </Font>
                </VStack>}
            </Layout>
            <Line />
            <Layout size='20px'>
              <HStack justifycontent={'space-around'} wrap='wrap'>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={MapPin} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.prospective_institution &&
                      campaignProfile.owner?.institution?.prospective_institution}
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={GraduationCap} height={'40px'} width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {campaignProfile.owner?.institution?.expected_graduation && <>
                      {campaignProfile.owner?.institution?.expected_graduation}
                    </>
                    }
                  </Font>
                </VStack>
                <VStack alignitems='center' justifycontent={'center'}>
                  <IconImage src={BookOpen}
                    height={'40px'}
                    width={'40px'} />
                  <Font size={'16px'} align='center' weight={500}>
                    {
                      campaignProfile?.owner?.institution && <>
                        {campaignProfile.owner?.institution?.degree} Degree  in {' '}
                        {campaignProfile.owner?.institution?.education_program_track}
                      </>
                    }
                  </Font>
                </VStack>
              </HStack>
            </Layout>
          </Container>
          <ContainerCard>

            <Layout size='20px'>
              <VStack justifycontent={'space-around'}>
                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage src={House} height={'40px'} width={'40px'} />
                  <Font size={'16px'} weight={500}>
                    I’m from -- {campaignProfile.owner?.city + ", " + campaignProfile.owner?.state}
                  </Font>
                </HStack>

                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage
                    src={Desire}
                    height={'35px'}
                    width={'35px'} />
                  <Font size={'16px'} weight={500}>
                    My desired major is -- {campaignProfile.owner?.desired_major && campaignProfile.owner?.desired_major}
                  </Font>
                </HStack>

                <HStack alignitems='center' justifycontent={'start'}>
                  <IconImage
                    src={Languages}
                    height={'40px'}
                    width={'40px'} />
                  <Font size={'16px'} weight={500}>
                    My dream job is to work at -- {campaignProfile.owner?.dream_job && campaignProfile.owner?.dream_job}
                  </Font>

                </HStack>
                <HStack alignitems justifycontent={'start'}>
                  <IconImage src={Activities} height={'40px'} width={'40px'} />
                  <Font size={'16px'} weight={500}>My hobbies are{' '}{campaignProfile.owner?.hobbies}</Font>
                  {!campaignProfile?.owner?.hobbies && <Fragment>---</Fragment>}
                </HStack>
              </VStack>
            </Layout>
          </ContainerCard>
        </VStack >
      </HStack >

      <Spacer size={'20px'} />
      {/* <CommentView { ...campaignProfile } /> */}
    </ContentView >
  );

  if (props.campaignProfile?.owner?.deactivate_account) {
    return (
      <Page>
        {
          openError && <ModalError open={openError} handleClose={() => { setErrorOpen(false) }} />
        }
        <TopSection {...props?.campaignProfile} />
        <VStack alignitems='center' justifycontent='center' style={{ height: '100vh' }}>
          <BlockIcon color="gray" style={{ fontSize: 40 }} />
          <Typography variant="h5" color="gray">
            Sorry, this campaign is currently disabled.
          </Typography>
        </VStack>
      </Page>
    );
  }

  return (
    <Page >
      {
        openError && <ModalError open={openError} handleClose={() => { setErrorOpen(false) }} />
      }
      {!profileLoading ? profileLoading :
        <>
          <TopSection {...props?.campaignProfile} />
          {desktopContentView()}
          <Spacer size={'40px'} />
        </ >
      }
    </Page >
  );
}

Profile.propTypes = {
  campaignProfile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getCampaignProfile: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error.error,
  loading: state.loading,
  campaignProfile: state.campaign.campaignProfile,
});

export default connect(mapStateToProps, { getCampaignProfile })(Profile);
