
export const ActionType = {
    CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
    LOGOUT_USER: "LOGOUT_USER",
    SIGNING: "SIGNING",
    CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
    HIDE_LOADING: "HIDE_LOADING",
    DELETE_FROM_CART: 'DELETE_FROM_CART',
    CLEAR_CART: "CLEAR_CART",
    CREATE_DONATION: "CREATE_DONATION",
    SET_CAMPAIGN: "SET_CAMPAIGN",
    SET_ALL_CAMPAIGN: "SET_ALL_CAMPAIGN",
    SET_ALL_USERS: "SET_ALL_USERS",
    SET_ALL_COMMENTS: "SET_ALL_COMMENTS",
    SET_CURRENT_CAMPAIGNS: "SET_CURRENT_CAMPAIGNS",
    SET_CURRENT_USERS: "SET_CURRENT_USERS",
    SET_CURRENT_COMMENTS: "SET_CURRENT_COMMENTS",
    SET_NOTIFICATION: "SET_NOTIFICATION",
    SET_CURRENT_USER: "SET_CURRENT_USER",
    SET_CURRENT_ADMIN: "SET_CURRENT_ADMIN",
    SET_TOTAL_FUNDS: "SET_TOTAL_FUNDS",
    SET_TOTAL_CONTRIBUTIONS: "SET_TOTAL_CONTRIBUTIONS",
    SET_THIS_YEAR_CONTRIBUTIONS: "SET_THIS_YEAR_CONTRIBUTIONS",
    SET_TOTAL_VISITS: "SET_TOTAL_VISITS",
    SET_THIS_YEAR_VISITS: "SET_THIS_YEAR_VISITS",
    SET_THIS_YEAR_USERS: "SET_THIS_YEAR_USERS",
    SET_THIS_YEAR_CAMPAIGNS: "SET_THIS_YEAR_CAMPAIGNS",
    SET_SEARCH_RESULT_COUNTS: "SET_SEARCH_RESULT_COUNTS",
    SET_MONTHLY_GOALS: "SET_MONTHLY_GOALS",
    SET_MONTHLY_CONTRIBUTIONS: "SET_MONTHLY_CONTRIBUTIONS",
    SET_ERROR: "SET_ERROR",
    CLEAR_PROFILE: "CLEAR_PROFILE",
    SET_LOADING: "SET_LOADING",
    SET_PROFILES: "SET_PROFILES",
    SET_PROFILE: "SET_PROFILE",
    SET_USER_PROFILE: "SET_USER_PROFILE",
    UPLOAD_COVER_PROFILE: "UPLOAD_COVER_PROFILE",
    UPLOAD_IMAGE_PROFILE: "UPLOAD_IMAGE_PROFILE",
    SET_CAMPAIGNS: "SET_CAMPAIGNS",
    SET_USERDEMO: "SET_USERDEMO",
    SET_GRADUATE_USER: "SET_GRADUATE_USER",

    IS_UPDATING: "IS_UPDATING",
    IS_POSTING: "IS_POSTING",
    IS_DELETING: "IS_DELETING",
    GETTING_DATA: "GETTING_DATA",
    SET_DONATIONS: "SET_DONATIONS",
    SET_CAMPAIGN_PROFILE: "SET_CAMPAIGN_PROFILE",
    SET_CAMPAIGN_PROFILES: "SET_CAMPAIGN_PROFILES",
    REQUEST_FUNDS: "REQUEST_FUNDS"

};