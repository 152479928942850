import NotificationsIcon from '@mui/icons-material/Notifications';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,  Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { Font, HStack, Layout, Line, Spacer, VStack } from '../../Component/Components';
import { ContainerCard } from '../../Component/ContainerCard';
import { getCampaignProfile } from '../../State/actions/campaign';
import { getProfile, updateProfile } from '../../State/actions/profile';
import CampaignProfile from './CampaignProfile';
import './style.css';
import Page from '../Page';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showLoading } from '../../State/setPayload';
function Home(props) {
  const { auth, profile } = props;
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  const updateCurrentUser = () => {
    const payload = {
      first_name: props?.auth?.user?.first_name,
      middle_name: props?.auth?.user?.middle_name,
      last_name: props?.auth?.user?.last_name,
      email: props?.auth?.user?.email,
      password: props?.auth?.user?.password,
      phone_number: props?.auth?.user?.phone_number,
      terms_and_conditions: props?.auth?.user?.terms_and_conditions,
      user_type: 'student',
      dob: props?.auth?.user?.dob,
      gender: props?.auth?.user?.gender,
      ethnicity: props?.auth?.user?.ethnicity,
      city: props?.auth?.user?.city,
      state: props?.auth?.user?.state,
      desired_major: props?.auth?.user?.desired_major,
      institution: props?.auth?.user?.institution,
      isFirstSignin: false,
    };
    setShowPopup(false);
    dispatch(showLoading());
    setTimeout(function () {
      props.updateProfile(props.auth.user._id, payload);
    }, 3000)
  }

  useEffect(() => {
    const firstSignin = props.auth?.user?.isFirstSignin || false;
  
    if (firstSignin) {
      setShowPopup(true);
    }
  }, [props.auth]);

  useEffect(() => {
    props.getCampaignProfile(auth?.user?._id);
    if (!auth.isAuthenticated) return <Navigate to={ '/login' } />;
  }, [auth.isAuthenticated, auth.user._id]);

  return (
    <Page>
      <Dialog
        open={showPopup}
        onClose={updateCurrentUser}
        aria-labelledby="welcome-dialog-title"
        aria-describedby="welcome-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '400px',
            borderRadius: '16px',
            padding: '20px',
            backgroundColor: '#f7f7f7'
          }
        }}
      >
        <DialogTitle id="welcome-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="welcome-dialog-description" sx={{ color: '#333', fontSize: '16px', textAlign: 'center' }}>
            Here is your dashboard. You can view and edit your page here.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={updateCurrentUser} sx={{ bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark' } }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Spacer size='80px' />
      <Font>Dashboard</Font>
      <Spacer />

      <Fragment>
        {/* Campaign Profile */ }
        <ContainerCard>
          {/* Top */ }
          <Layout size="20px">
            <HStack justifycontent='space-between' alignitems='center' wrap='nowrap' gap='10px'>
              <HStack>
                <Font size='18px'>My Campaign</Font>
              </HStack>
              { props.profile.campaign_create_status &&
                <HStack justifycontent='end'>
                  {/* View */ }
                  <Font style={ { cursor: 'pointer' } } size={ '16px' } color='blue' weight={ 400 } onClick={ () => navigate(`/profile/${props?.profile._id}`) }>
                    View
                  </Font>

                  {/* Edit */ }
                  <Font style={ { cursor: 'pointer' } } size={ '16px' } color='green' weight={ 400 } onClick={ () => navigate(`/profile/edit/${props?.profile._id}`) }>
                    Edit
                  </Font>
                </HStack>
              }
            </HStack>
          </Layout>

          <Line />
          {
            props.profile?.campaign_create_status === true ?
              <CampaignProfile { ...props.campaignProfile } /> :
              <Layout size="40px" >
                <VStack justifycontent='center' alignitems='center'>
                  Please create your campaign page
                  <Button type='button' className='__btn__' onClick={ () => navigate('/campaign/create') }>
                    Create Campaign
                  </Button>
                </VStack>
              </Layout>
          }

        </ContainerCard >

        <Spacer size='20px' />

        {/* Notification */ }

        <ContainerCard>
          <Spacer size='20px' />

          <Layout size="20px">
            <HStack justifycontent='space-between' alignitems='center' wrap='nowrap' gap='10px'>
              <HStack>
                <Font size={ '16px' } weight={ '400' }>
                  Notifications  ({ props.campaignProfile?.contributions?.length ? props.campaignProfile?.contributions?.length : 0 })
                </Font>
              </HStack>

              <HStack justifycontent='end'>
                { props.campaignProfile?.contributions?.length &&
                  <Font size={ '16px' } weight={ '400' } cursor='pointer' onClick={ () => navigate('/dashboard/contribution') }>
                    View All
                  </Font>
                }
                <NotificationsIcon sx={ { color: '#DC4405' } } />
              </HStack>

              {
                !props.profile?.campaign_create_status &&
                <HStack alignitems='center' justifycontent='center'>
                  <Font size={ '16px' } weight={ '400' } >You don't have new notifications</Font>
                </HStack>
              }
            </HStack>
          </Layout>

          <Spacer size='20px' />

        </ContainerCard>
      </Fragment>

      <Spacer size='40px' />

    </Page>
  );
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  campaignProfile: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  getCampaignProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  profile: state.profile.profile,
  campaignProfile: state.campaign.campaignProfile
});

export default connect(mapStateToProps, { getProfile, updateProfile, getCampaignProfile })(Home);