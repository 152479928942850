import { ActionType } from '../actionTypes/index';

const initialState = {
	profile: {},
	profiles: [],
	loading: false,
	NumberOfUsers: [],
	currentUsers: [],
	searchResultCnt: {},
};

function profileReducer(state = initialState, action) {
	switch (action.type) {
		case ActionType.SET_PROFILE:
			return {
				...state,
				profile: action.payload,
				loading: true,
			};
		case ActionType.SET_PROFILES:
			return {
				...state,
				profiles: action.payload
			};
		case ActionType.UPDATE_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case ActionType.UPLOAD_IMAGE_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case ActionType.UPLOAD_COVER_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case ActionType.CLEAR_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case ActionType.SET_CURRENT_USERS:
			return {
				...state,
				currentUsers: action.payload,
			};
		case ActionType.SET_ALL_USERS:
			return {
				...state,
				NumberOfUsers: action.payload,
			};
		case ActionType.SET_SEARCH_RESULT_COUNTS:
			return {
				...state,
				searchResultCnt: action.payload,
				loading: true
			};
		default:
			return state;
	}
}

export default profileReducer;
