import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ContentView, Spacer } from '../../Component/Components';
import { TextField } from '../../Component/InputFields';
import { getProfile, updateProfile, getAllUsersCounter, getUserList } from '../../State/actions/profile';
import { getCampaignProfiles } from '../Campaign/CampaignAPI';
import { showLoading } from '../../State/setPayload';
import './style.css';
import MenuBar from '../Navbar/MenuBar';
import Pagination from '../../Component/Paginate';
import CampaignList from '../../Component/CampaignList';
import SearchOffIcon from '@mui/icons-material/SearchOff'
function SearchView(props) {
  const dispatch = useDispatch();
  const [ search, setSearch ] = useState();
  const { data, dataTotalCnt} = props;
  const [pageSize, setPageSize] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  useEffect(() => {
    dispatch(showLoading());
    const apiGetProfiles = () => props.getCampaignProfiles(search, currentPageIndex, pageSize);
    apiGetProfiles();
    return () => { };
  }, [ search, currentPageIndex, pageSize ]);

  const paginate = (pageNumber) => {
		setCurrentPageIndex(pageNumber);
	};

	const numberOfPages = Math.ceil(dataTotalCnt / pageSize);
	const handlePageSizeChange = e => {
		const newPageSize = parseInt(e.target.value);
		setPageSize(newPageSize)
  }

  return (
    <div>
    <MenuBar {...props} />
    <ContentView width={'1400px'} className='fade-in-content'>
      <Spacer size='40px' />
      <TextField
        name={"search"}
        type={"text"}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={"Search a student by their name or university."}
      />
      <div style={{ marginLeft: 'auto' }}>
        {numberOfPages ? (
          <Pagination
            onPress={paginate}
            pageCount={numberOfPages || 0}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize} />
        ) : null}
      </div>
      <Spacer />
      {props.campaignProfiles && props.campaignProfiles.length > 0 ? (
        <CampaignList campaigns={props.campaignProfiles} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '16px', color: '#666' }}>
          <SearchOffIcon style={{ fontSize: '64px', color: '#999' }} />
          <h3>No results found for your search criteria.</h3>
        </div>
      )}
    </ContentView>
  </div>
  );
};


SearchView.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  getCampaignProfiles: PropTypes.func.isRequired,
  dataTotalCnt: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.error,
  profile: state.profile,
  campaignProfiles: state.campaign.campaignProfiles,
  dataTotalCnt: state.profile.searchResultCnt,
  data: state.profile.currentUsers
});

export default connect(mapStateToProps, { getCampaignProfiles, getProfile, updateProfile, getUserList, getAllUsersCounter })(SearchView);