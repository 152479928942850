import { ActionType } from '../actionTypes/index';

const initialState = {
	campaignProfiles: [],
	totalFunds: {},
	totalVisits: {},
	totalContributions: {},
	thisYearContributions: {},
	thisYearVisits: {},
	thisYearUsers: {},
	totalUserCounts: {},
	userDemo: {},
	monthlyGoals: {},
	monthlyContributions: {},
	graduatingUsers: {},
	loading: false
};

function adminReducer(state = initialState, action) {
	switch (action.type) {
		case ActionType.SET_TOTAL_FUNDS:
			return {
				...state,
				totalFunds: action.payload,
				loading: true
			};

		case ActionType.SET_TOTAL_VISITS:
			return {
				...state,
				totalVisits: action.payload,
			};

      case ActionType.SET_TOTAL_CONTRIBUTIONS:
        return {
          ...state,
          totalContributions: action.payload,
        };

      case ActionType.SET_THIS_YEAR_CONTRIBUTIONS:
        return {
          ...state,
          thisYearContributions: action.payload,
        };
			case ActionType.SET_MONTHLY_CONTRIBUTIONS:
        return {
          ...state,
          monthlyContributions: action.payload,
        };
			case ActionType.SET_MONTHLY_GOALS:
        return {
          ...state,
          monthlyGoals: action.payload,
        };
			case ActionType.SET_ALL_USERS:
				return {
					...state,
					totalUserCounts: action.payload,
				};
      case ActionType.SET_THIS_YEAR_VISITS:
        return {
          ...state,
          thisYearVisits: action.payload,
        };
      case ActionType.SET_THIS_YEAR_USERS:
				return {
					...state,
					thisYearUsers: action.payload,
				};
			case ActionType.SET_USERDEMO:
				return {
					...state,
					userDemo: action.payload,
				};
			case ActionType.SET_GRADUATE_USER:
				return {
					...state,
					graduatingUsers: action.payload,
				};
		default:
			return state;
	}
}

export default adminReducer;
