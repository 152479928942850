import { ActionType } from '../actionTypes/index';

const initialState = {
  loading: false,
};

function loadingReducer(state = initialState,action) {
  switch(action.type) {
    case ActionType.SET_LOADING:
      return {
        loading: true
      };
    case ActionType.HIDE_LOADING:
      return {
        loading: false
      };
    default:
      return state;
  }
}

export default loadingReducer;
