import {Box, Button } from '@mui/material';
import profileImage from '../../Assets/user_placeholder.svg';
import { Font, HStack, VStack } from '../../Component/Components';
import { useNavigate } from 'react-router-dom';

export function UserCard(props) {
  const {userInfo ,onDelete} = props;
  const navigate = useNavigate();

  const handleDeleteClick = () => {
    // Trigger the onDelete function when Delete button is clicked
    onDelete(userInfo);
  };
  return (
    <HStack
      gap='10px'
      justifycontent='center'
      alignitems='center'
      style={{ border: '1px solid #ccc', padding: '10px' }}
      sx={{
        '&:hover': {
          backgroundColor: '#333333', // Change this to the desired hover background color
          transition: 'background-color 0.3s ease-in-out', // Add transition for smooth effect
        },
      }}
    >
      <Box component={ 'img' }
        sx={ {
          borderRadius: '100%',
          width: 80,
          height: 80,
          objectFit: 'cover'
        } }
        src={ userInfo?.profile_photo_url ? userInfo?.profile_photo_url : profileImage }
      />
      <VStack gap={ '1' } width='auto'>
        <Font size={ '16px' }
          weight={ 600 }>{ userInfo?.first_name } { ' ' } { userInfo?.last_name }</Font>
        <Font size={ '14px' }
          weight={ 500 }>{ userInfo?.institution?.prospective_institution } </Font>
      </VStack>
      <Button onClick={() => {navigate(`/admin/setting/${userInfo._id}`)}}
        sx={{
          "&:hover":{
            color:"white",
            backgroundColor:"darkblue"
          }
        }}
        >Edit</Button>
      <Button onClick={handleDeleteClick} 
        sx={{
          color:"red",
          "&:hover":{
            color:"white",
            backgroundColor:"red"
          }
        }}
      >Delete</Button>
    </HStack>
  );
}

