import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_GAPFUNDR);

const Stripe = forwardRef((props, ref) => {
	const checkoutRef = useRef();

	useImperativeHandle(ref, () => ({

		handleSubmit(data) {
			checkoutRef.current.handleSubmit(data);
		}
		
	})
	);

	return (
		<Elements stripe={ stripePromise }>
			<CheckoutForm
				price={ props.price }
				contribution={ () => props.contribution() }
				ref={ checkoutRef }
				onPaymentDone={ () => props.onPaymentDone() } />
		</Elements>
	);
});

export default Stripe;
