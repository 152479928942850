import axios from 'axios';
import { handleError } from '../../../Util/handleError';
import { configOption } from '../../../Util/requireAuth';
import { isEmpty } from '../../../Util/validation';
import { ActionType } from '../../actionTypes';
import { BASE_URL } from '../../Api';
import { hideLoading, setCampaignProfile, setAllCamp, setCampaignProfiles, setError, setProfile, showLoading, setCurrentComments, setAllComments, setThisYearCampaigns } from '../../setPayload';

/**
 *  Create Campaign
 *  Creates a new profile. 
 * @param {*} payload 
 * @param {*} id 
 * @param {*} navigate 
 * @returns 
 */
export const createCampaign = (payload, id) => (dispatch) => {
  axios.post(BASE_URL.concat(`/campaign/${id}`), payload, configOption())
    .then(res => {
      dispatch(hideLoading());
      dispatch(setCampaignProfile(res.data))
      dispatch(setProfile(res.data.owner));
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
        window.location.href = '/login';
      }
      dispatch(setError(err.response.data));
    });
};

/**
 * Get Campaign
 *  Gets a single campaign
 * @param {*} id 
 * @returns 
 */
export const getCampaign = (id) => (dispatch) => {
  dispatch(showLoading());
  axios.get(BASE_URL.concat(`/campaign/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_CAMPAIGN,
            payload: (res.data)
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get contributions that belong to a user campaign
 *  Gets contributions for a unique student
 * @param {*}  
 * @returns 
 */
export const getContributions = ({ id }) => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_DONATIONS,
            payload: res.data.contributions
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      handleError(dispatch, err);
    });
};

export const getAllComments = () => (dispatch) => {

  axios.get(BASE_URL.concat(`/campaign/comments/getAll`),configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setAllComments(res.data));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
        window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

export const getAllCampaigns = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/getAll`),configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setAllCamp(res.data.totalCampaigns));
          dispatch(setThisYearCampaigns(res.data.campaignsThisYear));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
        window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};


export const getCampaignsbyPage = ({ page , pagesize }) => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/${page}/${pagesize}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch({
            type: ActionType.SET_CURRENT_CAMPAIGNS,
            payload: res.data
          });
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

export const getCommentsbyPage = ({ page , pagesize }) => (dispatch) => {
  axios.get(BASE_URL.concat(`/campaigns/comments/${page}/${pagesize}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setCurrentComments(res.data));
          dispatch(setError({}));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      if (err.response.data.status === 401) {
      window.location.href = '/login';
      }
      handleError(dispatch, err);
    });
};

/**
 * SendContribution 
 *  Sends/makes contributions/contribution to a student
 * @param {*}  
 * @returns 
 */
export const sendContribution = (id, payload) => (dispatch) => {
  axios.post(BASE_URL.concat(`/campaign/fund/${id}`), payload)
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          getCampaignProfile(id);
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
}

/**
 * Get CampaignProfiles
 *  Get profiles with their respective campaigns 
 * 
 * @returns 
 */
export const getCampaignProfiles = () => (dispatch) => {
  axios.get(BASE_URL.concat(`/users`), configOption())
    .then(res => {
      dispatch(hideLoading());
      dispatch(setCampaignProfiles(res.data));
    }).catch(err => {
      handleError(dispatch, err);
    });
};

/**
 * Get CampaignProfile
 *  Get a profile with its respective campaign
 * 
 * @returns 
 */
export const getCampaignProfile = (id) => (dispatch) => {
  axios.get(BASE_URL.concat(`/student/profile/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          dispatch(setError(res.data));
        } else {
          dispatch(setCampaignProfile(res.data));
        }
      }
    }).catch(err => {
      dispatch(hideLoading());
      handleError(dispatch, err);
    });
};

/**
 * Remove Campaign Cover Image 
 * @param {*} id 
 * @returns 
 */
export const removeCampaignCover = (id) => (dispatch) => {
  axios.delete(BASE_URL.concat(`/campaign/media/${id}`), configOption())
    .then(res => {
      dispatch(hideLoading());
      if (!isEmpty(res)) {
        if (!isEmpty(res.data.error)) {
          getCampaignProfile(id);
        } else {
          dispatch(setError({}));
        }
      }
    })
    .catch(err => {
      handleError(dispatch, err);
    });
};
